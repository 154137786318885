// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    position: fixed;
    background-color: var(--color-grey1100);
    min-height: 40px;
    width: 100%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 150;
    display: flex; /* Changed from inline-block to flex */
    align-items: center; /* Vertically centers content */
    justify-content: space-between; /* Spaces out logo and menu icon */
}

.logo-container {
    padding-left: 20px;
}

.logo {
    margin: 0;
    font-size: 1.2em;
    color: var(--color-primaryLight);
}

.menu {
    cursor: pointer;
    stroke: var(--color-primaryLight);
    padding-right: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,uCAAuC;IACvC,gBAAgB;IAChB,WAAW;IACX,yCAAyC;IACzC,YAAY;IACZ,aAAa,EAAE,sCAAsC;IACrD,mBAAmB,EAAE,+BAA+B;IACpD,8BAA8B,EAAE,kCAAkC;AACtE;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,SAAS;IACT,gBAAgB;IAChB,gCAAgC;AACpC;;AAEA;IACI,eAAe;IACf,iCAAiC;IACjC,mBAAmB;AACvB","sourcesContent":[".header {\n    position: fixed;\n    background-color: var(--color-grey1100);\n    min-height: 40px;\n    width: 100%;\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);\n    z-index: 150;\n    display: flex; /* Changed from inline-block to flex */\n    align-items: center; /* Vertically centers content */\n    justify-content: space-between; /* Spaces out logo and menu icon */\n}\n\n.logo-container {\n    padding-left: 20px;\n}\n\n.logo {\n    margin: 0;\n    font-size: 1.2em;\n    color: var(--color-primaryLight);\n}\n\n.menu {\n    cursor: pointer;\n    stroke: var(--color-primaryLight);\n    padding-right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
