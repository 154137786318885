import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { log } from '../utility/logging';

function TermsAndConditions() {
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    // Fetch the markdown file from the public folder
    fetch('/termsAndConditions.md')
      .then((response) => response.text())
      .then((text) => setMarkdown(text))
      .catch((err) => log(err));
  }, []);

  return (
    <ReactMarkdown>{markdown}</ReactMarkdown>
  );
}

export default TermsAndConditions;
