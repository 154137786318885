import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import {
  convertWeight, get, set,
} from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import {
  renderInputField, renderInputSelectField, renderSelectField, validateForm,
} from '../../core/input/CoreInputUtils';
import {
  INSPECTION_DECLARED_GRADES, INSPECTION_INSPECTED_GRADES, INSPECTION_PACKAGING_TYPES, INSPECTION_PRODUCT_FORM_MODES,
} from '../../../utility/InspectionsUtils';

const fieldsToValidate = ['description'];
const InspectionFormProduct = forwardRef(({
  dispatchInspectionsPut,
  inspectionsPutIsLoading,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentInspection, setCurrentInspection] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [inputState, setInputState] = useState({});
  const [error, setError] = useState({});
  const [modeState, setModeState] = useState(null);
  const [commodityOptionsState, setCommodityOptionsState] = useState([]);
  const [markingOptionsState, setMarkingOptionsState] = useState([]);

  useImperativeHandle(ref, () => ({
    open: (inspection, mode, product, organization) => {
      setCurrentInspection(inspection);
      setCurrentProduct(product);
      setIsOpen(true);
      setModeState(mode);
      setError({});

      const commodityOptions = get(organization, 'inspectionSettings.commodities', []).map((el) => ({ value: el._id, label: el.commodity }));
      setCommodityOptionsState(commodityOptions);

      const markingOptions = get(organization, 'inspectionSettings.markings', []).map((el) => ({ value: el._id, label: el.marking }));
      setMarkingOptionsState(markingOptions);

      switch (mode) {
        case INSPECTION_PRODUCT_FORM_MODES.new:
          setInputState({ inspectedGrade: INSPECTION_INSPECTED_GRADES.us1 });
          break;
        case INSPECTION_PRODUCT_FORM_MODES.edit:
          setInputState({ ...product });
          break;
        case INSPECTION_PRODUCT_FORM_MODES.duplicate:
          setInputState({ ...product });
          break;
        default:
          break;
      }
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!inspectionsPutIsLoading) {
      closeModal();
    }
  }, [inspectionsPutIsLoading]);

  if (!isOpen) return null;

  const onInputChange = (event) => {
    const newState = { ...inputState };
    if (event.target.id === 'weightUnit') {
      // Check if there's a value to convert
      const currentValue = inputState.weight;
      if (currentValue && inputState.weightUnit) {
        const convertedValue = convertWeight(currentValue, inputState.weightUnit, event.target.value, 2);
        newState.weight = convertedValue;
      }
    }

    newState[event.target.id] = event.target.value;
    setInputState(newState);

    validateForm({
      state: newState,
      fieldsToValidate,
      setError,
    });
  };

  const handleSave = (inspection) => {
    if (validateForm({
      state: inputState,
      fieldsToValidate,
      setError,
    })) {
      const update = {};
      const productsToAdd = [];
      const productsToUpdate = [];

      switch (modeState) {
        case INSPECTION_PRODUCT_FORM_MODES.new:
          productsToAdd.push(inputState);
          set(update, 'shipment.productsToAdd', productsToAdd);
          dispatchInspectionsPut(inspection, update);
          break;
        case INSPECTION_PRODUCT_FORM_MODES.edit:
          productsToUpdate.push(inputState);
          set(update, 'shipment.productsToUpdate', productsToUpdate);
          dispatchInspectionsPut(inspection, update);
          break;
        case INSPECTION_PRODUCT_FORM_MODES.duplicate:
          delete inputState._id;
          productsToAdd.push(inputState);
          set(update, 'shipment.productsToAdd', productsToAdd);
          dispatchInspectionsPut(inspection, update);
          break;
        default:
          break;
      }
    }
  };

  const renderTitle = (mode) => {
    switch (mode) {
      case INSPECTION_PRODUCT_FORM_MODES.new:
        return 'New Product';
      case INSPECTION_PRODUCT_FORM_MODES.edit:
        return 'Edit Product';
      case INSPECTION_PRODUCT_FORM_MODES.duplicate:
        return 'Duplicate Product';
      default:
        return 'Product';
    }
  };

  const renderPrimaryAction = (mode) => {
    switch (mode) {
      case INSPECTION_PRODUCT_FORM_MODES.new:
        return 'Create';

      case INSPECTION_PRODUCT_FORM_MODES.edit:
        return 'Save';
      case INSPECTION_PRODUCT_FORM_MODES.duplicate:
        return 'Duplicate';
      default:
        return 'Save';
    }
  };

  return (
    <CoreFormDualAction
      title={renderTitle(modeState)}
      primaryButtonCaption={renderPrimaryAction(modeState)}
      primaryButtonCallback={() => handleSave(currentInspection, currentProduct)}
      primaryButtonCallbackStatus={inspectionsPutIsLoading}
      primaryButtonDisabled={Object.keys(error).length > 0 || inspectionsPutIsLoading}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
      primaryButtonEnterKeyPressIsEnabled={false}
    >
      {renderInputSelectField({
        id: 'description', label: 'Commodity', inputState, onInputChange, error, options: commodityOptionsState,
      })}
      {renderInputSelectField({
        id: 'marking', label: 'Marking', inputState, onInputChange, error, options: markingOptionsState,
      })}
      {renderSelectField({
        id: 'grade', label: 'Declared Grade', inputState, onInputChange, options: Object.values(INSPECTION_DECLARED_GRADES),
      })}
      {renderSelectField({
        id: 'inspectedGrade', label: 'Inspected Grade', inputState, onInputChange, options: Object.values(INSPECTION_INSPECTED_GRADES),
      })}
      <div style={{
        paddingTop: '10px', display: 'flex', flexDirection: 'row', gap: '10px',
      }}
      >
        {renderInputField({ id: 'quantity', inputState, onInputChange })}
        {renderSelectField({
          id: 'packingType', inputState, onInputChange, options: Object.values(INSPECTION_PACKAGING_TYPES),
        })}
      </div>
      <div style={{
        paddingTop: '10px', display: 'flex', flexDirection: 'row', gap: '10px',
      }}
      >
        {renderSelectField({
          id: 'sizingMethod', inputState, onInputChange, options: ['Count', 'Size'],
        })}
        {renderInputField({ id: 'size', inputState, onInputChange })}
      </div>
      <div style={{
        paddingTop: '10px', display: 'flex', flexDirection: 'row', gap: '10px',
      }}
      >
        {renderInputField({ id: 'weight', inputState, onInputChange })}
        {renderSelectField({
          id: 'weightUnit', inputState, onInputChange, options: ['lbs', 'kg'],
        })}
      </div>
      <div style={{
        paddingTop: '10px', display: 'flex', flexDirection: 'row', gap: '10px',
      }}
      >
        {renderSelectField({
          id: 'referenceType', inputState, onInputChange, options: ['PUC'],
        })}
        {renderInputField({ id: 'referenceNumber', inputState, onInputChange })}
      </div>
    </CoreFormDualAction>
  );
});

export default InspectionFormProduct;
