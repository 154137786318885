import React from 'react';
import './InspectionCard.css';
import _ from 'lodash';
import { FaLink, FaUser } from 'react-icons/fa';
import {
  capitalizeFirstLetter, getDateString, get,
} from '../../../utility/utility';
import { getInspectionsStatusColor } from '../../../utility/InspectionsUtils';

function InspectionCard({
  inspection, onSelect, selectCheckboxHandler, checked,
}) {
  const renderLineTitle = (service, applicantName, purchaseOrder) => {
    const title = [];

    if (service) title.push(capitalizeFirstLetter(service));
    if (applicantName) title.push(applicantName);
    if (purchaseOrder) title.push(`#${purchaseOrder}`);

    return title.join(' - ');
  };

  const renderContainerTracking = (containerId, eta) => {
    const title = [];

    if (containerId) title.push(containerId);
    if (eta) title.push(`Pickup ${eta}`);

    return title.join(' - ');
  };

  const renderAssignments = (inspectionElement) => {
    const { assignments } = inspectionElement;
    if (!assignments) return null;

    return (
      <div
        className="inspections-assigned-users"
        title={assignments.map((user) => `${user.email} (${_.startCase(user.role)})`).join('\n')}
      >
        <div className="inspections-assigned-users-icon">
          {assignments.length > 0 && <FaUser className="fa-user" />}
        </div>
        <div className="inspections-assigned-users-count">
          {assignments.length > 1 && <div className="shareCount">{assignments.length}</div>}
        </div>
      </div>
    );
  };

  const renderCost = (inspectionElement) => {
    // Only users who have access to the report tab should be allowed to see the service cost.
    const permittedInspectionTabs = get(inspectionElement, 'permittedInspectionTabs', []);
    const access = permittedInspectionTabs.includes('report');

    const reportsCount = get(inspectionElement, 'report.generatedDocuments', []).length;
    const serviceCost = get(inspectionElement, 'request.service.cost');

    if (!access || !reportsCount) {
      return null;
    }

    return (
      <span
        onClick={(event) => event.stopPropagation()}
        className="inspection-card-margin-right"
      >
        {`$${serviceCost}`}
      </span>
    );
  };

  return (
    <div className="inspection-card">
      <div className="inspection-card-body">
        {get(inspection, 'summary.isLinkedToBooking')
          && (
            <div className="inspection-card-link-icon">
              <FaLink />
            </div>
          )}
        <div className="inspection-card-data-area" onClick={() => onSelect(inspection._id)}>
          <div className="inspection-card-line-service">
            <div className="inspection-card-service">
              {renderLineTitle(
                get(inspection, 'request.service.title'),
                get(inspection, 'summary.applicantName'),
                get(inspection, 'summary.purchaseOrder'),
              )}
            </div>
          </div>
          <div className="inspection-card-line-tracking">
            {renderContainerTracking(
              get(inspection, 'summary.carrierReference'),
              get(inspection, 'shipment.logistics.arrivalTimestamp.date'),
            )}
          </div>
          <div className="inspection-card-product-line">
            {get(inspection, 'summary.commodity')}
          </div>
          {renderAssignments(inspection)}
          <div className="inspection-card-certicicate-line">
            <span
              onClick={(event) => event.stopPropagation()}
              className="inspection-card-margin-right"
            >
              {`#${get(inspection, 'summary.certificateId')}`}
            </span>
            <span className="inspection-card-margin-right">{getDateString(get(inspection, 'createdAt'))}</span>
            <span className="inspection-card-margin-right">
              {`${(get(inspection, 'onsite.productImages')?.length || 0)
                + (get(inspection, 'onsite.shipmentImages')?.length || 0)
              } photos`}
            </span>
            <span className="inspection-card-margin-right">
              {`${(get(inspection, 'report.generatedDocuments', []).length || 0)} report${(get(inspection, 'report.generatedDocuments', []).length) > 1 ? 's' : ''}`}
            </span>
            {renderCost(inspection)}
            <span className="inspection-card-margin-right">
              {`${(get(inspection, 'report.invoices', []).length || 0)} invoice${(get(inspection, 'report.invoices', []).length) > 1 ? 's' : ''}`}
            </span>
            <span style={{ fontWeight: '500', color: getInspectionsStatusColor(get(inspection, 'summary.status')).color }}>
              {capitalizeFirstLetter(_.startCase(get(inspection, 'summary.status')))}
            </span>
          </div>

        </div>
        <div className="inspection-card-action-area">
          {selectCheckboxHandler
            && (
              <input
                type="checkbox"
                onClick={(event) => event.stopPropagation()}
                onChange={() => selectCheckboxHandler(inspection._id)}
                checked={checked}
              />
            )}
        </div>
      </div>
    </div>
  );
}

export default InspectionCard;
