import React, { useState, useRef, useEffect } from 'react';
import { FaEllipsisV } from 'react-icons/fa';
import './CoreCardActionMenu.css';
import { get } from '../../../utility/utility';

function CoreCardActionMenu({
  menuIcon = <FaEllipsisV data-testid="ellipsis-icon" />,
  actions = [],
  isShown = true,
}) {
  const [showMenu, setShowMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const menuRef = useRef();
  const timeoutIdRef = useRef(); // Ref to store the timeout ID

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current); // Clear the timeout when component unmounts
      }
    };
  }, []);

  const handleMenuClick = (event) => {
    event.preventDefault();
    setShowMenu(true);

    // Clear any existing timeout to avoid memory leaks
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    // Set a new timeout and store its ID
    timeoutIdRef.current = setTimeout(() => {
      if (menuRef.current) { // Check if the menuRef is still valid
        const menuWidth = menuRef.current.offsetWidth;
        const menuHeight = menuRef.current.offsetHeight;
        const xPosition = event.clientX - menuWidth;
        const yPosition = event.clientY - menuHeight;

        setMenuPosition({ x: xPosition, y: yPosition });
      }
    }, 0);
  };

  return (
    isShown && !!actions.length && (
      <div className="core-card-actions-menu-container" onClick={handleMenuClick}>
        {menuIcon}

        <div
          ref={menuRef}
          className={`core-card-actions-menu ${showMenu ? 'visible' : ''}`}
          style={{ top: `${menuPosition.y + 50}px`, left: `${menuPosition.x - 25}px` }}
        >
          {actions.map((action) => (
            <div
              key={get(action, '_id')}
              className="core-card-actions-menu-item"
              onClick={(e) => {
                e.stopPropagation(); // Prevent the menu click handler from being triggered
                setShowMenu(false); // Hide the menu
                // Call the original onClick function if it exists
                if (typeof action.onClick === 'function') {
                  action.onClick();
                }
              }}
            >
              <span className="core-card-actions-menu-item-text">{action.text}</span>
              <div className="core-card-actions-menu-item-icon">{action.icon}</div>
            </div>
          ))}
        </div>
      </div>
    )
  );
}

export default CoreCardActionMenu;
