import {
  ORGANIZATION_GET_REQUEST_START,
  ORGANIZATION_GET_REQUEST_SUCCESS,
  ORGANIZATION_GET_REQUEST_FAIL,
  ORGANIZATION_GET_INSPECTORS_REQUEST_START,
  ORGANIZATION_GET_INSPECTORS_REQUEST_SUCCESS,
  ORGANIZATION_GET_INSPECTORS_REQUEST_FAIL,
  ORGANIZATION_PUT_REQUEST_START,
  ORGANIZATION_PUT_REQUEST_SUCCESS,
  ORGANIZATION_PUT_REQUEST_FAIL,
  ORGANIZATION_PUT_REQUEST_IDLE,
} from '../actions';
import { createRequestStateUpdater } from '../utility/reduxUtils';
import { updateObject } from '../utility/utility';

const initialState = {};

export const ORGANIZATION_REQUESTS = {
  orgGet: 'orgGet',
  orgGetInspectors: 'orgGetInspectors',
};

const organizationsPutStart = (state, action) => updateObject(state, {
  organizationPutRequestState: action.requestState,
});

const organizationsPutFail = (state, action) => updateObject(state, {
  organizationPutRequestState: action.requestState,
});

const organizationsPutIdle = (state, action) => updateObject(state, {
  organizationPutRequestState: action.requestState,
});

const organizationsPutSuccess = (state, action) => (updateObject(state, {
  organizationPutRequestState: action.requestState,
  organization: action.data,
}));

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    // Organizations Get
    case ORGANIZATION_GET_REQUEST_START:
      return createRequestStateUpdater(state, action, ORGANIZATION_REQUESTS.orgGet);
    case ORGANIZATION_GET_REQUEST_SUCCESS:
      return updateObject(
        createRequestStateUpdater(state, action, ORGANIZATION_REQUESTS.orgGet),
        { organization: action.data },
      );
    case ORGANIZATION_GET_REQUEST_FAIL:
      return createRequestStateUpdater(state, action, ORGANIZATION_REQUESTS.orgGet);

    // Organizations Get Inspectors
    case ORGANIZATION_GET_INSPECTORS_REQUEST_START:
      return createRequestStateUpdater(state, action, ORGANIZATION_REQUESTS.orgGetInspectors);
    case ORGANIZATION_GET_INSPECTORS_REQUEST_SUCCESS:
      return updateObject(
        createRequestStateUpdater(state, action, ORGANIZATION_REQUESTS.orgGetInspectors),
        { inspectors: action.data },
      );
    case ORGANIZATION_GET_INSPECTORS_REQUEST_FAIL:
      return createRequestStateUpdater(state, action, ORGANIZATION_REQUESTS.orgGetInspectors);

    case ORGANIZATION_PUT_REQUEST_START:
      return organizationsPutStart(state, action);
    case ORGANIZATION_PUT_REQUEST_FAIL:
      return organizationsPutFail(state, action);
    case ORGANIZATION_PUT_REQUEST_SUCCESS:
      return organizationsPutSuccess(state, action);
    case ORGANIZATION_PUT_REQUEST_IDLE:
      return organizationsPutIdle(state, action);
    default:
      return state;
  }
};

export default reducer;
