import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import _ from 'lodash';
import { get, setNestedKey } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import InputFieldSelect from '../../core/input/InputFieldSelect';
import { renderInputField } from '../../core/input/CoreInputUtils';

const InspectionFormParticipant = forwardRef(({
  dispatchInspectionsPut,
  inspectionsPutIsLoading,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentInspection, setCurrentInspection] = useState(null);
  const [presetState, setPresetState] = useState(null);
  const [presetsListState, setPresetsListState] = useState([]);
  const [participantTypeState, setParticipantTypeState] = useState(null);
  const [inputState, setInputState] = useState({});
  const [error, setError] = useState(null);
  useImperativeHandle(ref, () => ({
    open: (organization, inspection, participantType, participant) => {
      setCurrentInspection(inspection);
      setParticipantTypeState(participantType);
      setInputState(participant);
      setPresetsListState(get(organization, 'contacts', []));
      setPresetState(null);
      setIsOpen(true);
      setError(null);
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!inspectionsPutIsLoading) {
      closeModal();
    }
  }, [inspectionsPutIsLoading]);

  if (!isOpen) return null;

  const validateForm = (state) => {
    const fieldId = 'name';
    if (!get(state, fieldId) && !get(state, fieldId)) {
      setError(`${_.startCase(fieldId)} is required`);
      return false;
    }
    setError(null);
    return true;
  };

  const onInputChange = (event, path) => {
    const newState = { ...inputState };
    newState[path] = event.target.value;
    setInputState(newState);
    validateForm(newState);
    setPresetState(null);
  };

  const handleSave = (inspection, participantType, participant) => {
    if (validateForm(inputState)) {
      // Clone the inspection state to avoid direct mutation
      const newInspectionState = { ...inspection };

      // Set the participant in the cloned inspection state
      setNestedKey(newInspectionState, `participants.${participantType}`, participant);

      // Prepare the participants object for dispatch
      const participantsToUpdate = {
        [participantType]: { ...participant },
      };

      // Dispatch with the updated inspection state and participants
      dispatchInspectionsPut(newInspectionState, { participants: participantsToUpdate });
    }
  };

  const handlePresetSelectChange = (presetId) => {
    const contactIndex = presetsListState.findIndex((element) => get(element, '_id').toString() === presetId);

    if (contactIndex >= 0) {
      const preset = presetsListState[contactIndex];
      setInputState({ ...preset.company });
      setPresetState({ label: `${get(preset, 'company.name')}`, value: presetId });
    }
  };

  return (
    <CoreFormDualAction
      title="Participant"
      primaryButtonCaption="Save"
      primaryButtonCallback={() => handleSave(currentInspection, participantTypeState, inputState)}
      primaryButtonCallbackStatus={inspectionsPutIsLoading}
      primaryButtonDisabled={error || inspectionsPutIsLoading}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <div>
        <InputFieldSelect
          style={{ color: 'black' }}
          id="contactPreset"
          label="Contact Preset Selector"
          placeholder="Load a preset contact"
          options={[].concat(presetsListState.map((contact) => ({ value: contact._id, label: `${contact.company.name}` })))}
          onChange={(event) => handlePresetSelectChange(get(event, 'value'))}
          value={presetState}
          createIsDisabled
        />
        {renderInputField({ id: 'name', inputState, onInputChange })}
        {error && <div style={{ color: 'var(--color-warning)' }}>{error}</div>}
        {renderInputField({
          id: 'address.line1', label: 'Address Line 1', inputState, onInputChange,
        })}
        {renderInputField({
          id: 'address.line2', label: 'Address Line 2', inputState, onInputChange,
        })}
        {renderInputField({ id: 'address.city', inputState, onInputChange })}
        {renderInputField({ id: 'address.state', inputState, onInputChange })}
        {renderInputField({ id: 'address.country', inputState, onInputChange })}
        {renderInputField({ id: 'address.postalCode', inputState, onInputChange })}
      </div>
    </CoreFormDualAction>
  );
});

export default InspectionFormParticipant;
