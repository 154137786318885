import { newCreateRequestAction } from '../utility/utility';

const ENDPOINT_PATH = 'carriers';
export const CARRIERS_GET_REQUEST_START = 'CARRIERS_GET_REQUEST_START';
export const CARRIERS_GET_REQUEST_SUCCESS = 'CARRIERS_GET_REQUEST_SUCCESS';
export const CARRIERS_GET_REQUEST_FAIL = 'CARRIERS_GET_REQUEST_FAIL';

export const carriersGet = () => (dispatch, _getState, { apiClient, log }) => {
  dispatch(newCreateRequestAction({ type: CARRIERS_GET_REQUEST_START, isLoading: true }));
  const carriersGetRequest = async () => {
    try {
      const response = await apiClient.httpGet(`${ENDPOINT_PATH}`);
      dispatch(newCreateRequestAction({ type: CARRIERS_GET_REQUEST_SUCCESS, data: response.data }));
    } catch (err) {
      log(err);
      const message = 'Failed to retrieve container carriers';
      dispatch(newCreateRequestAction({ type: CARRIERS_GET_REQUEST_FAIL, error: message }));
    }
  };

  carriersGetRequest();
};
