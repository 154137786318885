import {
  BOOKINGS_GET,
  BOOKINGS_GET_SHARED,
  BOOKINGS_GET_DOCUMENT,
  BOOKINGS_POST,
  BOOKINGS_POST_INSPECTION,
  BOOKINGS_PUT,
  BOOKINGS_PUT_DEACTIVATE,
  BOOKINGS_PUT_SHARE,
  BOOKINGS_DELETE,
  BOOKINGS_PATCH_DOCUMENT,
  BOOKINGS_DELETE_DOCUMENT,
  BOOKINGS_PATCH_REFRESH,
} from '../actions';
import { handleRequestState, REQUEST_STATE, updateElements } from '../utility/reduxUtils';

export const BOOKINGS_REQUESTS = {
  bookingsGet: 'bookingsGet',
  bookingsGetShared: 'bookingsGetShared',
  bookingsGetDocument: 'bookingsGetDocument',
  bookingsPost: 'bookingsPost',
  bookingsPostInspections: 'bookingsPostInspections',
  bookingsPut: 'bookingsPut',
  bookingsPutDeactivate: 'bookingsPutDeactivate',
  bookingsPutShare: 'bookingsPutShare',
  bookingsDelete: 'bookingsDelete',
  bookingsPatchDocuments: 'bookingsPatchDocuments',
  bookingDeleteDocuments: 'bookingDeleteDocuments',
  bookingsPatchRefresh: 'bookingsPatchRefresh',
};

// eslint-disable-next-line default-param-last
const reducer = (state = {}, action) => {
  switch (action.type) {
    case `${BOOKINGS_GET}_${REQUEST_STATE.START}`:
    case `${BOOKINGS_GET}_${REQUEST_STATE.SUCCESS}`:
    case `${BOOKINGS_GET}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state, action, requestKey: BOOKINGS_REQUESTS.bookingsGet, dataKey: 'bookings',
      });

    case `${BOOKINGS_GET_SHARED}_${REQUEST_STATE.START}`:
    case `${BOOKINGS_GET_SHARED}_${REQUEST_STATE.SUCCESS}`:
    case `${BOOKINGS_GET_SHARED}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state, action, requestKey: BOOKINGS_REQUESTS.bookingsGetShared, dataKey: 'sharedBookings',
      });

    case `${BOOKINGS_GET_DOCUMENT}_${REQUEST_STATE.START}`:
    case `${BOOKINGS_GET_DOCUMENT}_${REQUEST_STATE.SUCCESS}`:
    case `${BOOKINGS_GET_DOCUMENT}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({ state, action, requestKey: BOOKINGS_REQUESTS.bookingsGetDocument });

    case `${BOOKINGS_POST}_${REQUEST_STATE.START}`:
    case `${BOOKINGS_POST}_${REQUEST_STATE.SUCCESS}`:
    case `${BOOKINGS_POST}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state,
        action,
        requestKey: BOOKINGS_REQUESTS.bookingsPost,
        dataKey: 'bookings',
        updateFunction: () => updateElements({ current: state.bookings, created: action.data }),
      });

    case `${BOOKINGS_PUT}_${REQUEST_STATE.START}`:
    case `${BOOKINGS_PUT}_${REQUEST_STATE.SUCCESS}`:
    case `${BOOKINGS_PUT}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state,
        action,
        requestKey: BOOKINGS_REQUESTS.bookingsPut,
        dataKey: 'bookings',
        updateFunction: () => updateElements({ current: state.bookings, edited: action.data }),
      });

    case `${BOOKINGS_DELETE}_${REQUEST_STATE.START}`:
    case `${BOOKINGS_DELETE}_${REQUEST_STATE.SUCCESS}`:
    case `${BOOKINGS_DELETE}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state,
        action,
        requestKey: BOOKINGS_REQUESTS.bookingsDelete,
        dataKey: 'bookings',
        updateFunction: () => updateElements({ current: state.bookings, deleted: action.data }),
      });

    case `${BOOKINGS_POST_INSPECTION}_${REQUEST_STATE.START}`:
    case `${BOOKINGS_POST_INSPECTION}_${REQUEST_STATE.SUCCESS}`:
    case `${BOOKINGS_POST_INSPECTION}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state,
        action,
        requestKey: BOOKINGS_REQUESTS.bookingsPostInspections,
        dataKey: 'bookings',
        updateFunction: () => updateElements({ current: state.bookings, edited: action.data }),
      });

    case `${BOOKINGS_PUT_DEACTIVATE}_${REQUEST_STATE.START}`:
    case `${BOOKINGS_PUT_DEACTIVATE}_${REQUEST_STATE.SUCCESS}`:
    case `${BOOKINGS_PUT_DEACTIVATE}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state,
        action,
        requestKey: BOOKINGS_REQUESTS.bookingsPutDeactivate,
        dataKey: 'bookings',
        updateFunction: () => updateElements({ current: state.bookings, deleted: action.data }),
      });

    case `${BOOKINGS_PUT_SHARE}_${REQUEST_STATE.START}`:
    case `${BOOKINGS_PUT_SHARE}_${REQUEST_STATE.SUCCESS}`:
    case `${BOOKINGS_PUT_SHARE}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state,
        action,
        requestKey: BOOKINGS_REQUESTS.bookingsPutShare,
        dataKey: 'bookings',
        updateFunction: () => updateElements({ current: state.bookings, edited: action.data }),
      });

    case `${BOOKINGS_PATCH_DOCUMENT}_${REQUEST_STATE.START}`:
    case `${BOOKINGS_PATCH_DOCUMENT}_${REQUEST_STATE.SUCCESS}`:
    case `${BOOKINGS_PATCH_DOCUMENT}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state,
        action,
        requestKey: BOOKINGS_REQUESTS.bookingsPatchDocuments,
        dataKey: 'bookings',
        updateFunction: () => updateElements({ current: state.bookings, edited: action.data }),
      });

    case `${BOOKINGS_DELETE_DOCUMENT}_${REQUEST_STATE.START}`:
    case `${BOOKINGS_DELETE_DOCUMENT}_${REQUEST_STATE.SUCCESS}`:
    case `${BOOKINGS_DELETE_DOCUMENT}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state,
        action,
        requestKey: BOOKINGS_REQUESTS.bookingDeleteDocuments,
        dataKey: 'bookings',
        updateFunction: () => updateElements({ current: state.bookings, edited: action.data }),
      });

    case `${BOOKINGS_PATCH_REFRESH}_${REQUEST_STATE.START}`:
    case `${BOOKINGS_PATCH_REFRESH}_${REQUEST_STATE.SUCCESS}`:
    case `${BOOKINGS_PATCH_REFRESH}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state,
        action,
        requestKey: BOOKINGS_REQUESTS.bookingsPatchRefresh,
        dataKey: 'bookings',
        updateFunction: () => updateElements({ current: state.bookings, edited: action.data }),
      });

    default:
      return state;
  }
};

export default reducer;
