import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import { get } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import { renderTextArea } from '../../core/input/CoreInputUtils';

const InspectionFormQualityCheck = forwardRef(({
  dispatchInspectionsPut,
  inspectionsPutIsLoading,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentInspection, setCurrentInspection] = useState(null);
  const [inputState, setInputState] = useState({});
  useImperativeHandle(ref, () => ({
    open: (inspection) => {
      setCurrentInspection(inspection);
      setInputState(get(inspection, 'analysis.qualityCheck', {}));
      setIsOpen(true);
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    if (!inspectionsPutIsLoading) {
      closeModal();
    }
  }, [inspectionsPutIsLoading]);

  if (!isOpen) return null;

  const onInputChange = (event, path) => {
    const newState = { ...inputState };
    newState[path] = event.target.value;
    setInputState(newState);
  };

  const handleSave = (inpsection, state) => {
    dispatchInspectionsPut(inpsection, { analysis: { qualityCheck: { ...state } } });
  };

  return (
    <CoreFormDualAction
      title="Quality Check"
      primaryButtonCaption="Save"
      primaryButtonEnterKeyPressIsEnabled={false}
      primaryButtonCallback={() => handleSave(currentInspection, inputState)}
      primaryButtonCallbackStatus={inspectionsPutIsLoading}
      primaryButtonDisabled={inspectionsPutIsLoading}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      {renderTextArea({ id: 'comments', inputState, onInputChange })}
      {renderTextArea({ id: 'standards', inputState, onInputChange })}
    </CoreFormDualAction>
  );
});

export default InspectionFormQualityCheck;
