import { StatusCodes } from 'http-status-codes';
import {
  apiRequestState, get, uuid,
} from '../utility/utility';
import { USE_STORAGE_INDEXED_DB, IMAGE_SYNC_BATCH_SIZE } from '../config';
import { notificationsShow } from './notifications';
import { countImages, storeObjects } from '../utility/db';
import { INSPECTION_RETRIEVE_OPTIONS } from '../utility/InspectionsUtils';
import { openBlobDocument } from '../utility/documentViewerUtils';
import { createRequestAction } from '../utility/reduxUtils';

export const INSPECTIONS_CREATE_INVOICE_POST_REQUEST_START = 'INSPECTIONS_CREATE_INVOICE_POST_REQUEST_START';
export const INSPECTIONS_CREATE_INVOICE_POST_REQUEST_SUCCESS = 'INSPECTIONS_CREATE_INVOICE_POST_REQUEST_SUCCESS';
export const INSPECTIONS_CREATE_INVOICE_POST_REQUEST_FAIL = 'INSPECTIONS_CREATE_INVOICE_POST_REQUEST_FAIL';
export const INSPECTIONS_CREATE_INVOICE_POST_REQUEST_IDLE = 'INSPECTIONS_CREATE_INVOICE_POST_REQUEST_IDLE';
export const INSPECTIONS_DELETE_INVOICE_POST_REQUEST_START = 'INSPECTIONS_DELETE_INVOICE_POST_REQUEST_START';
export const INSPECTIONS_DELETE_INVOICE_POST_REQUEST_SUCCESS = 'INSPECTIONS_DELETE_INVOICE_POST_REQUEST_SUCCESS';
export const INSPECTIONS_DELETE_INVOICE_POST_REQUEST_FAIL = 'INSPECTIONS_DELETE_INVOICE_POST_REQUEST_FAIL';
export const INSPECTIONS_DELETE_INVOICE_POST_REQUEST_IDLE = 'INSPECTIONS_DELETE_INVOICE_POST_REQUEST_IDLE';
export const INSPECTIONS_FETCH_INVOICE_PDF_GET_REQUEST_START = 'INSPECTIONS_FETCH_INVOICE_PDF_GET_REQUEST_START';
export const INSPECTIONS_FETCH_INVOICE_PDF_GET_REQUEST_SUCCESS = 'INSPECTIONS_FETCH_INVOICE_PDF_GET_REQUEST_SUCCESS';
export const INSPECTIONS_FETCH_INVOICE_PDF_GET_REQUEST_FAIL = 'INSPECTIONS_FETCH_INVOICE_PDF_GET_REQUEST_FAIL';
export const INSPECTIONS_FETCH_INVOICE_PDF_GET_REQUEST_IDLE = 'INSPECTIONS_FETCH_INVOICE_PDF_GET_REQUEST_IDLE';

export const INSPECTIONS_IMAGES_PATCH_REQUEST_START = 'INSPECTIONS_IMAGES_PATCH_REQUEST_START';
export const INSPECTIONS_IMAGES_PATCH_REQUEST_SUCCESS = 'INSPECTIONS_IMAGES_PATCH_REQUEST_SUCCESS';
export const INSPECTIONS_IMAGES_PATCH_REQUEST_FAIL = 'INSPECTIONS_IMAGES_PATCH_REQUEST_FAIL';
export const INSPECTIONS_IMAGES_PATCH_REQUEST_IDLE = 'INSPECTIONS_IMAGES_PATCH_REQUEST_IDLE';
export const INSPECTIONS_IMAGE_DELETE_REQUEST_START = 'INSPECTIONS_IMAGE_DELETE_REQUEST_START';
export const INSPECTIONS_IMAGE_DELETE_REQUEST_SUCCESS = 'INSPECTIONS_IMAGE_DELETE_REQUEST_SUCCESS';
export const INSPECTIONS_IMAGE_DELETE_REQUEST_FAIL = 'INSPECTIONS_IMAGE_DELETE_REQUEST_FAIL';
export const INSPECTIONS_IMAGE_DELETE_REQUEST_IDLE = 'INSPECTIONS_IMAGE_DELETE_REQUEST_IDLE';
export const INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_START = 'INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_START';
export const INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_SUCCESS = 'INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_SUCCESS';
export const INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_FAIL = 'INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_FAIL';
export const INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_IDLE = 'INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_IDLE';
export const INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_START = 'INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_START';
export const INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_SUCCESS = 'INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_SUCCESS';
export const INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_FAIL = 'INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_FAIL';
export const INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_IDLE = 'INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_IDLE';
export const INSPECTIONS_IMAGES_LEFT_TO_SYNC_COUNT_START = 'INSPECTIONS_IMAGES_LEFT_TO_SYNC_COUNT_START';
export const INSPECTIONS_IMAGES_LEFT_TO_SYNC_COUNT_SUCCESS = 'INSPECTIONS_IMAGES_LEFT_TO_SYNC_COUNT_SUCCESS';

export const INSPECTIONS_GET = 'INSPECTIONS_GET';
export const INSPECTIONS_GET_SINGLE = 'INSPECTIONS_GET_SINGLE';
export const INSPECTIONS_GET_AVAILABLE_STAFF = 'INSPECTIONS_GET_AVAILABLE_STAFF';
export const INSPECTIONS_POST = 'INSPECTIONS_POST';
export const INSPECTIONS_PUT = 'INSPECTIONS_PUT';
export const INSPECTIONS_PUT_ASSIGN = 'INSPECTIONS_PUT_ASSIGN';
export const INSPECTIONS_PUT_MARK = 'INSPECTIONS_PUT_MARK';
export const INSPECTIONS_PUT_UNASSIGN_SELF = 'INSPECTIONS_PUT_UNASSIGN_SELF';
export const INSPECTIONS_PUT_SUBMIT_REPORT = 'INSPECTIONS_PUT_SUBMIT_REPORT';
export const INSPECTIONS_POST_GENERERATE_REPORT = 'INSPECTIONS_POST_GENERERATE_REPORT';
export const INSPECTIONS_POST_REPORTS_DELETE = 'INSPECTIONS_POST_REPORTS_DELETE';
export const INSPECTIONS_CREATE_INVOICE_POST = 'INSPECTIONS_CREATE_INVOICE_POST';
export const INSPECTIONS_DELETE_INVOICE_POST = 'INSPECTIONS_DELETE_INVOICE_POST';
export const INSPECTIONS_FETCH_INVOICE_PDF_GET = 'INSPECTIONS_FETCH_INVOICE_PDF_GET';
export const INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET = 'INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET';
export const INSPECTIONS_IMAGES_PATCH = 'INSPECTIONS_IMAGES_PATCH';
export const INSPECTIONS_IMAGE_DELETE = 'INSPECTIONS_IMAGE_DELETE';
export const INSPECTIONS_IMAGES_ASSOCIATE_PUT = 'INSPECTIONS_IMAGES_ASSOCIATE_PUT';
export const INSPECTIONS_IMAGES_SELECT_PUT = 'INSPECTIONS_IMAGES_SELECT_PUT';
export const INSPECTIONS_DELETE = 'INSPECTIONS_DELETE';
export const INSPECTIONS_PATCH_REPOPULATE = 'INSPECTIONS_PATCH_REPOPULATE';
export const INSPECTIONS_GET_DOCUMENT = 'INSPECTIONS_GET_DOCUMENT';
export const INSPECTIONS_IMAGES_LEFT_TO_SYNC = 'INSPECTIONS_IMAGES_LEFT_TO_SYNC';

export const inspectionsGet = (body) => {
  const option = get(body, 'status', INSPECTION_RETRIEVE_OPTIONS.active);
  return createRequestAction({
    requestType: INSPECTIONS_GET,
    endpoint: `inspections/?options=${option}`, // Add query params as needed
    method: 'httpGet',
    failureMessage: 'Failed to retrieve inspections',
    pagination: true, // Enable pagination
  });
};

export const inspectionsGetSingle = (body) => {
  const inspectionId = get(body, 'inspectionId', null);

  return createRequestAction({
    requestType: INSPECTIONS_GET_SINGLE,
    endpoint: `inspections/${inspectionId}`,
    method: 'httpGet',
    failureMessage: 'Failed to retrieve single inspection',
  });
};

export const inspectionsPost = (inspection) => createRequestAction({
  requestType: INSPECTIONS_POST,
  endpoint: 'inspections',
  method: 'httpPost',
  payload: inspection,
  successMessage: 'Successfully created inspection',
  failureMessage: 'Failed to create inspection',
});

// TODO: inspection and body could be one argument
export const inspectionsPut = (inspection, body) => createRequestAction({
  requestType: INSPECTIONS_PUT,
  endpoint: `inspections/${inspection._id}`,
  method: 'httpPut',
  payload: body,
  successMessage: 'Successfully updated inspection',
  failureMessage: 'Failed to update inspection',
});

export const inspectionsGetAvailableStaff = () => createRequestAction({
  requestType: INSPECTIONS_GET_AVAILABLE_STAFF,
  endpoint: 'inspections/availableStaff',
  method: 'httpGet',
  failureMessage: 'Failed to retrieve available staff',
});

export const inspectionsPutAssign = (assignment) => createRequestAction({
  requestType: INSPECTIONS_PUT_ASSIGN,
  endpoint: 'inspections/assign',
  method: 'httpPut',
  payload: assignment,
  successMessage: 'Successfully assigned inspection',
  failureMessage: 'Failed to assign inspection',
});

export const inspectionsDelete = (inspections) => createRequestAction({
  requestType: INSPECTIONS_DELETE,
  endpoint: 'inspections',
  method: 'httpDelete',
  payload: inspections,
  successMessage: 'Successfully deleted inspection',
  failureMessage: 'Failed to delete inspection',
});

export const inspectionsPutMark = (assignment) => createRequestAction({
  requestType: INSPECTIONS_PUT_MARK,
  endpoint: 'inspections/mark',
  method: 'httpPut',
  payload: assignment,
  successMessage: 'Successfully marked inspection',
  failureMessage: 'Failed to mark inspection',
});

export const inspectionsPostGenerateReport = (inspection, service) => createRequestAction({
  requestType: INSPECTIONS_POST_GENERERATE_REPORT,
  endpoint: `inspections/${inspection._id}/report/generate`,
  method: 'httpPost',
  payload: { service },
  successMessage: 'Successfully created inspection document',
  failureMessage: 'Failed to create inspection document',
});

export const inspectionsGetDocument = (inspection, documentData, openInPreview = true) => createRequestAction({
  requestType: INSPECTIONS_GET_DOCUMENT,
  endpoint: `inspections/${inspection._id}/report/fetch`,
  method: 'httpPost',
  responseType: 'blob',
  payload: documentData,
  failureMessage: 'Failed to retrieve document',
  onSuccess: (data, headers) => {
    const downloadUrl = window.URL.createObjectURL(data);
    if (openInPreview) {
      openBlobDocument(data);
    } else {
      // Create a temporary link element to trigger download
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = get(headers, 'x-filename', 'default.pdf'); // You can specify a default filename here
      document.body.appendChild(link);
      link.click();
      // Remove the link element and revoke URL after triggering download
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    }
  },
});

export const inspectionsDeleteReport = (inspection, reportIds) => createRequestAction({
  requestType: INSPECTIONS_POST_REPORTS_DELETE,
  endpoint: `inspections/${inspection._id}/reports/delete`,
  method: 'httpPost',
  payload: { reportIds },
  successMessage: 'Successfully deleted report',
  failureMessage: 'Failed to delete report',
});

export const inspectionsPutSubmitReport = (inspection, report) => createRequestAction({
  requestType: INSPECTIONS_PUT_SUBMIT_REPORT,
  endpoint: `inspections/${inspection._id}/report/submit`,
  method: 'httpPut',
  payload: { inspection, report },
  successMessage: 'Successfully submitted report',
  failureMessage: 'Failed to submit report',
});

export const inspectionsImagesPatchStart = () => ({
  type: INSPECTIONS_IMAGES_PATCH_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const inspectionsImagesPatchSuccess = ({ inspection, uploadProgress, leftToSync }) => ({
  type: INSPECTIONS_IMAGES_PATCH_REQUEST_SUCCESS,
  requestState: leftToSync === 0 ? apiRequestState.SUCCESS : apiRequestState.LOADING,
  inspection,
  uploadProgress,
  leftToSync,
});

export const inspectionsImagesPatchFail = () => ({
  type: INSPECTIONS_IMAGES_PATCH_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const inspectionsImagesPatchIdle = () => ({
  type: INSPECTIONS_IMAGES_PATCH_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

const cloneFile = (originalFile) => new File(
  [originalFile],
  originalFile.name,
  {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  },
);

const storeImagesLocally = async (images, dispatch, inspection, productId) => {
  try {
    const objectsToStore = images.map((image) => {
      const imageFile = cloneFile(image);

      return ({
        imageFile,
        inspectionId: inspection._id,
        productId,
      });
    });

    dispatch(inspectionsImagesPatchStart());

    // Store the images in IndexedDB and get their data
    await storeObjects(objectsToStore);
    const totalImages = await countImages();

    dispatch(inspectionsImagesPatchSuccess({ inspection, leftToSync: totalImages }));
    dispatch(inspectionsImagesPatchIdle());
  } catch (error) {
    dispatch(inspectionsImagesPatchFail());
  }
};

// Helper function to send images in batches
const sendImagesInBatches = async (apiClient, images, batchSize, dispatch, inspection, imageType, assignmentId) => {
  const totalBatches = Math.ceil(images.length / batchSize);
  let currentBatch = 0;

  const uploadBatch = async (imageBatch, batchId) => {
    const formData = new FormData();
    imageBatch.forEach((image) => {
      formData.append('images', image);
    });

    // Include batchId in the URL or as a part of the form data
    const url = `inspections/${inspection._id}/images/${imageType}/${assignmentId}/${batchId}`;
    try {
      const response = await apiClient.httpPatch(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 15 * 60 * 1000, // 15 min
      });

      // Calculate progress and dispatch success
      const uploadProgress = Math.round(((currentBatch + 1) / totalBatches) * 100);
      const temp = {
        leftToSync: totalBatches - currentBatch,
        inspection: response.data.inspection,
        uploadProgress,
      };
      dispatch(inspectionsImagesPatchSuccess(temp));
      return true; // Batch was successfully uploaded
    } catch (error) {
      dispatch(inspectionsImagesPatchFail());
      dispatch(notificationsShow('error', 'Failed to upload images'));
      return false; // Batch failed to upload
    }
  };

  // Sequential batch upload with progress updates
  dispatch(inspectionsImagesPatchStart());
  while (currentBatch < totalBatches) {
    const batch = images.slice(currentBatch * batchSize, (currentBatch + 1) * batchSize);
    const batchId = uuid(); // Generate a unique ID for each batch

    // eslint-disable-next-line
    const success = await uploadBatch(batch, batchId);
    if (!success) break; // Stop if uploading fails
    // eslint-disable-next-line
    currentBatch++; // Proceed to the next batch
  }

  if (currentBatch === totalBatches) {
    dispatch(notificationsShow('success', 'All images successfully uploaded'));
  }

  dispatch(inspectionsImagesPatchIdle());
};

export const inspectionsImagesPatch = (inspection, images, imageType, assignmentId) => (dispatch, _getState, apiClient) => {
  const batchSize = IMAGE_SYNC_BATCH_SIZE; // Adjust this number based on your server's capacity

  if (USE_STORAGE_INDEXED_DB) {
    storeImagesLocally(images, dispatch, inspection, assignmentId);
  } else {
    sendImagesInBatches(apiClient, images, batchSize, dispatch, inspection, imageType, assignmentId);
  }
};

export const inspectionsImageDeleteStart = () => ({
  type: INSPECTIONS_IMAGE_DELETE_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const inspectionsImageDeleteSuccess = (inspection) => ({
  type: INSPECTIONS_IMAGE_DELETE_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  inspection,
});

export const inspectionsImageDeleteFail = () => ({
  type: INSPECTIONS_IMAGE_DELETE_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const inspectionsImageDeleteIdle = () => ({
  type: INSPECTIONS_IMAGE_DELETE_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const inspectionsImageDelete = (inspection, request) => (dispatch, _getState, { apiClient }) => {
  dispatch(inspectionsImageDeleteStart());
  const inspectionsImageDeleteRequest = async () => {
    try {
      const response = await apiClient.httpDelete(`inspections/${inspection._id}/images`, request);

      await dispatch(inspectionsImageDeleteSuccess(response.data));
      await dispatch(inspectionsImageDeleteIdle());
      dispatch(notificationsShow('success', 'Successfully deleted images'));
    } catch (err) {
      dispatch(inspectionsImageDeleteFail());
      dispatch(notificationsShow('error', 'Failed to delete images'));
    }
  };

  inspectionsImageDeleteRequest();
};

export const inspectionsImagesAssociateStart = () => ({
  type: INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const inspectionsImagesAssociateSuccess = (inspection) => ({
  type: INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  inspection,
});

export const inspectionsImagesAssociateFail = () => ({
  type: INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const inspectionsImagesAssociateIdle = () => ({
  type: INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const inspectionsImagesAssociate = (inspection, association) => (dispatch, _getState, { apiClient }) => {
  dispatch(inspectionsImagesAssociateStart());
  const inspectionsImagesAssociateRequest = async () => {
    try {
      const response = await apiClient.httpPut(`inspections/${inspection._id}/images/associate`, association);

      await dispatch(inspectionsImagesAssociateSuccess(response.data));
      await dispatch(inspectionsImagesAssociateIdle());
      dispatch(notificationsShow('success', 'Successfully associated images'));
    } catch (err) {
      dispatch(inspectionsImagesAssociateFail());
      dispatch(notificationsShow('error', 'Failed to associate images'));
    }
  };

  inspectionsImagesAssociateRequest();
};

export const inspectionsImagesSelectStart = () => ({
  type: INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const inspectionsImagesSelectSuccess = (inspection) => ({
  type: INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  inspection,
});

export const inspectionsImagesSelectFail = () => ({
  type: INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const inspectionsImagesSelectIdle = () => ({
  type: INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const inspectionsReportImagesSelect = (inspection, select) => (dispatch, _getState, { apiClient }) => {
  dispatch(inspectionsImagesSelectStart());
  const inspectionsImagesSelectRequest = async () => {
    try {
      const response = await apiClient.httpPut(`inspections/${inspection._id}/images/select`, select);

      await dispatch(inspectionsImagesSelectSuccess(response.data));
      await dispatch(inspectionsImagesSelectIdle());
      dispatch(notificationsShow('success', 'Successfully selected images'));
    } catch (err) {
      dispatch(inspectionsImagesSelectFail());
      dispatch(notificationsShow('error', 'Failed to select images'));
    }
  };

  inspectionsImagesSelectRequest();
};

export const inspectionsCreateInvoicePostStart = () => ({
  type: INSPECTIONS_CREATE_INVOICE_POST_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const inspectionsCreateInvoicePostSuccess = (inspection) => ({
  type: INSPECTIONS_CREATE_INVOICE_POST_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  inspection,
});

export const inspectionsCreateInvoicePostFail = () => ({
  type: INSPECTIONS_CREATE_INVOICE_POST_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const inspectionsCreateInvoicePostIdle = () => ({
  type: INSPECTIONS_CREATE_INVOICE_POST_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const inspectionsCreateInvoicePost = (inspection, request) => async (dispatch, _getState, { apiClient }) => {
  dispatch(inspectionsCreateInvoicePostStart());

  try {
    const response = await apiClient.httpPost(`inspections/${inspection._id}/invoice/create`, request);

    await dispatch(inspectionsCreateInvoicePostSuccess(response.data, response.headers));
    await dispatch(inspectionsCreateInvoicePostIdle());
    dispatch(notificationsShow('success', 'Successfully created invoice'));
  } catch (err) {
    if (err.response.status === StatusCodes.BAD_REQUEST) {
      dispatch(notificationsShow('warning', `${err.response.status}`));
    } else {
      dispatch(notificationsShow('error', `${get(err, 'response.data.message')}`));
    }
    dispatch(inspectionsCreateInvoicePostFail());
  }
};

export const inspectionsDeleteInvoicePostStart = () => ({
  type: INSPECTIONS_DELETE_INVOICE_POST_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const inspectionsDeleteInvoicePostSuccess = (inspection) => ({
  type: INSPECTIONS_DELETE_INVOICE_POST_REQUEST_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  inspection,
});

export const inspectionsDeleteInvoicePostFail = () => ({
  type: INSPECTIONS_DELETE_INVOICE_POST_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const inspectionsDeleteInvoicePostIdle = () => ({
  type: INSPECTIONS_DELETE_INVOICE_POST_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const inspectionsDeleteInvoicePost = (inspection, request) => async (dispatch, _getState, { apiClient }) => {
  dispatch(inspectionsDeleteInvoicePostStart());

  try {
    const response = await apiClient.httpPost(`inspections/${inspection._id}/invoice/delete`, request);

    await dispatch(inspectionsDeleteInvoicePostSuccess(response.data, response.headers));
    await dispatch(inspectionsDeleteInvoicePostIdle());
    dispatch(notificationsShow('success', 'Successfully deleted invoice'));
  } catch (err) {
    if (err.response.status === StatusCodes.BAD_REQUEST) {
      dispatch(notificationsShow('warning', `${err.response.status}`));
    } else {
      dispatch(notificationsShow('error', 'Failed to delete invoice'));
    }
    dispatch(inspectionsDeleteInvoicePostFail());
  }
};

export const inspectionsInvoicePdfGetStart = () => ({
  type: INSPECTIONS_FETCH_INVOICE_PDF_GET_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const inspectionsInvoicePdfGetSuccess = (response, openInPreview = true) => {
  const downloadUrl = window.URL.createObjectURL(response.data);

  if (openInPreview) {
    // Create a new window or tab to display the PDF
    window.open(downloadUrl, '_blank');
    // It's important to revoke the object URL to avoid memory leaks
    window.URL.revokeObjectURL(downloadUrl);
  } else {
    // Create a temporary link element to trigger download
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = get(response, 'headers.x-filename', 'default.pdf'); // You can specify a default filename here
    document.body.appendChild(link);
    link.click();
    // Remove the link element and revoke URL after triggering download
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
  }

  return {
    type: INSPECTIONS_FETCH_INVOICE_PDF_GET_REQUEST_SUCCESS,
    requestState: apiRequestState.SUCCESS,
  };
};

export const inspectionsInvoicePdfGetFail = () => ({
  type: INSPECTIONS_FETCH_INVOICE_PDF_GET_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const inspectionsInvoicePdfGet = (inspection, request, openInPreview = true) => async (dispatch, _getState, { apiClient }) => {
  dispatch(inspectionsInvoicePdfGetStart());
  try {
    const response = await apiClient.httpPost(`inspections/${inspection._id}/invoice/pdf`, request, {
      headers: {
        // Specify any necessary headers, e.g., Content-Type
      },
      responseType: 'blob',
    });

    await dispatch(inspectionsInvoicePdfGetSuccess(response, openInPreview));
  } catch (err) {
    dispatch(inspectionsInvoicePdfGetFail());
    dispatch(notificationsShow('error', 'Failed to retrieve Document'));
  }
};

export const inspectionsImagesDownloadZipGet = (inspection, type) => createRequestAction({
  requestType: INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET,
  endpoint: `inspections/${inspection._id}/downloadImages/${type}`,
  method: 'httpPost',
  responseType: 'blob',
  failureMessage: 'Failed to retrieve images',
  onSuccess: (data, headers) => {
    const downloadUrl = window.URL.createObjectURL(data);
    // Create a temporary link element to trigger download
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = get(headers, 'x-filename', 'default.pdf'); // You can specify a default filename here
    document.body.appendChild(link);
    link.click();
    // Remove the link element and revoke URL after triggering download
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
  },
});

export const getImagesLeftToSyncCountStart = () => ({
  type: INSPECTIONS_IMAGES_LEFT_TO_SYNC_COUNT_START,
  requestState: apiRequestState.LOADING,
});

export const getImagesLeftToSyncCountSuccess = ({ imagesLeftToSync, uploadedImages }) => ({
  type: INSPECTIONS_IMAGES_LEFT_TO_SYNC_COUNT_SUCCESS,
  requestState: apiRequestState.SUCCESS,
  imagesLeftToSync,
  uploadedImages,
});

export const getImagesLeftToSyncCount = (uploadedImages) => async (dispatch) => {
  const imagesLeftToSync = await countImages();

  await dispatch(getImagesLeftToSyncCountStart());
  await dispatch(getImagesLeftToSyncCountSuccess({ imagesLeftToSync, uploadedImages }));
};
