import _ from 'lodash';
import { StatusCodes } from 'http-status-codes';
import { notificationsShow } from '../actions';
import { get, newCreateRequestAction, updateObject } from './utility';
import { log } from './logging';

export const createRequestStateUpdater = (state, action, requestKey, additionalUpdates = {}) => updateObject(state, {
  [requestKey]: {
    isLoading: action.isLoading,
    error: action.error,
    ...additionalUpdates,
  },
});

export const updateElements = ({
  current, created = [], edited = [], deleted = [],
}) => {
  // Start with a copy of the current elements
  let newElements = [...current];

  // Add new elements at the beginning
  if (created.length > 0) {
    newElements.unshift(...created);
  }

  // Apply edits to existing elements
  if (edited.length > 0) {
    edited.forEach((edit) => {
      const index = newElements.findIndex(
        (element) => element._id === get(edit, '_id', false),
      );

      // Update the element if found
      if (index !== -1) {
        newElements[index] = _.cloneDeep(edit);
      }
    });
  }

  // Remove elements with IDs specified in `deleted`
  if (deleted.length > 0) {
    // Extract `_id` from each object in `deleted` array
    const deletedIds = deleted.map((item) => get(item, '_id', false));

    newElements = newElements.filter((element) => {
      const elementId = get(element, '_id', false);
      if (!elementId) {
        log('Element without an _id found:', element);
      }
      // Filter out elements whose `_id` is in the `deletedIds` array
      return !deletedIds.includes(elementId);
    });
  }

  // Final return after all modifications
  return newElements;
};

export const handleRequestState = ({
  state, action, requestKey, dataKey = null, updateFunction = null,
}) => {
  let additionalUpdates = {};

  if (action.type.endsWith('_START')) {
    // Set loading state without updating data
    additionalUpdates = { [requestKey]: { isLoading: true, error: null } };
  } else if (action.type.endsWith('_SUCCESS')) {
    // On success, apply the custom update function if provided, otherwise, use action.data directly
    const updatedData = updateFunction
      ? updateFunction({ current: state[dataKey] || [], created: action.data })
      : action.data;

    additionalUpdates = {
      [requestKey]: { isLoading: false, error: null },
      ...(dataKey ? { [dataKey]: updatedData } : {}),
    };
  } else if (action.type.endsWith('_FAIL')) {
    // Set error state
    additionalUpdates = {
      [requestKey]: { isLoading: false, error: action.error || 'An error occurred' },
    };
  }

  return updateObject(state, additionalUpdates);
};

export const REQUEST_STATE = {
  START: 'START',
  SUCCESS: 'SUCCESS',
  FAIL: 'START',
};

export const createRequestAction = ({
  requestType,
  endpoint,
  method,
  payload = null,
  successMessage = '',
  failureMessage = '',
  responseType = 'json',
  onSuccess = null, // Optional callback for each response
  onComplete = null, // Optional callback for full data after pagination
  pagination = false, // Enables paginated requests
}) => async (dispatch, _getState, { apiClient }) => {
  try {
    dispatch(newCreateRequestAction({ type: `${requestType}_${REQUEST_STATE.START}`, isLoading: true }));

    let allData;

    if (pagination) {
      // If pagination is enabled, handle paginated data
      allData = [];
      let page = 1;
      let totalPages = 1;

      do {
        const paginatedEndpoint = `${endpoint}&page=${page}`;
        // eslint-disable-next-line
        const { data, headers } = await apiClient[method](paginatedEndpoint, payload, { responseType });

        allData = [...allData, ...data.inspections];
        totalPages = data.totalPages;
        page += 1;

        if (onSuccess) onSuccess(data, headers); // Callback for each page
      } while (page <= totalPages);
    } else {
      // For non-paginated requests, simply fetch data once
      const { data, headers } = await apiClient[method](endpoint, payload, { responseType });
      allData = data;

      if (onSuccess) onSuccess(data, headers); // Callback for single response
    }

    // Dispatch success action with all data after all pages (if paginated) or single fetch (if not)
    dispatch(newCreateRequestAction({ type: `${requestType}_${REQUEST_STATE.SUCCESS}`, data: allData }));

    if (onComplete && pagination) onComplete(allData); // Final callback with all data for paginated responses

    if (successMessage) {
      dispatch(notificationsShow('success', successMessage));
    }
  } catch (err) {
    log(err);
    const errorMessage = err.response?.data || failureMessage || 'An error occurred';
    dispatch(newCreateRequestAction({ type: `${requestType}_${REQUEST_STATE.FAIL}`, error: errorMessage }));

    if (err.response?.status === StatusCodes.BAD_REQUEST) {
      dispatch(notificationsShow('warning', errorMessage));
    } else {
      dispatch(notificationsShow('error', failureMessage || 'An error occurred'));
    }
  }
};
