export const branding = {
  name: 'vessel',
};

// Define the colors object
export const colors = {
  primaryLight: '#3798EF',
  primaryMedium: '#3A81C1',
  primaryDark: '#215380',

  secondaryLight: '#00ff00',
  secondaryMedium: '#00ff00',
  secondaryDark: '#00ff00',

  tertiaryLight: '#8AD1A9',
  tertiaryMedium: '#8AD1A9',
  tertiaryDark: '#8AD1A9',

  grey000: '#F7F7F7',
  grey050: '#EDEDED',
  grey100: '#acacac',
  grey300: '#E6E6E6',
  grey500: '#C5C5C6',
  grey700: '#A1A1A1',
  grey900: '#464953',
  grey1100: '#1F2021',

  mapDarkBackround: '#191A1A',

  warning: 'orange',
  critical: 'red',
};

// Apply colors to CSS
Object.entries(colors).forEach(([key, value]) => {
  document.documentElement.style.setProperty(`--color-${key}`, value);
});
