import _ from 'lodash';
import { notificationsShow } from './notifications';
import { get, newCreateRequestAction } from '../utility/utility';
import { storeConfig } from '../utility/db';

const ENDPOINT_PATH = 'auth';
export const AUTH_SIGN_IN_REQUEST_START = 'AUTH_SIGN_IN_REQUEST_START';
export const AUTH_SIGN_IN_REQUEST_SUCCESS = 'AUTH_SIGN_IN_REQUEST_SUCCESS';
export const AUTH_SIGN_IN_REQUEST_FAIL = 'AUTH_SIGN_IN_REQUEST_FAIL';
export const AUTH_VERIFY_REQUEST_START = 'AUTH_VERIFY_REQUEST_START';
export const AUTH_VERIFY_REQUEST_SUCCESS = 'AUTH_VERIFY_REQUEST_SUCCESS';
export const AUTH_VERIFY_REQUEST_FAIL = 'AUTH_VERIFY_REQUEST_FAIL';
export const AUTH_SIGN_OUT_REQUEST_START = 'AUTH_SIGN_OUT_REQUEST_START';
export const AUTH_SIGN_OUT_REQUEST_SUCCESS = 'AUTH_SIGN_OUT_REQUEST_SUCCESS';
export const AUTH_CSRF_GET_REQUEST_START = 'AUTH_CSRF_GET_REQUEST_START';
export const AUTH_CSRF_GET_REQUEST_SUCCESS = 'AUTH_CSRF_GET_REQUEST_SUCCESS';
export const AUTH_CSRF_GET_REQUEST_FAIL = 'AUTH_CSRF_GET_REQUEST_FAIL';
export const AUTH_CREDS_STATUS_GET_REQUEST_START = 'AUTH_CREDS_STATUS_GET_REQUEST_START';
export const AUTH_CREDS_STATUS_GET_REQUEST_SUCCESS = 'AUTH_CREDS_STATUS_GET_REQUEST_SUCCESS';
export const AUTH_CREDS_STATUS_GET_REQUEST_FAIL = 'AUTH_CREDS_STATUS_GET_REQUEST_FAIL';
export const AUTH_FORGOT_PASSWORD_REQUEST_START = 'AUTH_FORGOT_PASSWORD_REQUEST_START';
export const AUTH_FORGOT_PASSWORD_REQUEST_SUCCESS = 'AUTH_FORGOT_PASSWORD_REQUEST_SUCCESS';
export const AUTH_FORGOT_PASSWORD_REQUEST_FAIL = 'AUTH_FORGOT_PASSWORD_REQUEST_FAIL';
export const AUTH_RESET_PASSWORD_REQUEST_START = 'AUTH_RESET_PASSWORD_REQUEST_START';
export const AUTH_RESET_PASSWORD_REQUEST_SUCCESS = 'AUTH_RESET_PASSWORD_REQUEST_SUCCESS';
export const AUTH_RESET_PASSWORD_REQUEST_FAIL = 'AUTH_RESET_PASSWORD_REQUEST_FAIL';
export const AUTH_SIGNUP_INVITE_REQUEST_START = 'AUTH_SIGNUP_INVITE_REQUEST_START';
export const AUTH_SIGNUP_INVITE_REQUEST_SUCCESS = 'AUTH_SIGNUP_INVITE_REQUEST_SUCCESS';
export const AUTH_SIGNUP_INVITE_REQUEST_FAIL = 'AUTH_SIGNUP_INVITE_REQUEST_FAIL';
export const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT';

export const signIn = (credentials) => async (dispatch, _getState, { apiClient, log }) => {
  dispatch(newCreateRequestAction({ type: AUTH_SIGN_IN_REQUEST_START, isLoading: true }));

  try {
    const response = await apiClient.httpPost(
      `${ENDPOINT_PATH}/signin`,
      _.pick(credentials, ['email', 'password']),
      { csrfRequired: false },
    );

    dispatch(newCreateRequestAction({ type: AUTH_SIGN_IN_REQUEST_SUCCESS, data: response.data }));
  } catch (err) {
    log(err);
    const message = 'Invalid email or password';
    dispatch(newCreateRequestAction({ type: AUTH_SIGN_IN_REQUEST_FAIL, error: message }));
    dispatch(notificationsShow('error', message));
  }
};

export const verify = (credentials) => async (dispatch, _getState, { apiClient, log }) => {
  dispatch(newCreateRequestAction({ type: AUTH_VERIFY_REQUEST_START, isLoading: true }));

  try {
    const response = await apiClient.httpPost(
      `${ENDPOINT_PATH}/verify`,
      _.pick(credentials, ['email', 'code']),
      { csrfRequired: false },
    );

    dispatch(newCreateRequestAction({ type: AUTH_VERIFY_REQUEST_SUCCESS, data: response.data }));
    window.location.href = '/';
  } catch (err) {    
    log(err);
    const message = 'Invalid code';
    dispatch(newCreateRequestAction({ type: AUTH_VERIFY_REQUEST_FAIL, error: message }));
    dispatch(notificationsShow('error', message));
  }
};

export const signOut = () => async (dispatch, _getState, { apiClient, log }) => {
  dispatch(newCreateRequestAction({ type: AUTH_SIGN_OUT_REQUEST_START, isLoading: true }));

  try {
    // Request de server to delete the http-only auth cookies
    await apiClient.httpPost(
      `${ENDPOINT_PATH}/signout`,
      null,
      { csrfRequired: false },
    );

    // Clear the index db
    await storeConfig(null);

    dispatch(newCreateRequestAction({ type: AUTH_SIGN_OUT }));
  } catch (err) {
    log(err);
  }
};

export const csrfGetRequest = () => async (dispatch, _getState, { apiClient }) => {
  dispatch(newCreateRequestAction({ type: AUTH_CSRF_GET_REQUEST_START, isLoading: true }));

  try {
    const response = await apiClient.httpGet(`${ENDPOINT_PATH}/csrf`, null, {
      csrfRequired: false,
    });

    const data = get(response, 'data', false);
    if (!data) throw new Error('Failed to retrieve CSRF token.');

    dispatch(newCreateRequestAction({ type: AUTH_CSRF_GET_REQUEST_SUCCESS, data }));
  } catch (err) {
    dispatch(newCreateRequestAction({ type: AUTH_CSRF_GET_REQUEST_FAIL, isLoading: false }));
  }
};

export const credsStatusGet = () => async (dispatch, _getState, { apiClient }) => {
  dispatch(newCreateRequestAction({ type: AUTH_CREDS_STATUS_GET_REQUEST_START, isLoading: true }));

  try {
    await apiClient.httpGet(`${ENDPOINT_PATH}/csrf`, null, {
      csrfRequired: false,
    });

    dispatch(newCreateRequestAction({ type: AUTH_CREDS_STATUS_GET_REQUEST_SUCCESS }));
  } catch (err) {
    dispatch(newCreateRequestAction({ type: AUTH_CREDS_STATUS_GET_REQUEST_FAIL, error: 'No credentials stored.' }));
  }
};

export const authForgotPassword = (email) => (dispatch, _getState, { apiClient, log }) => {
  dispatch(newCreateRequestAction({ type: AUTH_FORGOT_PASSWORD_REQUEST_START, isLoading: true }));

  const authForgotPasswordRequest = async () => {
    try {
      await apiClient.httpPost(
        `${ENDPOINT_PATH}/forgot-password`,
        { email },
        {
          withCredentials: false,
          csrfRequired: false,
        },
      );

      dispatch(newCreateRequestAction({ type: AUTH_FORGOT_PASSWORD_REQUEST_SUCCESS }));
      await dispatch(notificationsShow('success', `Password reset link sent to ${email}`));
      window.location.href = '/';
    } catch (err) {
      log(err);
      const message = 'Invalid email';
      dispatch(newCreateRequestAction({ type: AUTH_FORGOT_PASSWORD_REQUEST_SUCCESS, error: message }));
      dispatch(notificationsShow('error', message));
    }
  };

  authForgotPasswordRequest();
};

export const authResetPassword = (credentials) => (dispatch, _getState, { apiClient, log }) => {
  dispatch(newCreateRequestAction({ type: AUTH_RESET_PASSWORD_REQUEST_START, isLoading: true }));

  const authResetPasswordRequest = async () => {
    try {
      await apiClient.httpPatch(
        `${ENDPOINT_PATH}/reset-password/${credentials.resetToken}`,
        _.pick(credentials, ['password']),
        {
          csrfRequired: false,
        },
      );
      dispatch(newCreateRequestAction({ type: AUTH_RESET_PASSWORD_REQUEST_SUCCESS }));

      window.location.href = '/';
    } catch (err) {
      log(err);
      const message = get(err, 'response.data');
      dispatch(newCreateRequestAction({ type: AUTH_RESET_PASSWORD_REQUEST_FAIL, error: message }));
      dispatch(notificationsShow('error', message));
    }
  };

  authResetPasswordRequest();
};

export const authSignupInvite = (credentials) => (dispatch, _getState, { apiClient }) => {
  dispatch(newCreateRequestAction({ type: AUTH_SIGNUP_INVITE_REQUEST_START, isLoading: true }));

  const authSignupInviteRequest = async () => {
    try {
      await apiClient.httpPatch(
        `${ENDPOINT_PATH}/signup-invite/${credentials.token}`,
        _.pick(credentials, ['email', 'firstName', 'lastName', 'password']),
        {
          csrfRequired: false,
        },
      );
      dispatch(newCreateRequestAction({ type: AUTH_RESET_PASSWORD_REQUEST_SUCCESS }));
      dispatch(notificationsShow('success', 'Successfully signed up'));
      window.location.href = '/';
    } catch (err) {
      const message = get(err, 'response.data');
      dispatch(newCreateRequestAction({ type: AUTH_SIGNUP_INVITE_REQUEST_FAIL, error: message }));
      dispatch(notificationsShow('error', message));
    }
  };

  authSignupInviteRequest();
};
