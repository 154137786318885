import {
  React,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { MEDIA_MOBILE_MAX_WIDTH } from '../utility/AppParameters';
import BackgroundVideo from '../components/BackgroudVideo';
import BubbleCard from '../components/BubbleCard';
import PrivacyPolicy from '../components/PivacyPolicy';
import EndUserLicenseAgreement from '../components/EndUserLicenseAgreement';
import TermsAndConditions from '../components/TermsAndConditions';
import CoreCard from '../components/core/content/CoreCard';

function TermsPage() {
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: MEDIA_MOBILE_MAX_WIDTH });
  return (
    <div style={{ paddingBottom: '200px' }}>
      <BubbleCard title="Privacy Policy for Vessel">
        <CoreCard content={(
          <div style={{
            color: 'var(--color-grey900)', paddingLeft: '10px', paddingRight: '10px', fontSize: '12px', fontWeight: '300',
          }}
          >
            <PrivacyPolicy />
          </div>
        )}
        />
      </BubbleCard>
      <BubbleCard title="End-User License Agreement for Vessel">
        <CoreCard content={(
          <div style={{
            color: 'var(--color-grey900)', paddingLeft: '10px', paddingRight: '10px', fontSize: '12px', fontWeight: '300',
          }}
          >
            <EndUserLicenseAgreement />
          </div>
        )}
        />
      </BubbleCard>
      <BubbleCard title="Terms and Conditions">
        <CoreCard content={(
          <div style={{
            color: 'var(--color-grey900)', paddingLeft: '10px', paddingRight: '10px', fontSize: '12px', fontWeight: '300',
          }}
          >
            <TermsAndConditions />
          </div>
        )}
        />
      </BubbleCard>
      {isDesktopOrLaptop && <BackgroundVideo videoSrc="wallpaper.mov" style={{ zIndex: '-1' }} />}
    </div>
  );
}

export default TermsPage;
