import React, { useState } from 'react';
import Button from './core/input/Button';
import { STRIPE_PUBLIC_KEY } from '../config';
import { apiClient } from '../App';
import { log } from '../utility/logging';

function Checkout() {
  const [stripe, setStripe] = useState(null); // Lazy-load Stripe

  const handleClick = async () => {
    if (!stripe) {
      // Load Stripe only when the button is clicked
      const { loadStripe } = await import('@stripe/stripe-js');
      const stripeModule = await loadStripe(STRIPE_PUBLIC_KEY);
      setStripe(stripeModule);

      // Create a new Checkout Session in the backend
      const response = await apiClient.httpPost(
        'subscriptions',
        { priceId: 'price_1NaPnOGirhJimORBDuDeLRxR' }, // replace with your priceId
      );

      const sessionId = response.data.id;

      // When the customer clicks on the button, redirect them to Stripe Checkout
      const result = await stripeModule.redirectToCheckout({ sessionId });

      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network error, display the error
        log(result.error.message);
      }
    }
  };

  return (
    <Button
      style={{ width: 'auto', paddingLeft: '10px', paddingRight: '10px' }}
      primary
      role="link"
      onClick={handleClick}
    >
      Subscribe to Commercial Monthly Plan
    </Button>
  );
}

export default Checkout;
