import {
  React, useEffect, useRef, useState,
} from 'react';
import './InspectionPage.css';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  FaCheck, FaSearch, FaShare, FaTrash,
} from 'react-icons/fa';
import {
  bookingsGetDocument,
  inspectionsDelete,
  inspectionsGet,
  inspectionsGetAvailableStaff,
  inspectionsGetDocument,
  inspectionsImageDelete,
  inspectionsImagesPatch,
  inspectionsImagesAssociate,
  inspectionsImagesDownloadZipGet,
  inspectionsPost,
  inspectionsPut,
  inspectionsPutAssign,
  notificationsShow,
  inspectionsPutSubmitReport,
  inspectionsReportImagesSelect,
  getImagesLeftToSyncCount,
  inspectionsGetSingle,
  organizationGet,
  inspectionsPutMark,
  inspectionsCreateInvoicePost,
  inspectionsDeleteInvoicePost,
  inspectionsInvoicePdfGet,
  inspectionsDeleteReport,
  inspectionsPostGenerateReport,
} from '../actions';
import Button from '../components/core/input/Button';
import InspectionDetailView from '../components/inspection/InspectionDetailView';
import InspectionTabReport from '../components/inspection/tabs/InspectionTabReport';
import TabView from '../components/TabView';
import {
  apiRequestState, get, searchByNestedFields,
  set,
} from '../utility/utility';
import InspectionAssignModal from '../components/inspection/forms/InspectionFormAssign';
import InputField from '../components/core/input/InputField';
import { searchableFieldsInspections } from '../utility/vesselParams';
import TitleCard from '../components/cardLists/TitleCard';
import Checkbox from '../components/core/input/CoreInputCheckbox';
import InspectionImagesDeleteModal from '../components/inspection/forms/InspectionFormImagesDelete';
import InspectionImagesAssociateModal from '../components/inspection/forms/InspectionFormImagesAssociate';
import InspectionSampleGeneralImagesSelectorModal from '../components/inspection/forms/InspectionFormSampleGeneralImagesSelector';
import InspectionImagesSelectorModal from '../components/inspection/forms/InspectionFormImagesSelector';
import InspectionSubmitReportModal from '../components/inspection/forms/InspectionFormSubmitReport';
import InspectionGenerateReportModal from '../components/inspection/forms/InspectionFormGenerateReport';
import InspectionDefectForm from '../components/inspection/forms/InspectionFormDefect';
import InspectionFormDelete from '../components/inspection/forms/InspectionFormDelete';
import InspectionFormParticipant from '../components/inspection/forms/InspectionFormParticipant';
import InspectionFormLogistics from '../components/inspection/forms/InspectionFormLogistics';
import InspectionTabOnsite from '../components/inspection/tabs/InspectionTabOnsite';
import InspectionTabShipment from '../components/inspection/tabs/InspectionTabShipment';
import InspectionTabParticipants from '../components/inspection/tabs/InspectionTabParticipants';
import InspectionFormProduct from '../components/inspection/forms/InspectionFormProduct';
import InspectionFormAssessment from '../components/inspection/forms/InspectionFormAssessment';
import InspectionFormMeasurements from '../components/inspection/forms/InspectionFormMeasurements';
import InspectionTabAnalysis from '../components/inspection/tabs/InspectionTabAnalysis';
import InspectionFormSample from '../components/inspection/forms/InspectionFormSample';
import InspectionFormQualityCheck from '../components/inspection/forms/InspectionFormQualityCheck';
import InspectionFormRequest from '../components/inspection/forms/InspectionFormRequest';
import InspectionCard from '../components/inspection/cards/InspectionCard';
import { INSPECTION_CREATE_FORM_MODES, INSPECTION_RETRIEVE_OPTIONS, INSPECTION_TABS } from '../utility/InspectionsUtils';
import InspectionFormReportImagesSelector from '../components/inspection/forms/InspectionFormReportImagesSelector';
import { flushImages } from '../utility/db';
import Select from '../components/Select';
import InspectionFormCreate from '../components/inspection/forms/InspectionFormCreate';
import InspectionFormMark from '../components/inspection/forms/InspectionFormMark';
import Spinner from '../components/Spinner';
import InspectionFormReportEmail from '../components/inspection/forms/InspectionFormReportEmail';
import InspectionFormCreateInvoice from '../components/inspection/forms/InspectionFormCreateInvoice';

function InspectionPage({
  inspectionsIsLoading,
  availableStaff,
  dispatchBookingsGetDocument,
  dispatchGetImagesLeftToSyncCount,
  dispatchInspectionImageDelete,
  dispatchInspectionImagePatch,
  dispatchInspectionImagesAssociate,
  dispatchInspectionImagesDownloadZipGet,
  dispatchInspectionReportImagesSelect,
  dispatchInspectionsAssign,
  dispatchInspectionsDelete,
  dispatchInspectionsCreateInvoicePost,
  dispatchInspectionsDeleteInvoicePost,
  dispatchInspectionsGet,
  dispatchInspectionsGetAvailableStaff,
  dispatchInspectionsGetDocument,
  dispatchInspectionsFetchInvoiceGet,
  dispatchInspectionsGetSingle,
  dispatchInspectionsMark,
  dispatchInspectionsPost,
  dispatchInspectionsPut,
  dispatchInspectionsReportsDelete,
  dispatchInspectioSubmitReport,
  dispatchInspectionsPostGenerateReport,
  dispatchOrganizationsGet,
  inspections,
  inspectionsDeleteIsLoading,
  inspectionsPostGenerateReportIsLoading,
  inspectionsCreateInvoicePostRequestState,
  inspectionsGetSingleIsLoading,
  inspectionsImageDeleteRequestState,
  inspectionsImagesAssociateRequestState,
  inspectionsImagesDownloadZipGetRequestState,
  inspectionsImagesLeftToSync,
  inspectionsImagesLeftToSyncCountState,
  inspectionsImagesPatchRequestState,
  inspectionsPostIsLoading,
  inspectionsPutAssignIsLoading,
  inspectionsPutMarkIsLoading,
  inspectionsPutIsLoading,
  inspectionsPutSubmitReportIsLoading,
  inspectionsPutUnassignSelfRequestState,
  inspectionsReportImagesSelectRequestState,
  inspectionsPostReportsDeleteIsLoading,
  me,
  organization,
}) {
  // Modals
  const inspectionCreateFormRef = useRef();
  const assignModalRef = useRef();
  const inspectionMarkModalRef = useRef();
  const inspectionDeleteModalRef = useRef();
  const inspectionImagesDeleteModalRef = useRef();
  const inspectionImagesAssociateModalRef = useRef();
  const inspectionImagesSelectorModalRef = useRef();
  const inspectionReportImagesSelectorModalRef = useRef();
  const inspectionSampleGeneralImagesSelectorModalRef = useRef();
  const inspectionSubmitReportModalRef = useRef();
  const inspectionGenerateReportModalRef = useRef();
  const inspectionReportEmailModalRef = useRef();
  const inspectionCreateInvoiceModalRef = useRef();
  const inspectionProductFormRef = useRef();
  const inspectionSampleFormRef = useRef();
  const inspectionDefectFormRef = useRef();
  const inspectionParticipantFormRef = useRef();
  const inspectionShipmentFormRef = useRef();
  const inspectionAssessmentFormRef = useRef();
  const inspectionMeasurementsFormRef = useRef();
  const inspectionQualityCheckFormRef = useRef();
  const inspectionRequestFormRef = useRef();

  const inspectionDisplayOptions = [
    INSPECTION_RETRIEVE_OPTIONS.active,
    INSPECTION_RETRIEVE_OPTIONS.last7days,
    INSPECTION_RETRIEVE_OPTIONS.last30days,
    INSPECTION_RETRIEVE_OPTIONS.completed,
    INSPECTION_RETRIEVE_OPTIONS.all,
  ];

  const defaultInputState = { displayOption: INSPECTION_RETRIEVE_OPTIONS.active };
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedInspectionId, setSelectedInspectionId] = useState(null);
  const [selectedInspection, setSelectedInspection] = useState(null);
  const [selectedInspections, setSelectedInspections] = useState([]);

  const [inputState, setInputState] = useState(defaultInputState);
  const [searchedInspectionsState, setSearchedInspectionsState] = useState(inspections);

  const handleInputChange = (event) => {
    const newState = { ...inputState };
    newState[event.target.id] = event.target.value;

    if (event.target.id === 'displayOption') {
      // Reset the search bar if the display option selection changed. Mostly to not confuse the user.
      set(newState, 'search', '');
      dispatchInspectionsGet({ status: event.target.value });
    }

    setInputState(newState);
  };

  const handleSelect = (id) => {
    const newSelectedInspection = {
      ...inspections.find(
        (inspection) => inspection._id === id,
      ),
    };
    setSelectedInspectionId(id);
    setSelectedInspection(_.cloneDeep(newSelectedInspection));
  };

  const handleSearch = (searchString) => {
    const newState = { ...inputState };
    newState.search = searchString;
    const newInspectionsState = searchByNestedFields(
      inspections,
      searchableFieldsInspections,
      newState.search,
    );

    setSearchedInspectionsState(newInspectionsState);
  };

  useEffect(() => {
    handleSearch(get(inputState, 'search'));
    setSelectedIds([]);
  }, [
    inputState,
    inspectionsIsLoading,
    inspectionsDeleteIsLoading,
    inspectionsPutMarkIsLoading,
    inspectionsPutAssignIsLoading]);

  useEffect(() => {
    setInputState(defaultInputState);
    setSelectedIds([]);
  }, [
    inspectionsPostIsLoading]);

  useEffect(() => {
    // Everytime the page reloads, it fetches all inspections and the inspector organization staff member
    // which are important for the assignment form.
    dispatchInspectionsGet();
    dispatchInspectionsGetAvailableStaff();
  }, []);

  useEffect(() => {
    if (selectedInspectionId
      && (
        inspectionsImagesPatchRequestState === apiRequestState.SUCCESS
        || inspectionsImageDeleteRequestState === apiRequestState.SUCCESS
        || inspectionsImagesAssociateRequestState === apiRequestState.SUCCESS
        || !inspectionsPutIsLoading
        || !inspectionsPutAssignIsLoading
        || !inspectionsPostGenerateReportIsLoading
        || !inspectionsPostReportsDeleteIsLoading
        || !inspectionsPutSubmitReportIsLoading
        || inspectionsImagesLeftToSyncCountState === apiRequestState.SUCCESS
        || inspectionsReportImagesSelectRequestState === apiRequestState.SUCCESS
        || !inspectionsGetSingleIsLoading
      )) {
      handleSelect(selectedInspectionId);
    }
  }, [
    inspectionsGetSingleIsLoading,
    inspectionsImagesPatchRequestState,
    inspectionsImagesAssociateRequestState,
    inspectionsImageDeleteRequestState,
    inspectionsPostIsLoading,
    inspectionsPutIsLoading,
    inspectionsPutAssignIsLoading,
    inspectionsDeleteIsLoading,
    inspectionsPutUnassignSelfRequestState,
    inspectionsPostReportsDeleteIsLoading,
    inspectionsPutSubmitReportIsLoading,
    inspectionsReportImagesSelectRequestState,
    inspectionsPostGenerateReportIsLoading,
    inspections,
  ]);

  useEffect(() => {
    if (selectedInspectionId && (!inspectionsPutIsLoading)) {
      dispatchOrganizationsGet();
    }
  }, [
    inspectionsPutIsLoading,
  ]);

  const handleSave = () => {
    dispatchInspectionsPut(_.cloneDeep(selectedInspection));
  };

  const handleDocumentsAttachmentChange = (documentId) => {
    // Clone the selectedInspection object to avoid direct mutation
    const editedSelectedInspection = { ...selectedInspection };

    // Use a callback function for findIndex
    const index = editedSelectedInspection.documents.findIndex((document) => document.id === documentId);
    if (index < 0) return;

    // Update the isAttached property of the specific document
    editedSelectedInspection.documents[index].isAttached = !editedSelectedInspection.documents[index].isAttached;

    setSelectedInspection(editedSelectedInspection);
  };

  const handleInspectionReviewChange = (event) => {
    const editedSelectedInspection = { ...selectedInspection };
    editedSelectedInspection[event.target.id] = event.target.value;
    setSelectedInspection(editedSelectedInspection);
  };

  const handleButtonInspectionCreateFormOpen = (inspection, mode) => {
    inspectionCreateFormRef.current.open(inspection, mode);
  };
  const handleButtonInspectionsAssignModalOpen = (selInspections, staff) => {
    assignModalRef.current.open(selInspections, staff);
  };
  const handleButtonInspectionsMarkModalOpen = (selInspections, body) => {
    inspectionMarkModalRef.current.open(selInspections, body);
  };
  const handleButtonInspectionDeleteModalOpen = (inspection) => {
    inspectionDeleteModalRef.current.open(inspection);
  };
  const handleButtonInspectionImagesDeleteModalOpen = (inspection, imagesType, imagesIds) => {
    inspectionImagesDeleteModalRef.current.open(inspection, imagesType, imagesIds);
  };
  const handleButtonInspectionImagesAssociateModalOpen = (inspection, imagesTypes, imagesIds) => {
    inspectionImagesAssociateModalRef.current.open(inspection, imagesTypes, imagesIds);
  };
  const handleButtonInspectionSampleGeneralImagesSelectorModalOpen = (inspection, product, sample) => {
    inspectionSampleGeneralImagesSelectorModalRef.current.open(inspection, product, sample);
  };
  const handleButtonInspectionImagesSelectorModalOpen = (inspection, product, sample, defect) => {
    inspectionImagesSelectorModalRef.current.open(inspection, product, sample, defect);
  };
  const handleButtonInspectionReportImagesSelectorModalOpen = (inspection) => {
    inspectionReportImagesSelectorModalRef.current.open(inspection);
  };
  const handleButtonInspectionSubmitReportModalOpen = (inspection, report) => {
    inspectionSubmitReportModalRef.current.open(inspection, report);
  };
  const handleButtonInspectionGenerateReportModalOpen = (inspection, report) => {
    inspectionGenerateReportModalRef.current.open(inspection, report);
  };
  const handleButtonInspectionReportEmailModalOpen = (org, inspection) => {
    inspectionReportEmailModalRef.current.open(org, inspection);
  };
  const handleButtonInspectionCreateInvoiceModalOpen = (org, inspection) => {
    inspectionCreateInvoiceModalRef.current.open(org, inspection);
  };
  const handleButtonInspectionProductFormOpen = (inspection, mode, product, org) => {
    inspectionProductFormRef.current.open(inspection, mode, product, org);
  };
  const handleButtonInspectionSampleFormOpen = (inspection, mode, sample) => {
    inspectionSampleFormRef.current.open(inspection, mode, sample);
  };
  const handleButtonInspectionDefectFormOpen = (org, inspection, sample, defect) => {
    inspectionDefectFormRef.current.open(org, inspection, sample, defect);
  };
  const handleButtonInspectionParticipantFormOpen = (org, inspection, partipantType, participant) => {
    inspectionParticipantFormRef.current.open(org, inspection, partipantType, participant);
  };
  const handleButtonInspectionShipmentFormOpen = (inspection) => {
    inspectionShipmentFormRef.current.open(inspection);
  };
  const handleButtonInspectionAssessmentFormOpen = (inspection) => {
    inspectionAssessmentFormRef.current.open(inspection);
  };
  const handleButtonInspectionMeasurementsFormOpen = (inspection, measurementType, measurement) => {
    inspectionMeasurementsFormRef.current.open(inspection, measurementType, measurement);
  };
  const handleButtonInspectionQualityCheckFormOpen = (inspection) => {
    inspectionQualityCheckFormRef.current.open(inspection);
  };
  const handleButtonInspectionRequestFormOpen = (inspection) => {
    inspectionRequestFormRef.current.open(inspection);
  };

  const tabs = (inspection) => {
    const arr = [];
    const permittedTabs = get(inspection, 'permittedInspectionTabs', []);
    if (permittedTabs.includes(INSPECTION_TABS.participants)) {
      arr.push({
        name: 'Participants',
        content:
  <InspectionTabParticipants
    organization={organization}
    inspectionState={selectedInspection}
    setInspectionState={setSelectedInspection}
    handleButtonInspectionParticipantFormOpen={handleButtonInspectionParticipantFormOpen}
  />,
      });
    }
    if (permittedTabs.includes(INSPECTION_TABS.shipment)) {
      arr.push({
        name: 'Shipment',
        content:
  <InspectionTabShipment
    organization={organization}
    inspectionState={selectedInspection}
    dispatchInspectionsPut={dispatchInspectionsPut}
    setInspectionState={setSelectedInspection}
    dispatchBookingsGetDocument={dispatchBookingsGetDocument}
    handleButtonInspectionShipmentFormOpen={handleButtonInspectionShipmentFormOpen}
    handleButtonInspectionProductFormOpen={handleButtonInspectionProductFormOpen}
    handleSave={handleSave}
  />,
      });
    }
    if (permittedTabs.includes(INSPECTION_TABS.onsite)) {
      arr.push({
        name: 'Onsite',
        content:
  <InspectionTabOnsite
    dispatchInspectionsPut={dispatchInspectionsPut}
    inspectionState={selectedInspection}
    setInspectionState={setSelectedInspection}
    handleSave={handleSave}
    dispatchInspectionImagePatch={dispatchInspectionImagePatch}
    dispatchInspectionsGet={dispatchInspectionsGet}
    handleButtonInspectionImagesDeleteModalOpen={handleButtonInspectionImagesDeleteModalOpen}
    handleButtonInspectionImagesAssociateModalOpen={handleButtonInspectionImagesAssociateModalOpen}
    handleButtonInspectionAssessmentFormOpen={handleButtonInspectionAssessmentFormOpen}
    handleButtonInspectionMeasurementsFormOpen={handleButtonInspectionMeasurementsFormOpen}
  />,
      });
    }
    if (permittedTabs.includes(INSPECTION_TABS.analysis)) {
      arr.push({
        name: 'Analysis',
        content:
  <InspectionTabAnalysis
    organization={organization}
    inspectionState={selectedInspection}
    setInspectionState={setSelectedInspection}
    dispatchInspectionsPut={dispatchInspectionsPut}
    handleButtonInspectionSampleGeneralImagesSelectorModalOpen={handleButtonInspectionSampleGeneralImagesSelectorModalOpen}
    handleButtonInspectionImagesSelectorModalOpen={handleButtonInspectionImagesSelectorModalOpen}
    handleButtonInspectionReportImagesSelectorModalOpen={handleButtonInspectionReportImagesSelectorModalOpen}
    handleButtonInspectionSampleFormOpen={handleButtonInspectionSampleFormOpen}
    handleButtonInspectionDefectFormOpen={handleButtonInspectionDefectFormOpen}
    handleButtonInspectionQualityCheckFormOpen={handleButtonInspectionQualityCheckFormOpen}
  />,
      });
    }
    if (permittedTabs.includes(INSPECTION_TABS.report)) {
      arr.push({
        name: 'Report',
        content:
  <InspectionTabReport
    me={me}
    organization={organization}
    inspectionState={selectedInspection}
    setInspectionState={setSelectedInspection}
    dispatchInspectionsGetSingle={dispatchInspectionsGetSingle}
    dispatchInspectionImagesDownloadZipGet={dispatchInspectionImagesDownloadZipGet}
    dispatchInspectionsGetDocument={dispatchInspectionsGetDocument}
    dispatchInspectionsFetchInvoiceGet={dispatchInspectionsFetchInvoiceGet}
    handleDocumentsAttachmentChange={handleDocumentsAttachmentChange}
    handleInspectionReviewChange={handleInspectionReviewChange}
    dispatchBookingsGetDocument={dispatchBookingsGetDocument}
    dispatchInspectionsReportsDelete={dispatchInspectionsReportsDelete}
    dispatchInspectionsDeleteInvoicePost={dispatchInspectionsDeleteInvoicePost}
    handleButtonInspectionSubmitReportModalOpen={handleButtonInspectionSubmitReportModalOpen}
    handleButtonInspectionGenerateReportModalOpen={handleButtonInspectionGenerateReportModalOpen}
    handleButtonInspectionReportEmailModalOpen={handleButtonInspectionReportEmailModalOpen}
    handleButtonInspectionCreateInvoiceModalOpen={handleButtonInspectionCreateInvoiceModalOpen}
    handleButtonInspectionRequestFormOpen={handleButtonInspectionRequestFormOpen}
  />,
      });
    }
    return arr;
  };

  const renderModals = () => (
    <div>
      <InspectionFormCreate
        ref={inspectionCreateFormRef}
        organization={organization}
        inspectionsPostIsLoading={inspectionsPostIsLoading}
        dispatchInspectionsPost={dispatchInspectionsPost}
      />
      <InspectionAssignModal
        ref={assignModalRef}
        dispatchInspectionsAssign={dispatchInspectionsAssign}
        inspectionsPutAssignIsLoading={inspectionsPutAssignIsLoading}
        availableStaff={availableStaff}
      />
      <InspectionFormMark
        ref={inspectionMarkModalRef}
        dispatchInspectionsMark={dispatchInspectionsMark}
        inspectionsPutMarkIsLoading={inspectionsPutMarkIsLoading}
      />
      <InspectionFormDelete
        ref={inspectionDeleteModalRef}
        dispatchInspectionsDelete={dispatchInspectionsDelete}
        inspectionsDeleteIsLoading={inspectionsDeleteIsLoading}
      />
      <InspectionImagesDeleteModal
        ref={inspectionImagesDeleteModalRef}
        dispatchInspectionImageDelete={dispatchInspectionImageDelete}
        inspectionsImageDeleteRequestState={inspectionsImageDeleteRequestState}
      />
      <InspectionImagesAssociateModal
        ref={inspectionImagesAssociateModalRef}
        dispatchInspectionImagesAssociate={dispatchInspectionImagesAssociate}
        inspectionsImagesAssociateRequestState={inspectionsImagesAssociateRequestState}
      />
      <InspectionSampleGeneralImagesSelectorModal
        ref={inspectionSampleGeneralImagesSelectorModalRef}
        dispatchInspectionImagesAssociate={dispatchInspectionImagesAssociate}
        inspectionsImagesAssociateRequestState={inspectionsImagesAssociateRequestState}
      />
      <InspectionImagesSelectorModal
        ref={inspectionImagesSelectorModalRef}
        dispatchInspectionImagesAssociate={dispatchInspectionImagesAssociate}
        inspectionsImagesAssociateRequestState={inspectionsImagesAssociateRequestState}
      />
      <InspectionFormReportImagesSelector
        ref={inspectionReportImagesSelectorModalRef}
        dispatchInspectionReportImagesSelect={dispatchInspectionReportImagesSelect}
        inspectionsReportImagesSelectRequestState={inspectionsReportImagesSelectRequestState}
      />
      <InspectionSubmitReportModal
        ref={inspectionSubmitReportModalRef}
        inspectionsPutSubmitReportIsLoading={inspectionsPutSubmitReportIsLoading}
        dispatchInspectioSubmitReport={dispatchInspectioSubmitReport}
      />
      <InspectionGenerateReportModal
        ref={inspectionGenerateReportModalRef}
        inspectionsPostGenerateReportIsLoading={inspectionsPostGenerateReportIsLoading}
        dispatchInspectionsPostGenerateReport={dispatchInspectionsPostGenerateReport}
      />
      <InspectionFormReportEmail
        ref={inspectionReportEmailModalRef}
      />
      <InspectionFormCreateInvoice
        ref={inspectionCreateInvoiceModalRef}
        inspectionsCreateInvoicePostRequestState={inspectionsCreateInvoicePostRequestState}
        dispatchInspectionsCreateInvoicePost={dispatchInspectionsCreateInvoicePost}
      />
      <InspectionFormProduct
        ref={inspectionProductFormRef}
        inspectionsPutIsLoading={inspectionsPutIsLoading}
        dispatchInspectionsPut={dispatchInspectionsPut}
      />
      <InspectionFormSample
        ref={inspectionSampleFormRef}
        inspectionsPutIsLoading={inspectionsPutIsLoading}
        dispatchInspectionsPut={dispatchInspectionsPut}
      />
      <InspectionDefectForm
        ref={inspectionDefectFormRef}
        inspectionsPutIsLoading={inspectionsPutIsLoading}
        dispatchInspectionsPut={dispatchInspectionsPut}
      />
      <InspectionFormParticipant
        ref={inspectionParticipantFormRef}
        inspectionsPutIsLoading={inspectionsPutIsLoading}
        dispatchInspectionsPut={dispatchInspectionsPut}
      />
      <InspectionFormLogistics
        ref={inspectionShipmentFormRef}
        inspectionsPutIsLoading={inspectionsPutIsLoading}
        dispatchInspectionsPut={dispatchInspectionsPut}
      />
      <InspectionFormAssessment
        ref={inspectionAssessmentFormRef}
        inspectionsPutIsLoading={inspectionsPutIsLoading}
        dispatchInspectionsPut={dispatchInspectionsPut}
      />
      <InspectionFormMeasurements
        ref={inspectionMeasurementsFormRef}
        inspectionsPutIsLoading={inspectionsPutIsLoading}
        dispatchInspectionsPut={dispatchInspectionsPut}
      />
      <InspectionFormQualityCheck
        ref={inspectionQualityCheckFormRef}
        inspectionsPutIsLoading={inspectionsPutIsLoading}
        dispatchInspectionsPut={dispatchInspectionsPut}
      />
      <InspectionFormRequest
        ref={inspectionRequestFormRef}
        inspectionsPutIsLoading={inspectionsPutIsLoading}
        dispatchInspectionsPut={dispatchInspectionsPut}
      />
    </div>
  );

  const handleMasterCheckboxChange = (event) => {
    const { checked } = event.target;

    if (checked) {
      setSelectedIds(searchedInspectionsState.map((inspection) => inspection._id));
      setSelectedInspections(searchedInspectionsState);
    } else {
      setSelectedIds([]);
      setSelectedInspections([]);
    }
  };

  const selectCheckboxHandler = (id) => {
    const newSelectedIds = [...selectedIds];
    const exists = newSelectedIds.includes(id);

    if (!exists) {
      newSelectedIds.push(id);
    } else {
      const index = newSelectedIds.indexOf(id);
      if (index > -1) {
        newSelectedIds.splice(index, 1);
      }
    }
    setSelectedIds(newSelectedIds);
    setSelectedInspections(_.filter(inspections, (object) => _.includes(newSelectedIds, object._id)));
  };

  const renderCardTitle = () => {
    let title = 'Inspections';

    if (!inspectionsIsLoading) {
      if (get(inputState, 'search')) {
        title += ` (${searchedInspectionsState?.length || 0}/${inspections?.length || 0})`;
      } else {
        title += ` (${inspections?.length || 0})`;
      }
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{title}</span>
        {inspectionsIsLoading && <div style={{ paddingLeft: '12px' }}><Spinner /></div>}
      </div>
    );
  };

  const renderActionIcons = () => {
    const icons = [];

    if (selectedIds.length > 0) {
      icons.push(get(me, 'permissions.inspections.delete', false) && <FaTrash title="Delete" className="action-icon" onClick={() => handleButtonInspectionDeleteModalOpen(selectedInspections)}>Delete</FaTrash>);
      icons.push(get(me, 'permissions.inspections.assign', false) && <FaShare title="Share" className="action-icon" onClick={() => handleButtonInspectionsAssignModalOpen(selectedInspections, availableStaff)}>Assign</FaShare>);
      icons.push(get(me, 'permissions.inspections.mark', false) && <FaCheck title="Mark" className="action-icon" onClick={() => handleButtonInspectionsMarkModalOpen(selectedInspections)}>Mark</FaCheck>);
    }
    icons.push(get(me, 'permissions.inspections.select', false) && searchedInspectionsState.length > 0 && <Checkbox checked={selectedIds.length === searchedInspectionsState.length} onChange={handleMasterCheckboxChange} />);

    return icons;
  };

  const renderDisplayOptions = (user, org) => {
    const owners = get(org, 'owners', []);

    // Check if the user's email is in the owners array
    const isOwner = owners.some((owner) => owner.email === user.email);

    if (!isOwner) return null;

    return (
      <div className="inspections-retrieve-options">
        <Select
          onChange={handleInputChange}
          id="displayOption"
          options={inspectionDisplayOptions}
          value={get(inputState, 'displayOption')}
        />
      </div>
    );
  };

  const handleBackArrow = () => {
    setSelectedInspectionId(null);
  };

  return (
    <div>
      {renderModals()}
      {me?.permissions?.inspections?.create
        && (
          <div className="inspections-add-button">
            <Button onClick={() => handleButtonInspectionCreateFormOpen(null, INSPECTION_CREATE_FORM_MODES.new)} primary>Create inspection</Button>
          </div>
        )}
      <div className="inspections-search-bar">
        <InputField
          id="search"
          placeholder="Search by PO, container ID, Applicant, Product, etc..."
          onChange={(event) => handleInputChange(event)}
          icon={<FaSearch />}
          value={get(inputState, 'search')}
        >
          Search By
        </InputField>
      </div>
      {renderDisplayOptions(me, organization)}
      {selectedInspectionId
        ? (
          <InspectionDetailView
            inspectionId={selectedInspectionId}
            selectedInspection={selectedInspection}
            flushImages={flushImages}
            dispatchGetImagesLeftToSyncCount={dispatchGetImagesLeftToSyncCount}
            dispatchInspectionsGetSingle={dispatchInspectionsGetSingle}
            onBack={handleBackArrow}
            actions={[
            ]}
            onSave={handleSave}
            isLoading={
              inspectionsImagesPatchRequestState === apiRequestState.LOADING
              || inspectionsPostGenerateReportIsLoading
              || inspectionsImageDeleteRequestState === apiRequestState.LOADING
              || inspectionsImagesDownloadZipGetRequestState === apiRequestState.LOADING
            }
            leftToSync={inspectionsImagesLeftToSync}
          >
            <TabView tabs={tabs(searchedInspectionsState.find(
              (inspection) => String(get(inspection, '_id')) === selectedInspectionId,
            ))}
            />
          </InspectionDetailView>
        )
        : null}
      {
        me?.permissions?.inspections?.view
          ? (
            <div className="inspection-table">
              <TitleCard
                actions={renderActionIcons()}
                title={renderCardTitle()}
              // sortOptions={sortOptions}
              // handleTableHeaderClick={handleTableHeaderClick}
              />
              {searchedInspectionsState.map((inspection) => (
                <InspectionCard
                  key={inspection._id}
                  organization={organization}
                  inspection={inspection}
                  onSelect={handleSelect}
                  selectCheckboxHandler={get(me, 'permissions.inspections.select', false) && selectCheckboxHandler}
                  checked={selectedIds.includes(inspection._id)}
                />
              ))}
            </div>
          )
          : null
      }
      <div className="inspection-view-page-last-element" />
    </div>

  );
}

const mapStateToProps = (state) => ({
  availableStaff: get(state, 'inspections.availableStaff', []),
  me: state.users.me,
  organization: state.organizations.organization,
  inspections: get(state, 'inspections.inspections', []),
  inspectionsDeleteIsLoading: get(state, 'inspections.inspectionsDelete.isLoading', false),
  inspectionsGetSingleIsLoading: get(state, 'inspections.inspectionsGetSingle.isLoading', false),
  inspectionsIsLoading: get(state, 'inspections.inspectionsGet.isLoading', false),
  inspectionsPostGenerateReportIsLoading: get(state, 'inspections.inspectionsPostGenerateReport.isLoading', false),
  inspectionsPostIsLoading: get(state, 'inspections.inspectionsPost.isLoading', false),
  inspectionsPostReportsDeleteIsLoading: get(state, 'inspections.inspectionsPostReportsDelete.isLoading', false),
  inspectionsPutAssignIsLoading: get(state, 'inspections.inspectionsPutAssign.isLoading', false),
  inspectionsPutIsLoading: get(state, 'inspections.inspectionsPut.isLoading', false),
  inspectionsPutMarkIsLoading: get(state, 'inspections.inspectionsPutMark.isLoading', false),
  inspectionsPutSubmitReportIsLoading: get(state, 'inspections.inspectionsPutSubmitReport.isLoading', false),

  inspectionsImagesPatchRequestState: state.inspections.inspectionsImagesPatchRequestState,
  inspectionsImagesLeftToSync: state.inspections.inspectionsImagesLeftToSync,
  inspectionsImageDeleteRequestState: state.inspections.inspectionsImageDeleteRequestState,
  inspectionsImagesAssociateRequestState: state.inspections.inspectionsImagesAssociateRequestState,
  inspectionsCreateInvoicePostRequestState: state.inspections.inspectionsCreateInvoicePostRequestState,
  inspectionsImagesDownloadZipGetRequestState: state.inspections.inspectionsImagesDownloadZipGetRequestState,
  inspectionsImagesLeftToSyncCountState: state.inspections.inspectionsImagesLeftToSyncCountState,
  inspectionsReportImagesSelectRequestState: state.inspections.inspectionsReportImagesSelectRequestState,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchBookingsGetDocument: (booking, documentType) => dispatch(bookingsGetDocument(booking, documentType)),
  dispatchGetImagesLeftToSyncCount: (uploadedImages) => dispatch(getImagesLeftToSyncCount(uploadedImages)),
  dispatchInspectionImageDelete: (inspection, request) => dispatch(inspectionsImageDelete(inspection, request)),
  dispatchInspectionImagePatch: (inspection, images, imageType, assignmentId) => dispatch(inspectionsImagesPatch(inspection, images, imageType, assignmentId)),
  dispatchInspectionImagesAssociate: (inspection, association) => dispatch(inspectionsImagesAssociate(inspection, association)),
  dispatchInspectionImagesDownloadZipGet: (inspection, type) => dispatch(inspectionsImagesDownloadZipGet(inspection, type)),
  dispatchInspectionReportImagesSelect: (inspection, selection) => dispatch(inspectionsReportImagesSelect(inspection, selection)),
  dispatchInspectionsAssign: (assignment) => dispatch(inspectionsPutAssign(assignment)),
  dispatchInspectionsCreateInvoicePost: (inspection, request) => dispatch(inspectionsCreateInvoicePost(inspection, request)),
  dispatchInspectionsDelete: (inspection) => dispatch(inspectionsDelete(inspection)),
  dispatchInspectionsDeleteInvoicePost: (inspection, request) => dispatch(inspectionsDeleteInvoicePost(inspection, request)),
  dispatchInspectionsFetchInvoiceGet: (inspection, request, openInPreview) => dispatch(inspectionsInvoicePdfGet(inspection, request, openInPreview)),
  dispatchInspectionsGet: (body) => dispatch(inspectionsGet(body)),
  dispatchInspectionsGetAvailableStaff: () => dispatch(inspectionsGetAvailableStaff()),
  dispatchInspectionsGetDocument: (inspection, document, openInPreview) => dispatch(inspectionsGetDocument(inspection, document, openInPreview)),
  dispatchInspectionsGetSingle: (body) => dispatch(inspectionsGetSingle(body)),
  dispatchInspectionsMark: (body) => dispatch(inspectionsPutMark(body)),
  dispatchInspectionsPost: (inspection) => dispatch(inspectionsPost(inspection)),
  dispatchInspectionsPostGenerateReport: (inspection, service) => dispatch(inspectionsPostGenerateReport(inspection, service)),
  dispatchInspectionsPut: (inspection, body) => dispatch(inspectionsPut(inspection, body)),
  dispatchInspectionsReportsDelete: (inspection, reportIds) => dispatch(inspectionsDeleteReport(inspection, reportIds)),
  dispatchInspectioSubmitReport: (inspection, report) => dispatch(inspectionsPutSubmitReport(inspection, report)),
  dispatchNotification: (severity, message) => dispatch(notificationsShow(severity, message)),
  dispatchOrganizationsGet: () => dispatch(organizationGet()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InspectionPage);
