import { apiRequestState } from '../utility/utility';

const ENDPOINT_PATH = 'subscriptions';
export const SUBSCRIPTIONS_GET_REQUEST_START = 'SUBSCRIPTIONS_GET_REQUEST_START';
export const SUBSCRIPTIONS_GET_REQUEST_SUCCESS = 'SUBSCRIPTIONS_GET_REQUEST_SUCCESS';
export const SUBSCRIPTIONS_GET_REQUEST_FAIL = 'SUBSCRIPTIONS_GET_REQUEST_FAIL';
export const SUBSCRIPTIONS_GET_REQUEST_IDLE = 'SUBSCRIPTIONS_GET_REQUEST_IDLE';

export const subscriptionsGetStart = () => ({
  type: SUBSCRIPTIONS_GET_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const subscriptionsGetSuccess = (subscription) => ({
  type: SUBSCRIPTIONS_GET_REQUEST_SUCCESS,
  subscription,
  requestState: apiRequestState.SUCCESS,
});

export const subscriptionsGetFail = () => ({
  type: SUBSCRIPTIONS_GET_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const subscriptionsGetIdle = () => ({
  type: SUBSCRIPTIONS_GET_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const subscriptionsGet = () => (dispatch, _getState, { apiClient, log }) => {
  dispatch(subscriptionsGetStart());
  const subscriptionsGetRequest = async () => {
    try {
      const response = await apiClient.httpGet(`${ENDPOINT_PATH}/`);
      dispatch(subscriptionsGetSuccess(response.data));
    } catch (err) {
      log(err);
      dispatch(subscriptionsGetFail());
    }
  };

  subscriptionsGetRequest();
};
