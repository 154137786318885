import React, { useEffect, useState } from 'react';
import './CoreCard.css';

import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import CoreCardActionMenu from './CoreCardActionMenu';
import Button from '../input/Button';
import Spinner from '../../Spinner';

function CoreCard({
  content,
  mainActionLabel,
  mainActionOnClick,
  actions = [],
  isExpandable = false,
  isAlwaysExpanded = false,
  expandedContent = null,
  style,
  isHoverable,
  isLoading = false,
}) {
  // Adjust initial state to consider both isExpandable and isAlwaysExpanded
  const [isExpanded, setIsExpanded] = useState(isAlwaysExpanded);

  useEffect(() => {
    // Ensure isExpanded is true if isAlwaysExpanded, respecting dynamic changes
    if (isAlwaysExpanded) {
      setIsExpanded(true);
    }
    // Optionally handle the case where isAlwaysExpanded becomes false
    // You might want to set isExpanded based on isExpandable here, if needed
  }, [isAlwaysExpanded]);

  const toggleExpanded = () => {
    if (isExpandable && !isAlwaysExpanded) { // Check if not always expanded
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <div className={`core-card-container ${isHoverable ? 'core-card-hoverable' : ''}`} style={style}>
      <div className="core-card-header">
        <div className="core-card-header-content-wrapper">
          <div className="core-card-header-content">{content}</div>
          {expandedContent !== null && isExpandable && !isAlwaysExpanded && (
            <div className="core-card-header-arrow" data-testid="toggle-expand" onClick={toggleExpanded}>
              {isExpanded
                ? <FaChevronUp data-testid="chevron-up-icon" />
                : <FaChevronDown data-testid="chevron-down-icon" />}
            </div>
          )}
        </div>
        {mainActionLabel
          && (
            <Button
              style={{ width: 'auto', minWidth: '125px', paddingLeft: '10px' }}
              onClick={mainActionOnClick}
              primary
              simple
            >
              {mainActionLabel}
            </Button>
          )}
        {isLoading && <div style={{ paddingRight: '10px' }}><Spinner /></div>}
        <CoreCardActionMenu actions={actions} />
      </div>
      {isExpanded && expandedContent && (
        <div className="core-card-expanded-content">
          {expandedContent}
        </div>
      )}
    </div>
  );
}

export default CoreCard;
