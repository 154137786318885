import React from 'react';
import BubbleCard from '../../BubbleCard';
import {
  get,
} from '../../../utility/utility';
import CoreCard from '../../core/content/CoreCard';
import Button from '../../core/input/Button';
import { QUICKBOOKS_CLIENT_ID, QUICKBOOKS_REDIRECT_URI } from '../../../config';

function OrganizationBubbleIntegration({
  organization,
  style,
}) {
  const quickBooksAuthUrl = `https://appcenter.intuit.com/connect/oauth2?client_id=${QUICKBOOKS_CLIENT_ID}&redirect_uri=${QUICKBOOKS_REDIRECT_URI}&response_type=code&scope=com.intuit.quickbooks.accounting&state=YOUR_CUSTOM_STATE`;

  const handleConnectClick = () => {
    window.open(quickBooksAuthUrl.trim(), '_blank');
  };

  const renderQuickbooksConnectionStatus = () => {
    const isActive = get(organization, 'integration.quickbooks.isActive');
    const companyName = get(organization, 'integration.quickbooks.companyName');

    if (!isActive) {
      return <div><Button style={{ paddingLeft: '10px', paddingRight: '10px' }} primary onClick={handleConnectClick}>Connect to Quickbooks</Button></div>;
    }
    return <div style={{ paddingLeft: '10px' }}>{`Quickbooks Connected: ${companyName}`}</div>;
  };

  return (
    <BubbleCard title="Integration" style={style}>
      <CoreCard
        key={get(organization, '_id')}
        content={(
          <div>{renderQuickbooksConnectionStatus()}</div>
        )}
      />
    </BubbleCard>
  );
}

export default OrganizationBubbleIntegration;
