import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import _ from 'lodash';
import { capitalizeFirstLetter } from '../../../utility/utility';

import { createBookingShareableFields } from '../../../utility/vesselLogic';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import Checkbox from '../../core/input/CoreInputCheckbox';
import InputField from '../../core/input/InputField';

const BookingFormShare = forwardRef(({ dispatchBookingsShare, bookingsPutShareIsLoading }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentBookings, setCurrentBookings] = useState([]);
  const [inputState, setInputState] = useState({});
  const [shareableFieldsState, setShareableFieldsState] = useState({});
  const [error, setError] = useState(null);

  useImperativeHandle(ref, () => ({
    open: (bookings, bookingSettings) => {
      setCurrentBookings(bookings);
      setIsOpen(true);
      setShareableFieldsState(createBookingShareableFields(bookings[0], bookingSettings));
      setError(null);
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!bookingsPutShareIsLoading) {
      closeModal();
    }
  }, [bookingsPutShareIsLoading]);

  const validateForm = (state) => {
    if (!state?.email) {
      setError('Recipient Email is required.');
      return false;
    }
    setError(null);
    return true;
  };

  const handleInputChange = (event) => {
    const newState = { ...inputState };
    newState[event.target.id] = event.target.value;
    setInputState(newState);
    validateForm(newState);
  };

  const handlePermissionChange = (event) => {
    const { id, checked } = event.target;
    setShareableFieldsState((prevState) => {
      const newState = { ...prevState };

      if (newState[id] && typeof newState[id] === 'object') {
        Object.keys(newState[id]).forEach((key) => {
          newState[id][key] = checked;
        });
      } else {
        for (const parentKey in newState) {
          if (newState[parentKey][id] !== undefined) {
            newState[parentKey][id] = checked;
            break;
          }
        }
      }
      return newState;
    });
  };

  const handleShare = () => {
    if (validateForm(inputState)) {
      const share = { ...inputState };
      share.permittedKeys = shareableFieldsState;
      dispatchBookingsShare(currentBookings.map((booking) => booking._id), share);
    }
  };

  if (!isOpen) return null;

  return (
    <CoreFormDualAction
      title="Share Booking"
      primaryButtonCaption="Share"
      primaryButtonCallback={handleShare}
      primaryButtonCallbackStatus={bookingsPutShareIsLoading}
      primaryButtonDisabled={error || bookingsPutShareIsLoading}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <p>Start sharing info about container id: .</p>
      <InputField id="email" onChange={handleInputChange} label="Email" type="email" value={inputState?.email} />
      {error && <div style={{ color: 'var(--color-warning)' }}>{error}</div>}
      <div style={{ paddingTop: '15px', fontWeight: '600' }}>Shareable fields</div>
      {
        Object.keys(shareableFieldsState).map((parentKey) => (
          <div key={parentKey}>
            <div style={{ paddingTop: '10px' }}>
              <Checkbox
                id={parentKey}
                onChange={handlePermissionChange}
                checked={Object.values(shareableFieldsState[parentKey]).every((value) => value)}
              >
                {capitalizeFirstLetter(_.startCase(parentKey))}
              </Checkbox>
            </div>
            <div style={{ paddingLeft: '20px' }}>
              {
                Object.keys(shareableFieldsState[parentKey]).map((childKey) => (
                  <Checkbox
                    key={childKey}
                    id={childKey}
                    onChange={handlePermissionChange}
                    checked={shareableFieldsState[parentKey][childKey]}
                  >
                    {capitalizeFirstLetter(_.startCase(childKey))}
                  </Checkbox>
                ))
              }
            </div>
          </div>
        ))
      }
    </CoreFormDualAction>
  );
});

export default BookingFormShare;
