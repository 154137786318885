import { StatusCodes } from 'http-status-codes';
import { apiRequestState, createRequestAction, newCreateRequestAction } from '../utility/utility';
import { notificationsShow } from './notifications';

const ENDPOINT_PATH = 'organizations';
export const ORGANIZATION_GET_REQUEST_START = 'ORGANIZATION_GET_REQUEST_START';
export const ORGANIZATION_GET_REQUEST_SUCCESS = 'ORGANIZATION_GET_REQUEST_SUCCESS';
export const ORGANIZATION_GET_REQUEST_FAIL = 'ORGANIZATION_GET_REQUEST_FAIL';
export const ORGANIZATION_GET_INSPECTORS_REQUEST_START = 'ORGANIZATION_GET_INSPECTORS_REQUEST_START';
export const ORGANIZATION_GET_INSPECTORS_REQUEST_SUCCESS = 'ORGANIZATION_GET_INSPECTORS_REQUEST_SUCCESS';
export const ORGANIZATION_GET_INSPECTORS_REQUEST_FAIL = 'ORGANIZATION_GET_INSPECTORS_REQUEST_FAIL';
export const ORGANIZATION_PUT_REQUEST_START = 'ORGANIZATION_PUT_REQUEST_START';
export const ORGANIZATION_PUT_REQUEST_SUCCESS = 'ORGANIZATION_PUT_REQUEST_SUCCESS';
export const ORGANIZATION_PUT_REQUEST_FAIL = 'ORGANIZATION_PUT_REQUEST_FAIL';
export const ORGANIZATION_PUT_REQUEST_IDLE = 'ORGANIZATION_PUT_REQUEST_IDLE';

export const organizationGet = () => async (dispatch, _getState, { apiClient, log }) => {
  try {
    dispatch(newCreateRequestAction({ type: ORGANIZATION_GET_REQUEST_START, isLoading: true }));
    const { data } = await apiClient.httpGet(`${ENDPOINT_PATH}/`);
    dispatch(newCreateRequestAction({ type: ORGANIZATION_GET_REQUEST_SUCCESS, data }));
  } catch (err) {
    log(err);
    const message = 'Failed to retrieve organization profile';
    dispatch(newCreateRequestAction({ type: ORGANIZATION_GET_REQUEST_FAIL, error: message }));
  }
};

export const organizationGetInspectors = () => async (dispatch, _getState, { apiClient, log }) => {
  try {
    dispatch(newCreateRequestAction({ type: ORGANIZATION_GET_INSPECTORS_REQUEST_START, isLoading: true }));
    const { data } = await apiClient.httpGet(`${ENDPOINT_PATH}/inspectors`);
    dispatch(newCreateRequestAction({ type: ORGANIZATION_GET_INSPECTORS_REQUEST_SUCCESS, data }));
  } catch (err) {
    log(err);
    const message = 'Failed to retrieve inspector organizations';
    dispatch(newCreateRequestAction({ type: ORGANIZATION_GET_INSPECTORS_REQUEST_FAIL, error: message }));
  }
};

export const organizationPutStart = () => createRequestAction(ORGANIZATION_PUT_REQUEST_START, apiRequestState.LOADING);
export const organizationPutSuccess = (organization) => createRequestAction(ORGANIZATION_PUT_REQUEST_SUCCESS, apiRequestState.SUCCESS, organization);
export const organizationPutFail = () => createRequestAction(ORGANIZATION_PUT_REQUEST_FAIL, apiRequestState.FAIL);
export const organizationPutIdle = () => createRequestAction(ORGANIZATION_PUT_REQUEST_IDLE, apiRequestState.IDLE);
export const organizationPut = (body) => (dispatch, _getState, { apiClient }) => {
  dispatch(organizationPutStart());
  const organizationPutRequest = async () => {
    try {
      const response = await apiClient.httpPut('organizations', body);
      await dispatch(organizationPutSuccess(response.data));
      await dispatch(organizationPutIdle());
      dispatch(notificationsShow('success', 'Successfully updated organization'));
    } catch (err) {
      dispatch(organizationPutFail());
      if (err.response.status === StatusCodes.BAD_REQUEST) {
        dispatch(notificationsShow('warning', `${err.response.data}`));
      } else {
        dispatch(notificationsShow('error', 'Failed to update organization'));
      }
    }
  };

  organizationPutRequest();
};
