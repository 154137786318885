import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import { get } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import { INSPECTION_CREATE_FORM_MODES } from '../../../utility/InspectionsUtils';
import {
  renderInputField, validateForm,
} from '../../core/input/CoreInputUtils';

const fieldsToValidate = ['commodity'];
const InspectionFormCreate = forwardRef(({
  organization,
  inspectionsPostIsLoading,
  dispatchInspectionsPost,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentInspection, setCurrentInspection] = useState(null);
  const [inputState, setInputState] = useState({});
  const [modeState, setModeState] = useState(null);
  const [error, setError] = useState({});

  useImperativeHandle(ref, () => ({
    open: (inspection, mode) => {
      setCurrentInspection(inspection);
      setInputState(get(inspection, 'request', {}));
      setIsOpen(true);
      setModeState(mode);
      setError({});
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    if (!inspectionsPostIsLoading) {
      closeModal();
    }
  }, [inspectionsPostIsLoading]);

  if (!isOpen) return null;

  const onInputChange = (event, path) => {
    const newState = { ...inputState };
    newState[path] = event.target.value;
    setInputState(newState);
    validateForm({
      state: newState,
      fieldsToValidate,
      setError,
    });
  };
  const handleSave = () => {
    const validation = validateForm({
      state: inputState,
      fieldsToValidate,
      setError,
    });
    if (validation) {
      const serviceId = get(organization, 'inspectionSettings.services.0._id', null);
      const request = {
        inspectorOrganizationId: organization._id,
        serviceId,
      };

      dispatchInspectionsPost({
        request,
        commodity: inputState.commodity,
        applicantName: inputState.name,
        purchaseOrder: inputState.purchaseOrder,
      });
    }
  };

  const renderTitle = (mode) => {
    switch (mode) {
      case INSPECTION_CREATE_FORM_MODES.new:
        return 'New Inspection';
      case INSPECTION_CREATE_FORM_MODES.edit:
        return 'Edit Inspection';
      case INSPECTION_CREATE_FORM_MODES.duplicate:
        return 'Duplicate Inspection';
      default:
        return 'Inspection';
    }
  };

  return (
    <CoreFormDualAction
      title={renderTitle(modeState)}
      primaryButtonCaption="Save"
      primaryButtonCallback={() => handleSave(currentInspection, inputState)}
      primaryButtonCallbackStatus={inspectionsPostIsLoading}
      primaryButtonDisabled={Object.keys(error).length > 0 || inspectionsPostIsLoading}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      {renderInputField({
        id: 'commodity', label: 'Commodity Description', inputState, onInputChange, error,
      })}
      {renderInputField({
        id: 'name', label: 'Applicant Name', inputState, onInputChange, error,
      })}
      {renderInputField({
        id: 'purchaseOrder', inputState, onInputChange, error,
      })}
    </CoreFormDualAction>
  );
});

export default InspectionFormCreate;
