import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import { getBookingIdentifier } from '../../../utility/BookingsUtility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';

const BookingFormDelete = forwardRef(({
  dispatchBookingsDelete,
  bookingsDeleteIsLoading,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentBookings, setCurrentBookings] = useState([]);

  useImperativeHandle(ref, () => ({
    open: (bookings) => {
      setCurrentBookings([...bookings]);
      setIsOpen(true);
    },
    close: () => setIsOpen(false),
  }));

  useEffect(() => {
    if (!bookingsDeleteIsLoading) {
      setIsOpen(false);
    }
  }, [bookingsDeleteIsLoading]);

  if (!isOpen) return null;

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <CoreFormDualAction
      title="Delete Booking"
      primaryButtonCaption="Delete"
      primaryButtonCallback={() => dispatchBookingsDelete({ bookings: currentBookings.map((booking) => booking._id) })}
      primaryButtonCallbackStatus={bookingsDeleteIsLoading}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <div>
        <p>
          Confirm booking deletion for:
        </p>
        {currentBookings.map((bookingElement) => (
          <p key={getBookingIdentifier(bookingElement).identifier} style={{ paddingLeft: '25px' }}>
            <span>- </span>
            {getBookingIdentifier(bookingElement).identifier}
            <span>: </span>
            {getBookingIdentifier(bookingElement).value}
          </p>
        ))}
      </div>
    </CoreFormDualAction>
  );
});

export default BookingFormDelete;
