import { get, set } from './utility';

const retrieveCsrfToken = ({ csrfTokenGet }) => {
  try {
    const { token } = csrfTokenGet();
    return token;
  } catch (err) {
    return null;
  }
};

const executeHttpMethod = async ({
  httpGet,
  httpDelete,
  method,
  data,
  apiRoute,
  config,
}) => {
  try {
    // GET
    if (method === httpGet) return { response: await method(apiRoute, { ...config, params: data }) };

    // DELETE
    if (method === httpDelete) {
      if (data) {
        set(config, 'data', data);
      }
      return { response: await method(apiRoute, config) };
    }

    // PUT, PATCH, POST
    return { response: await method(apiRoute, data, config) };
  } catch (error) {
    return { error };
  }
};

// eslint-disable-next-line
export const createApiClient = ({
  csrfTokenGet,
  apiBaseUrl,
  httpGet,
  httpPatch,
  httpPost,
  httpPut,
  httpDelete,
}) => {
  // Function to refresh the access token
  const refreshAccessToken = async (config) => {
    try {
      // The refresh token is already in a cookie.
      const response = await httpPost(`${apiBaseUrl}/auth/refresh-token`, null, config);
      const status = get(response, 'status', false);
      return { status };
    } catch (error) {
      return null;
    }
  };

  // Wrapper function to add authorization and CSRF token to requests
  const request = async (method, url, data = null, options = {}) => {
    const { csrfRequired = true, withCredentials = true, responseType } = options;
    const config = { withCredentials, headers: { ...options.headers } };

    // Add responseType directly to config
    if (responseType) {
      config.responseType = responseType;
    }

    const apiRoute = `${apiBaseUrl}/${url}`;

    if (csrfRequired) {
      const csrfToken = retrieveCsrfToken({ csrfTokenGet });
      if (!csrfToken) throw Error('Missing CSRF token');
      set(config, 'headers.x-csrf-token', csrfToken);
    }

    const { response, error } = await executeHttpMethod({
      httpGet, httpDelete, method, data, apiRoute, config,
    });
    if (response && !error) return response;

    const errorMessage = get(error, 'response.data.message').toLowerCase();
    const errorStatus = get(error, 'response.status');
    if (errorStatus !== 401 || errorMessage !== 'refresh access token') {
      throw error;
    }

    const { status } = await refreshAccessToken(config);
    if (status !== 200) throw new Error('Failed to refresh token');

    const { response: retryResponse, error: retryError } = await executeHttpMethod({
      httpGet, httpDelete, method, data, apiRoute, config,
    });
    if (retryResponse && !retryError) return retryResponse;

    throw retryError;
  };

  // Return wrapped functions with automatic token handling
  return {
    httpGet: (url, data, options) => request(httpGet, url, data, options),
    httpPatch: (url, data, options) => request(httpPatch, url, data, options),
    httpPost: (url, data, options) => request(httpPost, url, data, options),
    httpPut: (url, data, options) => request(httpPut, url, data, options),
    httpDelete: (url, data, options) => request(httpDelete, url, data, options),
  };
};
