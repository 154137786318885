import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import _ from 'lodash';
import { get } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import { CARRIER_TYPES } from '../../../utility/vesselParams';
import { renderDateTimePicker, renderInputField, renderSelectField } from '../../core/input/CoreInputUtils';

const InspectionFormLogistics = forwardRef(({
  dispatchInspectionsPut,
  inspectionsPutIsLoading,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentInspection, setCurrentInspection] = useState(null);
  const [inputState, setInputState] = useState({});
  useImperativeHandle(ref, () => ({
    open: (inspection) => {
      setCurrentInspection(inspection);
      setInputState(_.pick(get(inspection, 'shipment.logistics', {}), [
        'commodity',
        'purchaseOrder',
        'billOfLading',
        'shipperReference',
        'carrierType',
        'carrierReference',
        'carrierVehicle',
        'arrivalTimestamp.date',
        'arrivalTimestamp.time',
      ]));
      setIsOpen(true);
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    if (!inspectionsPutIsLoading) {
      closeModal();
    }
  }, [inspectionsPutIsLoading]);

  if (!isOpen) return null;

  const onInputChange = (event, path) => {
    const newState = { ...inputState };
    newState[path] = event.target.value;
    setInputState(newState);
  };

  const handleSave = (inpsection, state) => {
    dispatchInspectionsPut(inpsection, { shipment: { logistics: { ...state } } });
  };

  return (
    <CoreFormDualAction
      title="Logistics"
      primaryButtonCaption="Save"
      primaryButtonCallback={() => handleSave(currentInspection, inputState)}
      primaryButtonCallbackStatus={inspectionsPutIsLoading}
      primaryButtonDisabled={inspectionsPutIsLoading}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      {renderInputField({
        id: 'commodity', label: 'Commodity Description', inputState, onInputChange,
      })}
      {renderInputField({ id: 'purchaseOrder', inputState, onInputChange })}
      {renderInputField({ id: 'billOfLading', inputState, onInputChange })}
      {renderInputField({ id: 'shipperReference', inputState, onInputChange })}
      {renderSelectField({
        id: 'carrierType', inputState, onInputChange, options: Object.keys(CARRIER_TYPES),
      })}
      {renderInputField({ id: 'carrierName', inputState, onInputChange })}
      {renderInputField({ id: 'carrierVehicle', inputState, onInputChange })}
      {renderInputField({ id: 'carrierReference', inputState, onInputChange })}
      {renderDateTimePicker({
        id: 'arrivalTimestamp',
        inputState,
        onInputChange,
      })}
    </CoreFormDualAction>
  );
});

export default InspectionFormLogistics;
