import { apiRequestState } from '../utility/utility';

export const REPORTS_CONTAINERS_GET_REQUEST_START = 'REPORTS_CONTAINERS_GET_REQUEST_START';
export const REPORTS_CONTAINERS_GET_REQUEST_SUCCESS = 'REPORTS_CONTAINERS_GET_REQUEST_SUCCESS';
export const REPORTS_CONTAINERS_GET_REQUEST_FAIL = 'REPORTS_CONTAINERS_GET_REQUEST_FAIL';
export const REPORTS_CONTAINERS_GET_REQUEST_IDLE = 'REPORTS_CONTAINERS_GET_REQUEST_IDLE';

export const reportsContainersGetStart = () => ({
  type: REPORTS_CONTAINERS_GET_REQUEST_START,
  requestState: apiRequestState.LOADING,
});

export const reportsContainersGetSuccess = (bookings) => ({
  type: REPORTS_CONTAINERS_GET_REQUEST_SUCCESS,
  bookings,
  requestState: apiRequestState.SUCCESS,
});

export const reportsContainersGetFail = () => ({
  type: REPORTS_CONTAINERS_GET_REQUEST_FAIL,
  requestState: apiRequestState.FAIL,
});

export const reportsContainersGetIdle = () => ({
  type: REPORTS_CONTAINERS_GET_REQUEST_IDLE,
  requestState: apiRequestState.IDLE,
});

export const reportsContainersGet = (startDate, endDate) => (dispatch, _getState, { apiClient }) => {
  dispatch(reportsContainersGetStart());
  const reportsContainersGetRequest = async () => {
    try {
      const response = await apiClient.httpGet(`reports/bookings?startDate=${startDate}&endDate=${endDate}`);
      await dispatch(reportsContainersGetSuccess(response.data));
      await dispatch(reportsContainersGetIdle());
    } catch (err) {
      dispatch(reportsContainersGetFail());
    }
  };

  reportsContainersGetRequest();
};
