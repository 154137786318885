import React from 'react';
import { FaEdit } from 'react-icons/fa';
import {
  get, permissionCheck, uuid,
} from '../../../utility/utility';
import InspectionBubbleRequest from '../bubbles/InspectionBubbleRequest';
import InspectionBubbleReport from '../bubbles/InspectionBubbleReport';
import InspectionBubbleInvoices from '../bubbles/InspectionBubbleInvoices';

function InspectionTabReport({
  me,
  organization,
  inspectionState,
  dispatchInspectionsGetSingle,
  dispatchInspectionsGetDocument,
  dispatchInspectionsFetchInvoiceGet,
  dispatchInspectionImagesDownloadZipGet,
  dispatchInspectionsReportsDelete,
  dispatchInspectionsDeleteInvoicePost,
  handleButtonInspectionSubmitReportModalOpen,
  handleButtonInspectionGenerateReportModalOpen,
  handleButtonInspectionReportEmailModalOpen,
  handleButtonInspectionCreateInvoiceModalOpen,
  handleButtonInspectionRequestFormOpen,
}) {
  return (
    <div>
      <InspectionBubbleRequest
        inspection={inspectionState}
        request={get(inspectionState, 'request', {})}
        dispatchInspectionImagesDownloadZipGet={dispatchInspectionImagesDownloadZipGet}
        actions={[
          {
            _id: uuid(),
            text: 'Edit',
            icon: <FaEdit title="Edit" />,
            onClick: () => handleButtonInspectionRequestFormOpen(inspectionState),
          },
        ]}
      />
      <InspectionBubbleReport
        organization={organization}
        inspection={inspectionState}
        dispatchInspectionsGetSingle={dispatchInspectionsGetSingle}
        handleButtonInspectionGenerateReportModalOpen={handleButtonInspectionGenerateReportModalOpen}
        handleButtonInspectionReportEmailModalOpen={handleButtonInspectionReportEmailModalOpen}
        handleButtonInspectionCreateInvoiceModalOpen={handleButtonInspectionCreateInvoiceModalOpen}
        dispatchInspectionsReportsDelete={dispatchInspectionsReportsDelete}
        dispatchInspectionsGetDocument={dispatchInspectionsGetDocument}
        handleButtonInspectionSubmitReportModalOpen={handleButtonInspectionSubmitReportModalOpen}
        report={get(inspectionState, 'report', {})}
      />
      {permissionCheck(me, ['permissions.integrations.view'])
        && (
        <InspectionBubbleInvoices
          organization={organization}
          inspection={inspectionState}
          // dispatchInspectionsGetSingle={dispatchInspectionsGetSingle}
          // handleButtonInspectionGenerateReportModalOpen={handleButtonInspectionGenerateReportModalOpen}
          // handleButtonInspectionReportEmailModalOpen={handleButtonInspectionReportEmailModalOpen}
          dispatchInspectionsDeleteInvoicePost={dispatchInspectionsDeleteInvoicePost}
          handleButtonInspectionCreateInvoiceModalOpen={handleButtonInspectionCreateInvoiceModalOpen}
          // dispatchInspectionsReportsDelete={dispatchInspectionsReportsDelete}
          dispatchInspectionsFetchInvoiceGet={dispatchInspectionsFetchInvoiceGet}
        />
        )}
    </div>
  );
}

export default InspectionTabReport;
