import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import _ from 'lodash';
import { convertTemperature, set } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import { TEMPERATURE_TYPES, TEMPERATURE_UNITS } from '../../../utility/vesselParams';
import { renderInputField, renderSelectField, validateForm } from '../../core/input/CoreInputUtils';

const fieldsToValidate = ['description', 'value', 'unit'];
const InspectionFormMeasurements = forwardRef(({
  dispatchInspectionsPut,
  inspectionsPutIsLoading,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentInspection, setCurrentInspection] = useState(null);
  const [currentMeasurementType, setCurrentMeasurementType] = useState(null);
  const [currentMeasurement, setCurrentMeasurement] = useState(null);
  const [inputState, setInputState] = useState({});
  const [error, setError] = useState({});

  useImperativeHandle(ref, () => ({
    open: (inspection, measurementType, measurement) => {
      setCurrentInspection(inspection);
      setCurrentMeasurementType(measurementType);
      setCurrentMeasurement(measurement);
      setIsOpen(true);
      setError({});
      if (!measurement) {
        setInputState({ unit: 'F' });
      } else {
        setInputState({ ...measurement });
      }
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!inspectionsPutIsLoading) {
      closeModal();
    }
  }, [inspectionsPutIsLoading]);

  if (!isOpen) return null;

  const onInputChange = (event) => {
    const newState = { ...inputState };
    if (event.target.id === 'unit') {
      // Check if there's a value to convert
      const currentValue = inputState.value;
      if (currentValue && inputState.unit) {
        const convertedValue = convertTemperature(currentValue, inputState.unit, event.target.value);
        newState.value = convertedValue;
      }
    }

    newState[event.target.id] = event.target.value;
    setInputState(newState);

    validateForm({
      state: newState,
      fieldsToValidate,
      setError,
    });
  };

  const handleSave = (inspection, measurementType, measurement) => {
    if (validateForm({
      state: inputState,
      fieldsToValidate,
      setError,
    })) {
      if (measurement) {
        const measurementsToUpdate = [inputState];
        const update = {};
        set(update, `onsite.measurements.${measurementType}ToUpdate`, measurementsToUpdate);
        dispatchInspectionsPut(inspection, update);
      } else {
        // Creating a new measurement
        const measurementsToAdd = [inputState];
        const update = {};
        set(update, `onsite.measurements.${measurementType}ToAdd`, measurementsToAdd);
        dispatchInspectionsPut(inspection, update);
      }
    }
  };

  return (
    <CoreFormDualAction
      title="Measurements"
      primaryButtonCaption="Save"
      primaryButtonCallback={() => handleSave(currentInspection, currentMeasurementType, currentMeasurement)}
      primaryButtonCallbackStatus={inspectionsPutIsLoading}
      primaryButtonDisabled={Object.keys(error).length > 0 || inspectionsPutIsLoading}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      {renderSelectField({
        id: 'description',
        inputState,
        onInputChange,
        options: Object.keys(TEMPERATURE_TYPES).map(((element) => ({ label: _.startCase(element) }))),
        error,
      })}
      {renderInputField({
        id: 'value', inputState, onInputChange, error,
      })}
      {renderSelectField({
        id: 'unit',
        inputState,
        onInputChange,
        options: TEMPERATURE_UNITS.map(((element) => ({ label: _.startCase(element) }))),
        error,
      })}
    </CoreFormDualAction>
  );
});

export default InspectionFormMeasurements;
