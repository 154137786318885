import React from 'react';
import _ from 'lodash';
import { get } from '../../../utility/utility';
import CoreCard from '../../core/content/CoreCard';
import BubbleCard from '../../BubbleCard';
import Button from '../../core/input/Button';
import { INSPECTION_IMAGE_DOWNLOAD_ZIP_TYPES } from '../../../utility/InspectionsUtils';

function InspectionBubbleRequest({
  request,
  actions,
  inspection,
  dispatchInspectionImagesDownloadZipGet,
}) {
  const renderField = ({
    request: currentRequest,
    id,
    label = _.startCase(id),
    path = id,
  }) => (
    <div>
      {`${label}: ${get(currentRequest, path)}`}
    </div>
  );

  const onsiteImages = get(inspection, 'onsite.productImages', []);
  const imagesSelectedForReport = onsiteImages.filter((image) => image.isShownOnReport);

  return (
    <BubbleCard
      title="Request"
      style={{ marginBottom: '10px' }}
    >
      <CoreCard
        content={(
          <div style={{ paddingLeft: '10px', color: 'var(--color-grey900)' }}>
            {renderField({ request, id: 'certificateId' })}
            {renderField({ request, id: 'status' })}
            {renderField({ request, id: 'timestamp.date', label: 'Request date' })}
            {renderField({ request, id: 'timestamp.time', label: 'Request time' })}
          </div>
        )}
        actions={actions}
      />
      <div style={{
        display: 'flex', flexDirection: 'column', paddingTop: '20px', paddingLeft: '10px', alignItems: 'flex-start',
      }}
      >
        <Button
          style={{ width: 'auto', paddingLeft: '10px', paddingRight: '10px' }}
          onClick={() => dispatchInspectionImagesDownloadZipGet(inspection, INSPECTION_IMAGE_DOWNLOAD_ZIP_TYPES.selected)}
          primary
          simple
        >
          {`Download raw images selected for report  (${imagesSelectedForReport.length}/${onsiteImages.length})`}
        </Button>
        <Button
          style={{ width: 'auto', paddingLeft: '10px', paddingRight: '10px' }}
          onClick={() => dispatchInspectionImagesDownloadZipGet(inspection, INSPECTION_IMAGE_DOWNLOAD_ZIP_TYPES.all)}
          primary
          simple
        >
          {`Download all raw images (${get(inspection, 'onsite.productImages', []).length})`}
        </Button>
      </div>
    </BubbleCard>
  );
}

export default InspectionBubbleRequest;
