// csrfUtil.js

const createCsrf = () => {
  // Internal variable to store the CSRF token
  let csrf = {};

  // Function to set the CSRF token
  const set = ({ csrfToken }) => {
    csrf.token = csrfToken;
  };

  // Function to get the CSRF token
  const get = () => csrf;

  // Function to clear the CSRF token
  const clear = () => {
    csrf = {};
    return csrf.token;
  };

  return {
    set,
    get,
    clear,
  };
};

export default createCsrf;
