import {
  CARRIERS_GET_REQUEST_START,
  CARRIERS_GET_REQUEST_FAIL,
  CARRIERS_GET_REQUEST_SUCCESS,
} from '../actions';
import { createRequestStateUpdater } from '../utility/reduxUtils';
import { updateObject } from '../utility/utility';

const initialState = {};

export const CARRIERS_REQUESTS = {
  carriersGet: 'carriersGet',
};

export const carriersGetSuccess = (carriers) => {
  // 1. Create a new array where each object has had 'scac' removed
  const carriersWithoutScac = carriers.map((object) => {
    const { scac, ...rest } = object;
    return rest;
  });

  // 2. Remove duplicate objects with key-value "name"
  const carrierNames = new Set();
  const uniqueCarriers = carriersWithoutScac.filter((element) => {
    if (!carrierNames.has(element.name)) {
      carrierNames.add(element.name);
      return true;
    }
    return false;
  });

  return { carriers: uniqueCarriers };
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CARRIERS_GET_REQUEST_START:
      return createRequestStateUpdater(state, action, CARRIERS_REQUESTS.carriersGet);
    case CARRIERS_GET_REQUEST_SUCCESS:
      return updateObject(
        createRequestStateUpdater(state, action, CARRIERS_REQUESTS.carriersGet),
        carriersGetSuccess(action.data),
      );
    case CARRIERS_GET_REQUEST_FAIL:
      return createRequestStateUpdater(state, action, CARRIERS_REQUESTS.carriersGet);
    default:
      return state;
  }
};

export default reducer;
