import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import _ from 'lodash';
import { apiRequestState, get, set } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import InputField from '../../core/input/InputField';
import CoreInputCheckbox from '../../core/input/CoreInputCheckbox';

const OrganizationFormTeammate = forwardRef(({
  userPostRequestState,
  dispatchUserPost,
  dispatchUserPut,
  userPutRequestState,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentOrganization, setCurrentOrganization] = useState({});
  const [currentUser, setCurrentUser] = useState(null);
  const [inputState, setInputState] = useState({});
  const [error, setError] = useState({});
  useImperativeHandle(ref, () => ({
    open: (organization, user) => {
      setCurrentOrganization(organization);
      setCurrentUser(user);
      if (user) {
        setInputState(_.cloneDeep(user));
      } else {
        setInputState({});
      }
      setIsOpen(true);
      setError({});
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (userPutRequestState === apiRequestState.SUCCESS
      || userPostRequestState === apiRequestState.SUCCESS) {
      closeModal();
    }
  }, [userPutRequestState, userPostRequestState]);

  if (!isOpen) return null;

  const validateForm = (state) => {
    // Define the fields to validate
    const fieldsToValidate = [];

    // Initialize an object to hold error messages for each field
    const errors = {};

    // Iterate over the fields and validate
    fieldsToValidate.forEach((fieldId) => {
      if (!get(state, fieldId)) {
        errors[fieldId] = `${_.startCase(fieldId)} is required`;
      }
    });

    // Set the error state based on the validation results
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (event, path) => {
    const newState = { ...inputState };
    if (event.target.id === 'emailPrefix') {
      const emailPrefix = event.target.value;
      const emailSuffix = get(currentOrganization, 'emailSuffix');
      const emailTld = get(currentOrganization, 'emailTld', '.com');

      set(newState, path, event.target.value);
      set(newState, 'email', `${emailPrefix}@${emailSuffix}${emailTld}`.toLowerCase());
    } else {
      set(newState, path, event.target.checked);
    }
    setInputState(newState);
    validateForm(newState);
  };

  const handleSave = () => {
    if (validateForm(inputState)) {
      if (currentUser) {
        dispatchUserPut({ ...currentUser, ...inputState });
      } else {
        dispatchUserPost(_.pick(inputState, ['email', 'permissions']));
      }
    }
  };

  const renderInputField = (id, state, path = id) => (
    <InputField
      id={id}
      label={_.startCase(id)}
      placeholder={_.startCase(id)}
      value={get(state, path)}
      onChange={(event) => handleInputChange(event, path)}
    />
  );

  const renderCheckboxField = (id, state, path = id) => (
    <CoreInputCheckbox
      id={id}
      onChange={(event) => handleInputChange(event, path)}
      checked={get(state, path)}

    >
      {_.startCase(id)}
    </CoreInputCheckbox>
  );

  return (
    <CoreFormDualAction
      title="User"
      primaryButtonCaption="Save"
      primaryButtonCallback={() => handleSave()}
      primaryButtonCallbackStatus={userPutRequestState === apiRequestState.LOADING}
      primaryButtonDisabled={Object.keys(error).length > 0 || userPutRequestState === apiRequestState.LOADING}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <div>
        {get(error, 'type', false) && <div style={{ color: 'var(--color-warning)' }}>{error.type}</div>}
        {!currentUser && renderInputField('emailPrefix', inputState)}
        <p>
          <b>{get(inputState, 'email').toLowerCase()}</b>
        </p>
        <p style={{ fontWeight: '500' }}>Permissions</p>
        {renderCheckboxField('containers.view', inputState, 'permissions.containers.view')}
        {renderCheckboxField('containers.edit', inputState, 'permissions.containers.edit')}
        {renderCheckboxField('bookings.view', inputState, 'permissions.bookings.view')}
        {renderCheckboxField('bookings.edit', inputState, 'permissions.bookings.edit')}
        {renderCheckboxField('users.view', inputState, 'permissions.users.view')}
        {renderCheckboxField('users.edit', inputState, 'permissions.users.edit')}
      </div>
    </CoreFormDualAction>
  );
});

export default OrganizationFormTeammate;
