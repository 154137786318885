import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import { get } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import Select from '../../Select';
import { INSPECTION_STATES } from '../../../utility/InspectionsUtils';

const InspectionFormMark = forwardRef(({ dispatchInspectionsMark, inspectionsPutMarkIsLoading }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentInspections, setCurrentInspections] = useState([]);
  const [inputState, setInputState] = useState({});

  const [error, setError] = useState(null);

  useImperativeHandle(ref, () => ({
    open: (inspections) => {
      setInputState({});
      setCurrentInspections(inspections);
      setIsOpen(true);
      setError(null);
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!inspectionsPutMarkIsLoading) {
      closeModal();
    }
  }, [inspectionsPutMarkIsLoading]);

  const validateForm = (state) => {
    if (!state?.status) {
      setError('State is required.');
      return false;
    }
    setError(null);
    return true;
  };

  const handleInputChange = (event) => {
    const newState = { ...inputState };
    newState[event.target.id] = event.target.value;
    setInputState(newState);
    validateForm(newState);
  };

  const handlePrimaryAction = () => {
    if (validateForm(inputState)) {
      const requestBody = { ...inputState, inspectionIds: currentInspections.map((inspection) => inspection._id) };
      dispatchInspectionsMark(requestBody);
    }
  };

  const renderInspectionSummary = (inspection) => {
    const output = [];
    const applicantName = get(inspection, 'summary.applicantName', false);
    const purchaseOrder = get(inspection, 'summary.purchaseOrder', false);
    if (applicantName) {
      output.push(applicantName);
    }
    if (purchaseOrder) {
      output.push(`#${purchaseOrder}`);
    }
    return output.join(' ');
  };

  if (!isOpen) return null;

  return (
    <CoreFormDualAction
      title="Mark Inspection"
      primaryButtonCaption="Mark"
      primaryButtonCallback={handlePrimaryAction}
      primaryButtonCallbackStatus={inspectionsPutMarkIsLoading}
      primaryButtonDisabled={error || inspectionsPutMarkIsLoading}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <p>Selected inspections: </p>
      {currentInspections.map((inspection) => (
        <div key={get(inspection, '_id')}>
          <strong>
            <p style={{ paddingLeft: '10px', paddingTop: '10px', margin: '0px' }}>
              {get(inspection, 'summary.commodity')}
            </p>
          </strong>
          <p style={{ paddingLeft: '10px', paddingTop: '0px', margin: '0px' }}>
            {renderInspectionSummary(inspection)}
          </p>
          <p style={{ paddingLeft: '10px', paddingTop: '0px', margin: '0px' }}>
            {`${get(inspection, 'request.certificateId')}`}
          </p>
        </div>

      ))}
      <Select
        id="status"
        options={[''].concat(Object.keys(INSPECTION_STATES).map((status) => (status)))}
        value={get(inputState, 'status')}
        onChange={handleInputChange}
      >
        Status
      </Select>
      {error && <div style={{ color: 'var(--color-warning)' }}>{error}</div>}
    </CoreFormDualAction>
  );
});

export default InspectionFormMark;
