import { openBlobDocument } from '../utility/documentViewerUtils';
import { createRequestAction } from '../utility/reduxUtils';

const ENDPOINT_PATH = 'bookings';
export const BOOKINGS_GET = 'BOOKINGS_GET';
export const BOOKINGS_GET_SHARED = 'BOOKINGS_GET_SHARED';
export const BOOKINGS_GET_DOCUMENT = 'BOOKINGS_GET_DOCUMENT';
export const BOOKINGS_POST = 'BOOKINGS_POST';
export const BOOKINGS_PUT = 'BOOKINGS_PUT';
export const BOOKINGS_PUT_DEACTIVATE = 'BOOKINGS_PUT_DEACTIVATE';
export const BOOKINGS_PUT_SHARE = 'BOOKINGS_PUT_SHARE';
export const BOOKINGS_DELETE = 'BOOKINGS_DELETE';
export const BOOKINGS_POST_INSPECTION = 'BOOKINGS_POST_INSPECTION';
export const BOOKINGS_PATCH_DOCUMENT = 'BOOKINGS_PATCH_DOCUMENT';
export const BOOKINGS_DELETE_DOCUMENT = 'BOOKINGS_DELETE_DOCUMENT';
export const BOOKINGS_PATCH_REFRESH = 'BOOKINGS_PATCH_REFRESH';

export const bookingsGet = () => createRequestAction({
  requestType: BOOKINGS_GET,
  endpoint: 'bookings/',
  method: 'httpGet',
  failureMessage: 'Failed to retrieve bookings',
});

export const bookingsGetShared = () => createRequestAction({
  requestType: BOOKINGS_GET_SHARED,
  endpoint: 'bookings/shared',
  method: 'httpGet',
  failureMessage: 'Failed to retrieve shared bookings',
});

export const bookingsGetDocument = (booking, documentType) => createRequestAction({
  requestType: BOOKINGS_GET_DOCUMENT,
  endpoint: `bookings/${booking._id}/${documentType}`,
  method: 'httpGet',
  responseType: 'blob',
  failureMessage: `Failed to retrieve ${documentType}`,
  onSuccess: (data) => openBlobDocument(data),
});

export const bookingsPost = (booking) => createRequestAction({
  requestType: BOOKINGS_POST,
  endpoint: 'bookings',
  method: 'httpPost',
  payload: booking,
  successMessage: 'Successfully created booking',
  failureMessage: 'Failed to create booking',
});

export const bookingsDelete = (bookings) => createRequestAction({
  requestType: BOOKINGS_DELETE,
  endpoint: 'bookings',
  method: 'httpDelete',
  payload: bookings,
  successMessage: 'Successfully deleted booking',
  failureMessage: 'Failed to delete booking',
});

export const bookingsPut = (booking) => createRequestAction({
  requestType: BOOKINGS_PUT,
  endpoint: `bookings/${booking._id}`,
  method: 'httpPut',
  payload: booking,
  successMessage: 'Successfully updated booking',
  failureMessage: 'Failed to update booking',
});

export const bookingsPutDeactivate = (bookings) => createRequestAction({
  requestType: BOOKINGS_PUT_DEACTIVATE,
  endpoint: 'bookings/deactivate',
  method: 'httpPut',
  payload: bookings,
  successMessage: 'Successfully deactivated booking',
  failureMessage: 'Failed to deactivate booking',
});

export const bookingsPutShare = (bookings, share) => createRequestAction({
  requestType: BOOKINGS_PUT_SHARE,
  endpoint: `${ENDPOINT_PATH}/share`,
  method: 'httpPut',
  payload: { bookingIds: bookings, share },
  successMessage: 'Successfully shared booking',
  failureMessage: 'Failed to share booking',
});

export const bookingsPostInspection = (bookings) => createRequestAction({
  requestType: BOOKINGS_POST_INSPECTION,
  endpoint: `${ENDPOINT_PATH}/requestInspection`,
  method: 'httpPost',
  payload: bookings,
  successMessage: 'Successfully requested inspection',
  failureMessage: 'Failed to request inspection',
});

export const bookingsDocumentPatch = (booking, documentType, document) => {
  const formData = new FormData();
  formData.append('document', document);

  return createRequestAction({
    requestType: BOOKINGS_PATCH_DOCUMENT,
    endpoint: `${ENDPOINT_PATH}/${booking._id}/${documentType}`,
    method: 'httpPatch',
    payload: formData,
    successMessage: `Successfully uploaded ${documentType}`,
    failureMessage: `Failed to upload ${documentType}`,
  });
};

export const bookingsDocumentDelete = (booking, documentType) => createRequestAction({
  requestType: BOOKINGS_PATCH_DOCUMENT,
  endpoint: `${ENDPOINT_PATH}/${booking._id}/${documentType}`,
  method: 'httpDelete',
  successMessage: `Successfully deleted ${documentType}`,
  failureMessage: `Failed to delete ${documentType}`,
});

export const bookingsRefreshPatch = (booking) => createRequestAction({
  requestType: BOOKINGS_PATCH_REFRESH,
  endpoint: `${ENDPOINT_PATH}/refresh/${booking._id}`,
  method: 'httpPatch',
  successMessage: 'Successfully refreshed booking',
  failureMessage: 'Failed to refresh booking',
});
