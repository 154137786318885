import { IMAGE_SYNC_TIME_INTERVAL_MS } from '../config';

const SERVICE_WORKER_COMMAND_TYPES = {
  NEW_API_CONFIG: 'NEW_API_CONFIG',
  SYNC_EVENT: 'SYNC_EVENT',
};

const SERVICE_WORKER_RESPONSE_TYPES = {
  SW_ACTIVATED: 'SW_ACTIVATED',
  SW_CONFIGURED: 'SW_CONFIGURED',
  BATCH_DELETE_SUCCESS: 'BATCH_DELETE_SUCCESS',
  BATCH_DELETE_FAILED: 'BATCH_DELETE_FAILED',
  NO_IMAGES_TO_SYNC: 'NO_IMAGES_TO_SYNC',
};

let configured = null;
let dispatchGetImagesLeftToSyncCount = null;

const sendConfig = (config) => {
  navigator.serviceWorker.controller.postMessage({
    type: SERVICE_WORKER_COMMAND_TYPES.NEW_API_CONFIG,
    baseUrl: config.baseUrl,
    csrfToken: config.csrfToken,
    batchSize: config.batchSize,
  });
};

const setDispatchFunction = (dispatchFunction) => {
  dispatchGetImagesLeftToSyncCount = dispatchFunction;
};

function serviceWorkerCheckSupport() {
  return ('serviceWorker' in navigator);
}

async function serviceWorkerCheckRegistration() {
  const registration = await navigator.serviceWorker.getRegistration();
  if (registration) {
    // console.log('Service Worker is already registered.');
    return registration;
  }
  // console.log('No Service Worker is registered for this scope.');
  return null;
}

async function serviceWorkerRegister({ filePath = '/', fileName }) {
  const registration = await navigator.serviceWorker.register(`${filePath}${fileName}`, { type: 'module' });
  // console.log('Service Worker registered with scope:', registration.scope);
  return registration;
}
async function registerServiceWorker(serviceWorkerConfig) {
  if (!('serviceWorker' in navigator)) {
    // console.error('Service Workers are not supported in this browser.');
    return;
  }

  const { filePath, fileName, syncEventTag } = serviceWorkerConfig.sync;
  const { baseUrl, csrfToken, batchSize } = serviceWorkerConfig.api;
  dispatchGetImagesLeftToSyncCount = serviceWorkerConfig.callbacks.dispatchGetImagesLeftToSyncCount;

  if (!baseUrl || !csrfToken || !batchSize) {
    // throw new Error('Invalid service worker API configuration');
  }

  try {
    let registration = await navigator.serviceWorker.getRegistration();
    if (!registration) {
      registration = await navigator.serviceWorker.register(`${filePath}${fileName}`, { type: 'module' });
      // console.log('Service Worker registered with scope:', registration.scope);
    }

    // eslint-disable-next-line
    self.addEventListener('activate', (event) => {
      event.waitUntil(
        // eslint-disable-next-line
        self.clients.claim(), // Immediately take control of the page
      );
    });

    // Ensure the service worker is ready and controlling the page
    await navigator.serviceWorker.ready;
    if (!navigator.serviceWorker.controller) {
      // console.error('Service Worker controller is not set');
      return;
    }

    // Register for background sync
    await registration.sync.register(syncEventTag);
    // console.log('Sync registered successfully');

    // Send initial configuration to the service worker
    sendConfig(serviceWorkerConfig.api);
    // console.log('Configuration message posted to Service Worker.');
  } catch (error) {
    // console.error('Service Worker registration failed:', error);
  }
}

function triggerConfig(serviceWorkerConfig) {
  if (navigator.serviceWorker.controller) {
    // console.log('Config triggered');
    sendConfig(serviceWorkerConfig.api);
    // Setup message listener
    navigator.serviceWorker.addEventListener('message', (event) => {
      // console.log('Response from the Service Worker:', event.data);
      switch (event.data.type) {
        case SERVICE_WORKER_RESPONSE_TYPES.SW_ACTIVATED:
          break;
        case SERVICE_WORKER_RESPONSE_TYPES.BATCH_DELETE_SUCCESS:
          if (dispatchGetImagesLeftToSyncCount) {
            dispatchGetImagesLeftToSyncCount(event.data.uploadedImages);
          }
          break;
        case SERVICE_WORKER_RESPONSE_TYPES.BATCH_DELETE_FAILED:
          break;
        case SERVICE_WORKER_RESPONSE_TYPES.NO_IMAGES_TO_SYNC:
          break;
        default:
      }
    });

    configured = 1;
  } else {
    // console.log('Service Worker controller not available to trigger config.');
  }
}

function triggerSync() {
  if (navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({ type: SERVICE_WORKER_COMMAND_TYPES.SYNC_EVENT });
  } else {
    // eslint-disable-next-line
    console.warn('Service Worker controller not available to trigger sync.');
  }
}

async function initServiceWorker(serviceWorkerConfig) {
  if (!serviceWorkerCheckSupport()) {
    return;
  }

  const intervalId = setInterval(() => {
    if (navigator.serviceWorker.controller) {
      if (!configured) {
        triggerConfig(serviceWorkerConfig);
      } else {
        triggerSync();
      }
    } else {
      registerServiceWorker(serviceWorkerConfig);
    }
  }, IMAGE_SYNC_TIME_INTERVAL_MS);

  // eslint-disable-next-line
  return () => clearInterval(intervalId);
}

export {
  setDispatchFunction,
  serviceWorkerCheckSupport,
  serviceWorkerCheckRegistration,
  serviceWorkerRegister,
  registerServiceWorker,
  initServiceWorker,
};
