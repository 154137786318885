// Development settings
const NODE_ENV = process.env.REACT_APP_NODE_ENV;
const IS_DEVELOPMENT = NODE_ENV === 'development';

const API_PROTOCOL = process.env.REACT_APP_API_PROTOCOL;
const API_HOST = process.env.REACT_APP_API_HOST;
const API_PORT = process.env.REACT_APP_API_PORT;
const API_PATH = process.env.REACT_APP_API_PATH;

// Determine API Base URL
const API_BASE_URL_DEFAULT = process.env.REACT_APP_API_BASE_URL;
// eslint-disable-next-line
const API_BASE_URL = IS_DEVELOPMENT
  ? `${API_PROTOCOL}://${API_HOST}:${API_PORT}${API_PATH}`
  : API_BASE_URL_DEFAULT;

// Third-party services
const BUGSNAG_KEY = process.env.REACT_APP_BUGSNAG_KEY;
const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const STRIPE_SUBSCRIPTION_PLAN = process.env.REACT_APP_STRIPE_SUBSCRIPTION_PLAN;
const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
const REDUX_DEV_TOOLS_ARE_ENABLED = process.env.REACT_APP_REDUX_DEV_TOOLS_ARE_ENABLED === 'true';

// Inspection image storage
const DEFAULT_IMAGE_SYNC_BATCH_SIZE = 1;
const DEFAULT_IMAGE_SYNC_BATCH_TIME_INTERVAL_MS = 100;

const IMAGE_SYNC_BATCH_SIZE = parseInt(process.env.REACT_APP_IMAGE_SYNC_BATCH_SIZE, 10) || DEFAULT_IMAGE_SYNC_BATCH_SIZE;
const IMAGE_SYNC_TIME_INTERVAL_MS = parseInt(process.env.REACT_APP_IMAGE_SYNC_TIME_INTERVAL_MS, 10) || DEFAULT_IMAGE_SYNC_BATCH_TIME_INTERVAL_MS;
const USE_STORAGE_INDEXED_DB = process.env.REACT_APP_USE_STORAGE_INDEXED_DB === 'true';
const INSPECTIONS_PENDING_REPORTS_FETCH_INTERVAL_MS = parseInt(process.env.REACT_APP_INSPECTIONS_PENDING_REPORTS_FETCH_INTERVAL_MS, 10) || 5000;

const QUICKBOOKS_CLIENT_ID = process.env.REACT_APP_QUICKBOOKS_CLIENT_ID;
const QUICKBOOKS_REDIRECT_URI = process.env.REACT_APP_QUICKBOOKS_REDIRECT_URI;

export {
  API_BASE_URL,
  USE_STORAGE_INDEXED_DB,
  IMAGE_SYNC_BATCH_SIZE,
  IMAGE_SYNC_TIME_INTERVAL_MS,
  BUGSNAG_KEY,
  STRIPE_PUBLIC_KEY,
  STRIPE_SUBSCRIPTION_PLAN,
  MAPBOX_TOKEN,
  REDUX_DEV_TOOLS_ARE_ENABLED,
  INSPECTIONS_PENDING_REPORTS_FETCH_INTERVAL_MS,
  QUICKBOOKS_CLIENT_ID,
  QUICKBOOKS_REDIRECT_URI,
};
