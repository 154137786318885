import _, { cloneDeep } from 'lodash';
import {
  INSPECTIONS_CREATE_INVOICE_POST_REQUEST_START,
  INSPECTIONS_CREATE_INVOICE_POST_REQUEST_FAIL,
  INSPECTIONS_CREATE_INVOICE_POST_REQUEST_SUCCESS,
  INSPECTIONS_CREATE_INVOICE_POST_REQUEST_IDLE,
  INSPECTIONS_FETCH_INVOICE_PDF_GET_REQUEST_START,
  INSPECTIONS_FETCH_INVOICE_PDF_GET_REQUEST_FAIL,
  INSPECTIONS_FETCH_INVOICE_PDF_GET_REQUEST_SUCCESS,
  INSPECTIONS_IMAGES_PATCH_REQUEST_START,
  INSPECTIONS_IMAGES_PATCH_REQUEST_FAIL,
  INSPECTIONS_IMAGES_PATCH_REQUEST_SUCCESS,
  INSPECTIONS_IMAGES_PATCH_REQUEST_IDLE,
  INSPECTIONS_IMAGE_DELETE_REQUEST_START,
  INSPECTIONS_IMAGE_DELETE_REQUEST_FAIL,
  INSPECTIONS_IMAGE_DELETE_REQUEST_SUCCESS,
  INSPECTIONS_IMAGE_DELETE_REQUEST_IDLE,
  INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_START,
  INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_FAIL,
  INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_SUCCESS,
  INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_IDLE,
  INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_START,
  INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_FAIL,
  INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_SUCCESS,
  INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_IDLE,
  INSPECTIONS_IMAGES_LEFT_TO_SYNC_COUNT_START,
  INSPECTIONS_IMAGES_LEFT_TO_SYNC_COUNT_SUCCESS,

  INSPECTIONS_GET,
  INSPECTIONS_GET_SINGLE,
  INSPECTIONS_GET_AVAILABLE_STAFF,
  INSPECTIONS_POST,
  INSPECTIONS_PUT,
  INSPECTIONS_PUT_ASSIGN,
  INSPECTIONS_PUT_MARK,
  INSPECTIONS_PUT_SUBMIT_REPORT,
  INSPECTIONS_DELETE,
  INSPECTIONS_GET_DOCUMENT,
  INSPECTIONS_POST_GENERERATE_REPORT,
  INSPECTIONS_POST_REPORTS_DELETE,
  INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET,
} from '../actions';
import { apiRequestState, updateObject } from '../utility/utility';
import { handleRequestState, REQUEST_STATE, updateElements } from '../utility/reduxUtils';

export const INSPECTIONS_REQUESTS = {
  inspectionsGet: 'inspectionsGet',
  inspectionsGetSingle: 'inspectionsGetSingle',
  inspectionsPost: 'inspectionsPost',
  inspectionsDelete: 'inspectionsDelete',
  inspectionsPut: 'inspectionsPut',
  inspectionsGetAvailableStaff: 'inspectionsGetAvailableStaff',
  inspectionsGetDocument: 'inspectionsGetDocument',
  inspectionsPutAssign: 'inspectionsPutAssign',
  inspectionsPutMark: 'inspectionsPutMark',
  inspectionsPostGenerateReport: 'inspectionsPostGenerateReport',
  inspectionsPostReportsDelete: 'inspectionsPostReportsDelete',
  inspectionsPutSubmitReport: 'inspectionsPutSubmitReport',
  inspectionsGetImagesZip: 'inspectionsGetImagesZip',
};

const initialState = {
  inspectionsImagesLeftToSync: null,
  availableStaff: [],
  inspectionsPutUnassignSelfRequestState: apiRequestState.IDLE,
  inspectionsCreateInvoicePostRequestState: apiRequestState.IDLE,
  inspectionsDeleteInvoicePostRequestState: apiRequestState.IDLE,
  inspectionsFetchInvoicePdfGetRequestState: apiRequestState.IDLE,
  inspectionsImagesPatchRequestState: apiRequestState.IDLE,
  inspectionsImagesPatchProgress: null,
  inspectionsImageDeleteRequestState: apiRequestState.IDLE,
  inspectionsImagesDownloadZipGetRequestState: apiRequestState.IDLE,
  inspectionsImagesAssociateRequestState: apiRequestState.IDLE,
  inspectionsImagesLeftToSyncCountState: apiRequestState.IDLE,
  inspectionsReportImagesSelectRequestState: apiRequestState.IDLE,
};

const inspectionsFetchInvoicePdfGetStart = (state, action) => updateObject(state, {
  inspectionsFetchInvoicePdfGetRequestState: action.requestState,
});

const inspectionsFetchInvoicePdfGetFail = (state, action) => updateObject(state, {
  inspectionsFetchInvoicePdfGetRequestState: action.requestState,
});

const inspectionsFetchInvoicePdfGetSuccess = (state, action) => updateObject(state, {
  inspectionsFetchInvoicePdfGetRequestState: action.requestState,
});

const inspectionsCreateInvoicePostStart = (state, action) => updateObject(state, {
  inspectionsCreateInvoicePostRequestState: action.requestState,
});

const inspectionsCreateInvoicePostFail = (state, action) => updateObject(state, {
  inspectionsCreateInvoicePostRequestState: action.requestState,
});

const inspectionsCreateInvoicePostIdle = (state, action) => updateObject(state, {
  inspectionsCreateInvoicePostRequestState: action.requestState,
});

const inspectionsCreateInvoicePostSuccess = (state, action) => {
  const newInspections = [...state.inspections];
  // Find index of inspection and update it.
  const index = newInspections.findIndex((p) => p._id === action.inspection._id);
  newInspections[index] = _.cloneDeep(action.inspection);
  return updateObject(state, {
    inspectionsCreateInvoicePostRequestState: action.requestState,
    inspections: newInspections,
  });
};

// const inspectionsDeleteInvoicePostStart = (state, action) => updateObject(state, {
//   inspectionsDeleteInvoicePostRequestState: action.requestState,
// });

// const inspectionsDeleteInvoicePostFail = (state, action) => updateObject(state, {
//   inspectionsDeleteInvoicePostRequestState: action.requestState,
// });

// const inspectionsDeleteInvoicePostIdle = (state, action) => updateObject(state, {
//   inspectionsDeleteInvoicePostRequestState: action.requestState,
// });

// const inspectionsDeleteInvoicePostSuccess = (state, action) => {
//   const newInspections = [...state.inspections];
//   // Find index of inspection and update it.
//   const index = newInspections.findIndex((p) => p._id === action.inspection._id);
//   newInspections[index] = _.cloneDeep(action.inspection);
//   return updateObject(state, {
//     inspectionsDeleteInvoicePostRequestState: action.requestState,
//     inspections: newInspections,
//   });
// };

const inspectionsImagesPatchStart = (state, action) => updateObject(state, {
  inspectionsImagesPatchRequestState: action.requestState,
  inspectionsImagesPatchProgress: 0,
});

const inspectionsImagesPatchFail = (state, action) => updateObject(state, {
  inspectionsImagesPatchRequestState: action.requestState,
  inspectionsImagesPatchProgress: null,
});

const inspectionsImagesPatchIdle = (state, action) => updateObject(state, {
  inspectionsImagesPatchRequestState: action.requestState,
  inspectionsImagesPatchProgress: null,
});

const inspectionsImagesPatchSuccess = (state, action) => {
  const newInspections = [...state.inspections];
  // Find index of inspection and update it.
  const index = newInspections.findIndex((p) => p._id === action.inspection._id);
  newInspections[index] = _.cloneDeep(action.inspection);
  return updateObject(state, {
    inspectionsImagesLeftToSync: action.leftToSync,
  });
};

const inspectionsImageDeleteStart = (state, action) => updateObject(state, {
  inspectionsImageDeleteRequestState: action.requestState,
});

const inspectionsImageDeleteFail = (state, action) => updateObject(state, {
  inspectionsImageDeleteRequestState: action.requestState,
});

const inspectionsImageDeleteIdle = (state, action) => updateObject(state, {
  inspectionsImageDeleteRequestState: action.requestState,
});

const inspectionsImageDeleteSuccess = (state, action) => {
  const newInspections = [...state.inspections];
  // Find index of inspection and update it.
  const index = newInspections.findIndex((p) => p._id === action.inspection._id);
  newInspections[index] = _.cloneDeep(action.inspection);
  return updateObject(state, {
    inspectionsImageDeleteRequestState: action.requestState,
    inspections: newInspections,
  });
};

const inspectionsImagesAssociateStart = (state, action) => updateObject(state, {
  inspectionsImagesAssociateRequestState: action.requestState,
});

const inspectionsImagesAssociateFail = (state, action) => updateObject(state, {
  inspectionsImagesAssociateRequestState: action.requestState,
});

const inspectionsImagesAssociateIdle = (state, action) => updateObject(state, {
  inspectionsImagesAssociateRequestState: action.requestState,
});

const inspectionsImagesAssociateSuccess = (state, action) => {
  const newInspections = [...state.inspections];
  // Find index of inspection and update it.
  const index = newInspections.findIndex((p) => p._id === action.inspection._id);
  newInspections[index] = _.cloneDeep(action.inspection);
  return updateObject(state, {
    inspectionsImagesAssociateRequestState: action.requestState,
    inspections: newInspections,
  });
};

const inspectionsImagesSelectStart = (state, action) => updateObject(state, {
  inspectionsReportImagesSelectRequestState: action.requestState,
});

const inspectionsImagesSelectFail = (state, action) => updateObject(state, {
  inspectionsReportImagesSelectRequestState: action.requestState,
});

const inspectionsImagesSelectIdle = (state, action) => updateObject(state, {
  inspectionsReportImagesSelectRequestState: action.requestState,
});

const inspectionsImagesSelectSuccess = (state, action) => {
  const newInspections = [...state.inspections];
  // Find index of inspection and update it.
  const index = newInspections.findIndex((p) => p._id === action.inspection._id);
  newInspections[index] = _.cloneDeep(action.inspection);
  return updateObject(state, {
    inspectionsReportImagesSelectRequestState: action.requestState,
    inspections: newInspections,
  });
};

const inspectionsImagesDownloadZipGetStart = (state, action) => updateObject(state, {
  inspectionsImagesDownloadZipGetRequestState: action.requestState,
});

const inspectionsImagesDownloadZipGetFail = (state, action) => updateObject(state, {
  inspectionsImagesDownloadZipGetRequestState: action.requestState,
});

const inspectionsImagesDownloadZipGetSuccess = (state, action) => updateObject(state, {
  inspectionsImagesDownloadZipGetRequestState: action.requestState,
});

const inspectionsImagesDownloadZipGetIdle = (state, action) => updateObject(state, {
  inspectionsImagesDownloadZipGetRequestState: action.requestState,
});

const inspectionsImagesLeftToSyncCountStart = (state, action) => updateObject(state, {
  inspectionsImagesLeftToSyncCountState: action.requestState,
});

const inspectionsImagesLeftToSyncCountSuccess = (state, action) => {
  const { uploadedImages = [], imagesLeftToSync, requestState } = action;

  // Clone inspections state to ensure immutability
  const newInspections = cloneDeep(state.inspections);

  uploadedImages.forEach((image) => {
    const inspectionIndex = newInspections.findIndex((p) => p._id === image.inspectionId);

    if (inspectionIndex !== -1) {
      const inspection = newInspections[inspectionIndex];
      inspection.onsite = inspection.onsite || {};
      inspection.onsite.productImages = inspection.onsite.productImages || [];
      inspection.onsite.productImages.push(image);

      // Sort images based on the captured date after adding new images
      inspection.onsite.productImages.sort(
        (a, b) => new Date(a.metadata.capturedAt) - new Date(b.metadata.capturedAt),
      );
    } else {
      // eslint-disable-next-line
      console.warn(`Inspection ID ${image.inspectionId} not found in state.`);
    }
  });

  // Update state object with new inspections, remaining images to sync, and request state
  return updateObject(state, {
    inspectionsImagesLeftToSyncCountState: requestState,
    inspectionsImagesLeftToSync: imagesLeftToSync,
    inspections: newInspections,
  });
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${INSPECTIONS_GET}_${REQUEST_STATE.START}`:
    case `${INSPECTIONS_GET}_${REQUEST_STATE.SUCCESS}`:
    case `${INSPECTIONS_GET}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state, action, requestKey: INSPECTIONS_REQUESTS.inspectionsGet, dataKey: 'inspections',
      });

    case `${INSPECTIONS_GET_SINGLE}_${REQUEST_STATE.START}`:
    case `${INSPECTIONS_GET_SINGLE}_${REQUEST_STATE.SUCCESS}`:
    case `${INSPECTIONS_GET_SINGLE}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state,
        action,
        requestKey: INSPECTIONS_REQUESTS.inspectionsGetSingle,
        dataKey: 'inspections',
        updateFunction: () => updateElements({ current: state.inspections, edited: action.data }),
      });

    case `${INSPECTIONS_POST}_${REQUEST_STATE.START}`:
    case `${INSPECTIONS_POST}_${REQUEST_STATE.SUCCESS}`:
    case `${INSPECTIONS_POST}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state,
        action,
        requestKey: INSPECTIONS_REQUESTS.inspectionsPost,
        dataKey: 'inspections',
        updateFunction: () => updateElements({ current: state.inspections, created: action.data }),
      });

    case `${INSPECTIONS_DELETE}_${REQUEST_STATE.START}`:
    case `${INSPECTIONS_DELETE}_${REQUEST_STATE.SUCCESS}`:
    case `${INSPECTIONS_DELETE}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state,
        action,
        requestKey: INSPECTIONS_REQUESTS.inspectionsDelete,
        dataKey: 'inspections',
        updateFunction: () => updateElements({ current: state.inspections, deleted: action.data }),
      });

    case `${INSPECTIONS_PUT}_${REQUEST_STATE.START}`:
    case `${INSPECTIONS_PUT}_${REQUEST_STATE.SUCCESS}`:
    case `${INSPECTIONS_PUT}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state,
        action,
        requestKey: INSPECTIONS_REQUESTS.inspectionsPut,
        dataKey: 'inspections',
        updateFunction: () => updateElements({ current: state.inspections, edited: action.data }),
      });

    case `${INSPECTIONS_GET_AVAILABLE_STAFF}_${REQUEST_STATE.START}`:
    case `${INSPECTIONS_GET_AVAILABLE_STAFF}_${REQUEST_STATE.SUCCESS}`:
    case `${INSPECTIONS_GET_AVAILABLE_STAFF}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state,
        action,
        requestKey: INSPECTIONS_REQUESTS.inspectionsGetSingle,
        dataKey: 'availableStaff',
      });

    case `${INSPECTIONS_PUT_ASSIGN}_${REQUEST_STATE.START}`:
    case `${INSPECTIONS_PUT_ASSIGN}_${REQUEST_STATE.SUCCESS}`:
    case `${INSPECTIONS_PUT_ASSIGN}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state,
        action,
        requestKey: INSPECTIONS_REQUESTS.inspectionsPutAssign,
        dataKey: 'inspections',
        updateFunction: () => updateElements({ current: state.inspections, edited: action.data }),
      });

    case `${INSPECTIONS_PUT_MARK}_${REQUEST_STATE.START}`:
    case `${INSPECTIONS_PUT_MARK}_${REQUEST_STATE.SUCCESS}`:
    case `${INSPECTIONS_PUT_MARK}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state,
        action,
        requestKey: INSPECTIONS_REQUESTS.inspectionsPutMark,
        dataKey: 'inspections',
        updateFunction: () => updateElements({ current: state.inspections, edited: action.data }),
      });

    case `${INSPECTIONS_POST_GENERERATE_REPORT}_${REQUEST_STATE.START}`:
    case `${INSPECTIONS_POST_GENERERATE_REPORT}_${REQUEST_STATE.SUCCESS}`:
    case `${INSPECTIONS_POST_GENERERATE_REPORT}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state,
        action,
        requestKey: INSPECTIONS_REQUESTS.inspectionsPostGenerateReport,
        dataKey: 'inspections',
        updateFunction: () => updateElements({ current: state.inspections, edited: action.data }),
      });

    case `${INSPECTIONS_GET_DOCUMENT}_${REQUEST_STATE.START}`:
    case `${INSPECTIONS_GET_DOCUMENT}_${REQUEST_STATE.SUCCESS}`:
    case `${INSPECTIONS_GET_DOCUMENT}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state,
        action,
        requestKey: INSPECTIONS_REQUESTS.inspectionsGetDocument,
      });

    case `${INSPECTIONS_POST_REPORTS_DELETE}_${REQUEST_STATE.START}`:
    case `${INSPECTIONS_POST_REPORTS_DELETE}_${REQUEST_STATE.SUCCESS}`:
    case `${INSPECTIONS_POST_REPORTS_DELETE}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state,
        action,
        requestKey: INSPECTIONS_REQUESTS.inspectionsPostReportsDelete,
        dataKey: 'inspections',
        updateFunction: () => updateElements({ current: state.inspections, edited: action.data }),
      });

    case `${INSPECTIONS_PUT_SUBMIT_REPORT}_${REQUEST_STATE.START}`:
    case `${INSPECTIONS_PUT_SUBMIT_REPORT}_${REQUEST_STATE.SUCCESS}`:
    case `${INSPECTIONS_PUT_SUBMIT_REPORT}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state,
        action,
        requestKey: INSPECTIONS_REQUESTS.inspectionsPutSubmitReport,
        dataKey: 'inspections',
        updateFunction: () => updateElements({ current: state.inspections, edited: action.data }),
      });

    case `${INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET}_${REQUEST_STATE.START}`:
    case `${INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET}_${REQUEST_STATE.SUCCESS}`:
    case `${INSPECTIONS_IMAGES_DOWNLOAD_ZIP_GET}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state,
        action,
        requestKey: INSPECTIONS_REQUESTS.inspectionsGetImagesZip,
      });

    case INSPECTIONS_FETCH_INVOICE_PDF_GET_REQUEST_START:
      return inspectionsFetchInvoicePdfGetStart(state, action);
    case INSPECTIONS_FETCH_INVOICE_PDF_GET_REQUEST_FAIL:
      return inspectionsFetchInvoicePdfGetFail(state, action);
    case INSPECTIONS_FETCH_INVOICE_PDF_GET_REQUEST_SUCCESS:
      return inspectionsFetchInvoicePdfGetSuccess(state, action);
    case INSPECTIONS_CREATE_INVOICE_POST_REQUEST_START:
      return inspectionsCreateInvoicePostStart(state, action);
    case INSPECTIONS_CREATE_INVOICE_POST_REQUEST_FAIL:
      return inspectionsCreateInvoicePostFail(state, action);
    case INSPECTIONS_CREATE_INVOICE_POST_REQUEST_SUCCESS:
      return inspectionsCreateInvoicePostSuccess(state, action);
    case INSPECTIONS_CREATE_INVOICE_POST_REQUEST_IDLE:
      return inspectionsCreateInvoicePostIdle(state, action);
    case INSPECTIONS_IMAGES_PATCH_REQUEST_START:
      return inspectionsImagesPatchStart(state, action);
    case INSPECTIONS_IMAGES_PATCH_REQUEST_FAIL:
      return inspectionsImagesPatchFail(state, action);
    case INSPECTIONS_IMAGES_PATCH_REQUEST_SUCCESS:
      return inspectionsImagesPatchSuccess(state, action);
    case INSPECTIONS_IMAGES_PATCH_REQUEST_IDLE:
      return inspectionsImagesPatchIdle(state, action);
    case INSPECTIONS_IMAGE_DELETE_REQUEST_START:
      return inspectionsImageDeleteStart(state, action);
    case INSPECTIONS_IMAGE_DELETE_REQUEST_FAIL:
      return inspectionsImageDeleteFail(state, action);
    case INSPECTIONS_IMAGE_DELETE_REQUEST_SUCCESS:
      return inspectionsImageDeleteSuccess(state, action);
    case INSPECTIONS_IMAGE_DELETE_REQUEST_IDLE:
      return inspectionsImageDeleteIdle(state, action);
    case INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_START:
      return inspectionsImagesAssociateStart(state, action);
    case INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_FAIL:
      return inspectionsImagesAssociateFail(state, action);
    case INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_SUCCESS:
      return inspectionsImagesAssociateSuccess(state, action);
    case INSPECTIONS_IMAGES_ASSOCIATE_PUT_REQUEST_IDLE:
      return inspectionsImagesAssociateIdle(state, action);
    case INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_START:
      return inspectionsImagesSelectStart(state, action);
    case INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_FAIL:
      return inspectionsImagesSelectFail(state, action);
    case INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_SUCCESS:
      return inspectionsImagesSelectSuccess(state, action);
    case INSPECTIONS_IMAGES_SELECT_PUT_REQUEST_IDLE:
      return inspectionsImagesSelectIdle(state, action);
    case INSPECTIONS_IMAGES_LEFT_TO_SYNC_COUNT_START:
      return inspectionsImagesLeftToSyncCountStart(state, action);
    case INSPECTIONS_IMAGES_LEFT_TO_SYNC_COUNT_SUCCESS:
      return inspectionsImagesLeftToSyncCountSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
