import React from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom'; // Import useSearchParams

import './CodeVerification.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Typography from '../components/Typography';
import Button from '../components/core/input/Button';
import InputField from '../components/core/input/InputField';
import { get } from '../utility/utility';
import { verify } from '../actions';

function CodeVerification({
  email: reduxEmail,
  dispatchVerify,
}) {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email') || reduxEmail;

  const formik = useFormik({
    initialValues: {
      code: '',
      email,
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .required('Code is required')
        .min(6, 'Code must be at least 6 characters'),
    }),
    initialErrors: {
      code: 'Code is required',
    },

    onSubmit: (values) => {
      console.log(dispatchVerify,values);
      
      dispatchVerify({ ...values, email });
    },
  });

  return (
    <div className="code-verification-container">
      <div className="code-verification-title">
        <Typography text="Verify" />
      </div>
      <div className="code-verification-subtitle">
        {email}
      </div>

      <form className="code-verification-form" onSubmit={formik.handleSubmit}>
        <div className="code-verification-password">
          <InputField
            id="code"
            label="Enter code"
            type="text"
            maxLength="6"
            placeholder="Enter code"
            isRequired
            value={formik.values.code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="code-verification-validation-error-text">
            {formik.touched.code && formik.errors.code ? (
              <div>{formik.errors.code}</div>
            ) : null}
          </div>
        </div>

        <div className="code-verification-buttons-container">
          <div className="code-verification-button-submit">
            <Button
              id="buttonSubmit"
              type="submit"
              // isLoading={resetPasswordRequestState === apiRequestState.LOADING}
              primary
              disabled={!formik.isValid}
            >
              Verify code and sign in
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  email: get(state, 'auth.signInPost.email'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchVerify: (code) => dispatch(verify(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CodeVerification);
