import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { BrowserRouter as Router } from 'react-router-dom';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import rootReducer from './reducers';
import App, { apiClient } from './App';
import { BUGSNAG_KEY, REDUX_DEV_TOOLS_ARE_ENABLED } from './config';
import { log } from './utility/logging';

// eslint-disable-next-line
let ErrorBoundary = ({ children }) => <>{children}</>; // Default to no-op ErrorBoundary
if (BUGSNAG_KEY) {
  Bugsnag.start({
    apiKey: BUGSNAG_KEY,
    plugins: [new BugsnagPluginReact()],
  });
  const BugsnagErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
  ErrorBoundary = BugsnagErrorBoundary;
}

const root = ReactDOM.createRoot(document.getElementById('root'));

// Configure the store to pass apiClient as an extra argument
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: { extraArgument: { apiClient, log } } }),
  devTools: !!REDUX_DEV_TOOLS_ARE_ENABLED,
});

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </ErrorBoundary>,
);
