import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

function EndUserLicenseAgreement() {
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    // Fetch the markdown file from the public folder
    fetch('/endUserLicenseAgreement.md')
      .then((response) => response.text())
      .then((text) => setMarkdown(text))
      // eslint-disable-next-line
      .catch((err) => console.error(err));
  }, []);

  return (
    <ReactMarkdown>{markdown}</ReactMarkdown>
  );
}

export default EndUserLicenseAgreement;
