import {
  USER_GET_ME_API_CALL_START,
  USER_GET_ME_API_CALL_FAIL,
  USER_GET_ME_API_CALL_SUCCESS,
  USER_POST_API_CALL_START,
  USER_POST_API_CALL_FAIL,
  USER_POST_API_CALL_SUCCESS,
  USER_POST_API_CALL_IDLE,
  USER_PUT_API_CALL_START,
  USER_PUT_API_CALL_FAIL,
  USER_PUT_API_CALL_SUCCESS,
  USER_PUT_API_CALL_IDLE,
  USER_DELETE_API_CALL_START,
  USER_DELETE_API_CALL_FAIL,
  USER_DELETE_API_CALL_SUCCESS,
  USER_DELETE_API_CALL_IDLE,
} from '../actions';
import { createRequestStateUpdater } from '../utility/reduxUtils';
import { updateObject } from '../utility/utility';

const initialState = {};

export const USER_REQUESTS = {
  meGet: 'meGet',
};

const userPostStart = (state, action) => updateObject(state, {
  userPostRequestState: action.requestState,
});

const userPostFail = (state, action) => updateObject(state, {
  userPostRequestState: action.requestState,
});

const userPostSuccess = (state, action) => updateObject(state, {
  user: action.data,
  userPostRequestState: action.requestState,
});

const userPostIdle = (state, action) => updateObject(state, {
  userPostRequestState: action.requestState,
});

const userPutStart = (state, action) => updateObject(state, {
  userPutRequestState: action.requestState,
});

const userPutFail = (state, action) => updateObject(state, {
  userPutRequestState: action.requestState,
});

const userPutSuccess = (state, action) => updateObject(state, {
  user: action.data,
  userPutRequestState: action.requestState,
});

const userPutIdle = (state, action) => updateObject(state, {
  userPutRequestState: action.requestState,
});

const userDeleteStart = (state, action) => updateObject(state, {
  userDeleteRequestState: action.requestState,
});

const userDeleteFail = (state, action) => updateObject(state, {
  userDeleteRequestState: action.requestState,
});

const userDeleteSuccess = (state, action) => updateObject(state, {
  user: action.data,
  userDeleteRequestState: action.requestState,
});

const userDeleteIdle = (state, action) => updateObject(state, {
  userDeleteRequestState: action.requestState,
});

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_GET_ME_API_CALL_START:
      return createRequestStateUpdater(state, action, USER_REQUESTS.meGet);
    case USER_GET_ME_API_CALL_SUCCESS:
      return updateObject(
        createRequestStateUpdater(state, action, USER_REQUESTS.meGet),
        { me: action.data },
      );
    case USER_GET_ME_API_CALL_FAIL:
      return createRequestStateUpdater(state, action, USER_REQUESTS.meGet);

    case USER_POST_API_CALL_START:
      return userPostStart(state, action);
    case USER_POST_API_CALL_FAIL:
      return userPostFail(state, action);
    case USER_POST_API_CALL_SUCCESS:
      return userPostSuccess(state, action);
    case USER_POST_API_CALL_IDLE:
      return userPostIdle(state, action);
    case USER_PUT_API_CALL_START:
      return userPutStart(state, action);
    case USER_PUT_API_CALL_FAIL:
      return userPutFail(state, action);
    case USER_PUT_API_CALL_SUCCESS:
      return userPutSuccess(state, action);
    case USER_PUT_API_CALL_IDLE:
      return userPutIdle(state, action);
    case USER_DELETE_API_CALL_START:
      return userDeleteStart(state, action);
    case USER_DELETE_API_CALL_FAIL:
      return userDeleteFail(state, action);
    case USER_DELETE_API_CALL_SUCCESS:
      return userDeleteSuccess(state, action);
    case USER_DELETE_API_CALL_IDLE:
      return userDeleteIdle(state, action);
    default:
      return state;
  }
};

export default reducer;
