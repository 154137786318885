import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import _ from 'lodash';

import Select from '../../Select';
import {
  get,
  setNestedKey,
} from '../../../utility/utility';
import { displayInputFields } from '../../../utility/utlityFields';

import SectionDivider from '../../SectionDivider';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import InputField from '../../core/input/InputField';

const BookingFormSave = forwardRef(({
  onCreate,
  onEdit,
  bookingsPostIsLoading,
  bookingsPutIsLoading,
  organization,
  carriers,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentBooking, setCurrentBooking] = useState(null);
  const [inputState, setInputState] = useState(null);
  const [error, setError] = useState(null);

  useImperativeHandle(ref, () => ({
    open: (booking) => {
      if (!booking) {
        setCurrentBooking(null);
        setInputState(null);
      } else {
        setCurrentBooking(booking);
        setInputState(_.cloneDeep(booking));
      }
      setIsOpen(true);
      setError(null);
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!bookingsPostIsLoading || !bookingsPutIsLoading) {
      closeModal();
    }
  }, [bookingsPostIsLoading, bookingsPutIsLoading]);

  const validateForm = (state) => {
    if (!get(state, 'booking.invoiceNumber') && !get(state, 'booking.purchaseOrder')) {
      setError('Either Invoice Number or Purchase Order required.');
      return false;
    }
    setError(null);
    return true;
  };

  const handleChange = (event, path) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    let newState = { ...inputState };
    newState = { ...setNestedKey(newState, path, value) };
    setInputState(newState);
    validateForm(newState);
  };

  const handleCreate = () => {
    if (validateForm(inputState)) {
      onCreate({ ...inputState });
    }
  };

  const handleEdit = () => {
    if (validateForm(inputState)) {
      onEdit({ ...inputState });
    }
  };

  const shipperOptions = (get(organization, 'contacts', []) ?? [])
    .filter((contact) => contact.type === 'shipper')
    .map((contact) => contact.company?.name);

  const customerOptions = (get(organization, 'contacts', []) ?? [])
    .filter((contact) => contact.type === 'customer')
    .map((contact) => contact.company?.name);

  if (!isOpen) return null;
  return (
    <CoreFormDualAction
      title={currentBooking === null ? 'Create Booking' : 'Edit Booking'}
      primaryButtonCaption={currentBooking === null ? 'Create' : 'Save'}
      primaryButtonCallback={currentBooking === null ? handleCreate : handleEdit}
      primaryButtonCallbackStatus={currentBooking === null ? bookingsPostIsLoading : bookingsPutIsLoading}
      primaryButtonDisabled={error || currentBooking === null ? bookingsPostIsLoading : bookingsPutIsLoading}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <InputField id="purchaseOrder" value={get(inputState, 'booking.purchaseOrder')} onChange={(e) => handleChange(e, 'booking.purchaseOrder')} label="Purchase Order" />
      {error && <div style={{ color: 'var(--color-warning)' }}>{error}</div>}
      <InputField id="invoiceNumber" value={get(inputState, 'booking.invoiceNumber')} onChange={(e) => handleChange(e, 'booking.invoiceNumber')} label="Invoice Number" />
      {error && <div style={{ color: 'var(--color-warning)' }}>{error}</div>}
      <InputField id="billOfLading" value={get(inputState, 'booking.billOfLading')} onChange={(e) => handleChange(e, 'booking.billOfLading')} label="Bill of Lading" />
      <InputField id="productDescription" value={get(inputState, 'booking.productDescription')} onChange={(e) => handleChange(e, 'booking.productDescription')} label="Product Description" />
      <Select id="shipper" value={get(inputState, 'booking.shipper')} onChange={(e) => handleChange(e, 'booking.shipper')} options={[''].concat(shipperOptions)}>Shipper</Select>
      <Select id="customer" value={get(inputState, 'booking.customer')} onChange={(e) => handleChange(e, 'booking.customer')} options={[''].concat(customerOptions)}>Customer</Select>
      <SectionDivider label="Container Tracking" />
      <Select id="carrierName" value={get(inputState, 'tracking.container.carrierName')} onChange={(e) => handleChange(e, 'tracking.container.carrierName')} label="Carrier" options={[''].concat(carriers.map((carrier) => (carrier.name)))}>Carrier</Select>
      <InputField id="containerId" value={get(inputState, 'tracking.container.containerId')} onChange={(e) => handleChange(e, 'tracking.container.containerId')} label="Container ID" />
      <SectionDivider label="Details" />
      {displayInputFields(organization.bookingSettings.details, inputState, 'details', handleChange)}
      <SectionDivider label="Checklist" />
      {displayInputFields(organization.bookingSettings.checklist, inputState, 'checklist', handleChange)}
    </CoreFormDualAction>
  );
});

export default BookingFormSave;
