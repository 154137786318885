import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import _ from 'lodash';
import { get } from '../../../utility/utility';
import Select from '../../Select';

import { getBookingIdentifier } from '../../../utility/BookingsUtility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';

const BookingFormInspectionRequest = forwardRef(({
  bookingsPostInspectionsIsLoading,
  dispatchBookingsPostInspection,
  inspectors,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputState, setInputState] = useState({
    inspector: '',
    bookings: [],
    service: '',
  });
  const [error, setError] = useState(null);
  useImperativeHandle(ref, () => ({
    open: (bookings) => {
      setIsOpen(true);
      setInputState({
        bookings: _.cloneDeep(bookings),
        inspector: '',
        service: '',
      });
      setError(null);
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!bookingsPostInspectionsIsLoading) {
      closeModal();
    }
  }, [bookingsPostInspectionsIsLoading]);

  if (!isOpen) return null;

  const handleChange = (event) => {
    const newState = { ...inputState };
    newState[event.target.id] = event.target.value;
    setInputState(newState);
  };

  const handleRequestInspection = () => {
    const serviceRequested = inspectors.find((org) => org.company.name === inputState.inspector).inspectionSettings.services.find((service) => (service.title === inputState.service));

    const body = {
      request: {
        inspectorOrganizationId: inspectors.find((inspector) => inspector.company.name === inputState.inspector)._id,
        serviceId: serviceRequested._id,
        bookingIds: inputState.bookings.map((booking) => (booking._id)),
      },
    };
    dispatchBookingsPostInspection(body);
  };

  const renderSelectedBooking = (selectedBookings) => (
    selectedBookings.map((booking) => {
      const bookingIdentifier = getBookingIdentifier(booking);
      return (
        <p
          key={get(booking, '_id')}
          style={{ margin: '0px', paddingLeft: '25px' }}
        >
          <span>- </span>
          {bookingIdentifier.identifier}
          <span>: </span>
          {bookingIdentifier.value}
        </p>
      );
    }));

  const inspectorOptions = inspectors;
  const selectedInspector = inspectorOptions.find((org) => org.company.name === inputState.inspector);
  const servicesOptions = get(selectedInspector, 'inspectionSettings.services', []).map((service) => (service.title));

  return (
    <CoreFormDualAction
      title="Inspection Request"
      primaryButtonCaption="Request"
      primaryButtonCallback={() => handleRequestInspection()}
      primaryButtonCallbackStatus={bookingsPostInspectionsIsLoading}
      primaryButtonDisabled={error || bookingsPostInspectionsIsLoading}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <>
        <p>
          Request inspection for:
        </p>
        {renderSelectedBooking(get(inputState, 'bookings', []))}
      </>
      <Select id="inspector" value={get(inputState, 'inspector')} onChange={handleChange} options={[''].concat(inspectors.map((org) => org.company.name))}>Inspector</Select>
      {inputState.inspector && (
        <Select
          id="service"
          value={get(inputState, 'service')}
          onChange={handleChange}
          options={[''].concat(servicesOptions)}
        >
          Service
        </Select>
      )}

      {error?.template && <div style={{ color: 'var(--color-warning)' }}>{error.template}</div>}
    </CoreFormDualAction>
  );
});

export default BookingFormInspectionRequest;
