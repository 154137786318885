import { React, useEffect } from 'react';
import { connect } from 'react-redux';

import './BookingPage.css';
import BookingView from '../components/BookingView';
import {
  carriersGet, bookingsGetDocument, bookingsGet, bookingsPost, bookingsPut, bookingsDocumentPatch, emailsPost, bookingsPutDeactivate, bookingsPutShare, bookingsRefreshPatch, bookingsDelete, notificationsShow, bookingsDocumentDelete, bookingsGetShared, organizationGetInspectors, inspectionsPost, bookingsPostInspection, inspectionsGetDocument,
} from '../actions';
import { get } from '../utility/utility';

function BookingPage({
  me,
  organization,
  carriers,
  bookings,
  bookingsIsLoading,
  sharedBookings,
  sharedBookingsIsLoading,
  bookingsPostIsLoading,
  bookingsPutIsLoading,
  bookingsDeleteIsLoading,
  bookingsPostInspectionsIsLoading,
  bookingsPutDeactivateIsLoading,
  bookingsPutShareIsLoading,
  bookingsDeleteDocumentsIsLoading,
  bookingsPatchDocumentsIsLoading,
  dispatchBookingDocumentDelete,
  dispatchBookingDocumentPatch,
  dispatchBookingRefreshPatch,
  dispatchBookingsDeactivate,
  dispatchBookingsDelete,
  dispatchBookingsGetDocument,
  dispatchInspectionsGetDocument,
  dispatchBookingsGet,
  dispatchBookingsGetShared,
  dispatchBookingsPost,
  dispatchBookingsPostInspection,
  dispatchBookingsPut,
  dispatchBookingsShare,
  dispatchCarriersGet,
  dispatchEmailsPost,
  dispatchInspectionsPost,
  dispatchNotification,
  dispatchOrganizationGetInspectors,
  emailsPostRequestState,
  inspectors,
}) {
  // Load once every time the page loads
  useEffect(() => {
    dispatchCarriersGet();
    dispatchBookingsGet();
    dispatchBookingsGetShared();
    dispatchOrganizationGetInspectors();
  }, []);

  return !bookingsIsLoading && !sharedBookingsIsLoading ? (
    <BookingView
      me={me}
      organization={organization}
      sharedBookings={sharedBookings}
      bookings={bookings}
      bookingsPostIsLoading={bookingsPostIsLoading}
      bookingsPutIsLoading={bookingsPutIsLoading}
      bookingsPostInspectionsIsLoading={bookingsPostInspectionsIsLoading}
      bookingsDeleteIsLoading={bookingsDeleteIsLoading}
      bookingsDeleteDocumentsIsLoading={bookingsDeleteDocumentsIsLoading}
      bookingsPatchDocumentsIsLoading={bookingsPatchDocumentsIsLoading}
      bookingsPutDeactivateIsLoading={bookingsPutDeactivateIsLoading}
      bookingsPutShareIsLoading={bookingsPutShareIsLoading}
      carriers={carriers}
      dispatchBookingDocumentDelete={dispatchBookingDocumentDelete}
      dispatchBookingDocumentPatch={dispatchBookingDocumentPatch}
      dispatchBookingRefreshPatch={dispatchBookingRefreshPatch}
      dispatchBookingsDeactivate={dispatchBookingsDeactivate}
      dispatchBookingsDelete={dispatchBookingsDelete}
      dispatchBookingsGetDocument={dispatchBookingsGetDocument}
      dispatchInspectionsGetDocument={dispatchInspectionsGetDocument}
      dispatchBookingsPost={dispatchBookingsPost}
      dispatchBookingsPostInspection={dispatchBookingsPostInspection}
      dispatchBookingsPut={dispatchBookingsPut}
      dispatchBookingsShare={dispatchBookingsShare}
      dispatchEmailsPost={dispatchEmailsPost}
      dispatchInspectionsPost={dispatchInspectionsPost}
      dispatchNotification={dispatchNotification}
      emailsPostRequestState={emailsPostRequestState}
      inspectors={inspectors}
    />
  ) : null;
}

const mapStateToProps = (state) => ({
  me: state.users.me,
  organization: state.organizations.organization,
  carriers: get(state.carriers, 'carriers', []),
  emailsPostRequestState: state.emails.emailsPostRequestState,
  inspectors: state.organizations.inspectors,
  bookings: get(state, 'bookings.bookings', []),
  bookingsIsLoading: get(state, 'bookings.bookingsGet.isLoading', false),
  sharedBookings: get(state, 'bookings.sharedBookings', []),
  sharedBookingsIsLoading: get(state, 'bookings.bookingsGetShared.isLoading', false),
  bookingsPostIsLoading: get(state, 'bookings.bookingsPost.isLoading', false),
  bookingsPutIsLoading: get(state, 'bookings.bookingsPut.isLoading', false),
  bookingsDeleteIsLoading: get(state, 'bookings.bookingsDelete.isLoading', false),
  bookingsPostInspectionsIsLoading: get(state, 'bookings.bookingsPostInspections.isLoading', false),
  bookingsPutDeactivateIsLoading: get(state, 'bookings.bookingsPutDeactivate.isLoading', false),
  bookingsPutShareIsLoading: get(state, 'bookings.bookingsPutShare.isLoading', false),
  bookingsPatchDocumentsIsLoading: get(state, 'bookings.bookingsPatchDocuments.isLoading', false),
  bookingsDeleteDocumentsIsLoading: get(state, 'bookings.bookingsDeleteDocuments.isLoading', false),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchBookingDocumentDelete: (booking, documentType) => dispatch(bookingsDocumentDelete(booking, documentType)),
  dispatchBookingDocumentPatch: (booking, documentType, document) => dispatch(bookingsDocumentPatch(booking, documentType, document)),
  dispatchBookingRefreshPatch: (booking) => dispatch(bookingsRefreshPatch(booking)),
  dispatchBookingsDeactivate: (booking) => dispatch(bookingsPutDeactivate(booking)),
  dispatchBookingsDelete: (booking) => dispatch(bookingsDelete(booking)),
  dispatchBookingsGet: () => dispatch(bookingsGet()),
  dispatchBookingsGetDocument: (booking, documentType) => dispatch(bookingsGetDocument(booking, documentType)),
  dispatchBookingsGetShared: () => dispatch(bookingsGetShared()),
  dispatchBookingsPost: (body) => dispatch(bookingsPost(body)),
  dispatchBookingsPostInspection: (body) => dispatch(bookingsPostInspection(body)),
  dispatchBookingsPut: (body) => dispatch(bookingsPut(body)),
  dispatchBookingsShare: (booking, shareObj) => dispatch(bookingsPutShare(booking, shareObj)),
  dispatchCarriersGet: () => dispatch(carriersGet()),
  dispatchEmailsPost: (email) => dispatch(emailsPost(email)),
  dispatchInspectionsPost: (inspection) => dispatch(inspectionsPost(inspection)),
  dispatchNotification: (severity, message) => dispatch(notificationsShow(severity, message)),
  dispatchOrganizationGetInspectors: () => dispatch(organizationGetInspectors()),
  dispatchInspectionsGetDocument: (inspection, document) => dispatch(inspectionsGetDocument(inspection, document)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingPage);
