// Define available log types
export const LOG_TYPES = {
  info: 'info',
  error: 'error',
  warn: 'warn',
};

// Generic logging function with type-based output
export const log = (content, options = {}) => {
  const {
    type = LOG_TYPES.error, // Default to 'error' if type is not provided
  } = options;

  if (process.env.REACT_APP_DEBUG_MODE === 'true') {
    switch (type) {
      case LOG_TYPES.error:
        // eslint-disable-next-line
        console.error(content);
        break;
      case LOG_TYPES.info:
        // eslint-disable-next-line
        console.info(content);
        break;
      case LOG_TYPES.warn:
        // eslint-disable-next-line
        console.warn(content);
        break;
      default:
        // eslint-disable-next-line
        console.log(content); // Fallback for undefined types
        break;
    }
  }
};
