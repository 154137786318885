import { StatusCodes } from 'http-status-codes';
import {
  apiRequestState, createRequestAction, get, newCreateRequestAction,
} from '../utility/utility';
import { notificationsShow } from './notifications';
import { signOut } from './authActions';

export const ENDPOINT_PATH = 'users';
export const USER_GET_ME_API_CALL_START = 'USER_GET_ME_API_CALL_START';
export const USER_GET_ME_API_CALL_SUCCESS = 'USER_GET_ME_API_CALL_SUCCESS';
export const USER_GET_ME_API_CALL_FAIL = 'USER_GET_ME_API_CALL_FAIL';
export const USER_POST_API_CALL_START = 'USER_POST_API_CALL_START';
export const USER_POST_API_CALL_SUCCESS = 'USER_POST_API_CALL_SUCCESS';
export const USER_POST_API_CALL_FAIL = 'USER_POST_API_CALL_FAIL';
export const USER_POST_API_CALL_IDLE = 'USER_POST_API_CALL_IDLE';
export const USER_PUT_API_CALL_START = 'USER_PUT_API_CALL_START';
export const USER_PUT_API_CALL_SUCCESS = 'USER_PUT_API_CALL_SUCCESS';
export const USER_PUT_API_CALL_FAIL = 'USER_PUT_API_CALL_FAIL';
export const USER_PUT_API_CALL_IDLE = 'USER_PUT_API_CALL_IDLE';
export const USER_DELETE_API_CALL_START = 'USER_DELETE_API_CALL_START';
export const USER_DELETE_API_CALL_SUCCESS = 'USER_DELETE_API_CALL_SUCCESS';
export const USER_DELETE_API_CALL_FAIL = 'USER_DELETE_API_CALL_FAIL';
export const USER_DELETE_API_CALL_IDLE = 'USER_DELETE_API_CALL_IDLE';

export const userGetMe = () => (dispatch, _getState, { apiClient, log }) => {
  dispatch(newCreateRequestAction({ type: USER_GET_ME_API_CALL_START, isLoading: true }));

  const userGetMeRequest = async () => {
    try {
      const response = await apiClient.httpGet(
        `${ENDPOINT_PATH}/me`,
      );

      dispatch(newCreateRequestAction({ type: USER_GET_ME_API_CALL_SUCCESS, data: response.data }));
    } catch (err) {
      log(err);
      const message = 'Failed to load user profile';
      dispatch(newCreateRequestAction({ type: USER_GET_ME_API_CALL_FAIL, error: message }));

      if ((get(err, 'response.status') === StatusCodes.UNAUTHORIZED)) {
        dispatch(signOut());
      }
    }
  };

  userGetMeRequest();
};

export const userPostStart = () => createRequestAction(USER_POST_API_CALL_START, apiRequestState.LOADING);
export const userPostSuccess = (user) => createRequestAction(USER_POST_API_CALL_SUCCESS, apiRequestState.SUCCESS, user);
export const userPostIdle = () => createRequestAction(USER_POST_API_CALL_IDLE, apiRequestState.IDLE);
export const userPostFail = () => createRequestAction(USER_POST_API_CALL_FAIL, apiRequestState.FAIL);
export const userPost = (user) => (dispatch, _getState, { apiClient }) => {
  dispatch(userPostStart());

  const userPostRequest = async () => {
    try {
      const response = await apiClient.httpPost(`${ENDPOINT_PATH}/invite-internal`, user);
      await dispatch(userPostSuccess(response.data));
      await dispatch(userPostIdle());
      dispatch(notificationsShow('success', `Successfully created user with email: ${user.email}`));
    } catch (err) {
      dispatch(userPostFail());
      if (err.response.status === StatusCodes.BAD_REQUEST) {
        dispatch(notificationsShow('warning', `${err.response.data}`));
      } else {
        dispatch(notificationsShow('success', `Successfully created user with email: ${user.email}`));
      }
    }
  };

  userPostRequest();
};

export const userPutStart = () => createRequestAction(USER_PUT_API_CALL_START, apiRequestState.LOADING);
export const userPutSuccess = (user) => createRequestAction(USER_PUT_API_CALL_SUCCESS, apiRequestState.SUCCESS, user);
export const userPutIdle = () => createRequestAction(USER_PUT_API_CALL_IDLE, apiRequestState.IDLE);
export const userPutFail = () => createRequestAction(USER_PUT_API_CALL_FAIL, apiRequestState.FAIL);
export const userPut = (user) => (dispatch, _getState, { apiClient }) => {
  dispatch(userPutStart());

  const userPutRequest = async () => {
    try {
      const response = await apiClient.httpPut(`${ENDPOINT_PATH}/${user._id}`, user);
      await dispatch(userPutSuccess(response.data));
      await dispatch(userPutIdle());
      dispatch(notificationsShow('success', `Successfully updated user: ${user.email}`));
    } catch (err) {
      dispatch(userPutFail());
      dispatch(notificationsShow('warning', `${err.response.data}`));
    }
  };

  userPutRequest();
};

export const userDeleteStart = () => createRequestAction(USER_DELETE_API_CALL_START, apiRequestState.LOADING);
export const userDeleteSuccess = (user) => createRequestAction(USER_DELETE_API_CALL_SUCCESS, apiRequestState.SUCCESS, user);
export const userDeleteIdle = () => createRequestAction(USER_DELETE_API_CALL_IDLE, apiRequestState.IDLE);
export const userDeleteFail = () => createRequestAction(USER_DELETE_API_CALL_FAIL, apiRequestState.FAIL);
export const userDelete = (user) => (dispatch, _getState, { apiClient }) => {
  dispatch(userDeleteStart());

  const userDeleteRequest = async () => {
    try {
      const response = await apiClient.httpDelete(
        `${ENDPOINT_PATH}/${user._id}`,
      );
      await dispatch(userDeleteSuccess(response.data));
      dispatch(notificationsShow('success', 'Successfully deleted user'));
      await dispatch(userDeleteIdle());
    } catch (err) {
      dispatch(userDeleteFail());
    }
  };

  userDeleteRequest();
};
