import {
  AUTH_SIGN_IN_REQUEST_START,
  AUTH_SIGN_IN_REQUEST_SUCCESS,
  AUTH_SIGN_IN_REQUEST_FAIL,
  AUTH_VERIFY_REQUEST_START,
  AUTH_VERIFY_REQUEST_SUCCESS,
  AUTH_VERIFY_REQUEST_FAIL,
  AUTH_SIGN_OUT_REQUEST_START,
  AUTH_SIGN_OUT_REQUEST_SUCCESS,
  AUTH_CSRF_GET_REQUEST_START,
  AUTH_CSRF_GET_REQUEST_SUCCESS,
  AUTH_CSRF_GET_REQUEST_FAIL,
  AUTH_CREDS_STATUS_GET_REQUEST_START,
  AUTH_CREDS_STATUS_GET_REQUEST_SUCCESS,
  AUTH_CREDS_STATUS_GET_REQUEST_FAIL,
  AUTH_FORGOT_PASSWORD_REQUEST_START,
  AUTH_FORGOT_PASSWORD_REQUEST_FAIL,
  AUTH_FORGOT_PASSWORD_REQUEST_SUCCESS,
  AUTH_RESET_PASSWORD_REQUEST_START,
  AUTH_RESET_PASSWORD_REQUEST_FAIL,
  AUTH_RESET_PASSWORD_REQUEST_SUCCESS,
  AUTH_SIGNUP_INVITE_REQUEST_START,
  AUTH_SIGNUP_INVITE_REQUEST_FAIL,
  AUTH_SIGNUP_INVITE_REQUEST_SUCCESS,
} from '../actions';
import { createRequestStateUpdater } from '../utility/reduxUtils';

const initialState = {};

export const AUTH_REQUESTS = {
  signInPost: 'signInPost',
  verifyPost: 'verifyPost',
  signOutPost: 'signOutPost',
  csrfGet: 'csrfGet',
  credsStatusGet: 'credsStatusGet',
  forgotPassordPost: 'forgotPassordPost',
  resetPasswordPost: 'resetPasswordPost',
  signUpPost: 'signUpPost',
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SIGN_IN_REQUEST_START:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.signInPost);
    case AUTH_SIGN_IN_REQUEST_SUCCESS:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.signInPost, { isSignedIn: true, ...action.data });
    case AUTH_SIGN_IN_REQUEST_FAIL:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.signInPost);

    case AUTH_VERIFY_REQUEST_START:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.verifyPost);
    case AUTH_VERIFY_REQUEST_SUCCESS:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.verifyPost, { ...action.data });
    case AUTH_VERIFY_REQUEST_FAIL:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.verifyPost);

    case AUTH_SIGN_OUT_REQUEST_START:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.signOutPost);
    case AUTH_SIGN_OUT_REQUEST_SUCCESS:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.signOutPost);

    case AUTH_CSRF_GET_REQUEST_START:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.csrfGet);
    case AUTH_CSRF_GET_REQUEST_SUCCESS:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.csrfGet, action.data);
    case AUTH_CSRF_GET_REQUEST_FAIL:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.csrfGet);

    case AUTH_CREDS_STATUS_GET_REQUEST_START:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.credsStatusGet);
    case AUTH_CREDS_STATUS_GET_REQUEST_SUCCESS:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.credsStatusGet, { credsAreValid: true });
    case AUTH_CREDS_STATUS_GET_REQUEST_FAIL:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.credsStatusGet);

    case AUTH_FORGOT_PASSWORD_REQUEST_START:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.forgotPassordPost);
    case AUTH_FORGOT_PASSWORD_REQUEST_FAIL:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.forgotPassordPost);
    case AUTH_FORGOT_PASSWORD_REQUEST_SUCCESS:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.forgotPassordPost);

    case AUTH_RESET_PASSWORD_REQUEST_START:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.resetPasswordPost);
    case AUTH_RESET_PASSWORD_REQUEST_FAIL:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.resetPasswordPost);
    case AUTH_RESET_PASSWORD_REQUEST_SUCCESS:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.resetPasswordPost);

    case AUTH_SIGNUP_INVITE_REQUEST_START:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.signUpPost);
    case AUTH_SIGNUP_INVITE_REQUEST_FAIL:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.signUpPost);
    case AUTH_SIGNUP_INVITE_REQUEST_SUCCESS:
      return createRequestStateUpdater(state, action, AUTH_REQUESTS.signUpPost);

    default:
      return state;
  }
};

export default reducer;
