import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { log } from '../utility/logging';

function QuickbooksPage({ apiClient }) {
  const navigate = useNavigate();

  useEffect(() => {
    // Parse the query parameters from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const realmId = urlParams.get('realmId');

    // Verify the state (replace with your actual state)
    const expectedState = 'YOUR_CUSTOM_STATE';
    if (state !== expectedState) {
      log('Invalid state parameter');
      return;
    }

    // Send code and realmId to backend for token exchange
    const sendCodeToBackend = async () => {
      try {
        await apiClient.httpPost('integration/quickbooks/auth', {
          code,
          realmId,
        });

        // Navigate to a success or dashboard page if desired
        navigate('/organization');
      } catch (error) {
        log('Error connecting to QuickBooks:', error);
      }
    };

    if (code && realmId) {
      sendCodeToBackend();
    }
  }, [navigate]);

  return (
    <div>
      <h1>Connecting to QuickBooks...</h1>
      <p>Please wait while we complete the connection.</p>
    </div>
  );
}

export default QuickbooksPage;
