import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import _ from 'lodash';
import { get } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import { INSPECTION_STATES } from '../../../utility/InspectionsUtils';
import {
  renderDateTimePicker, renderInputField, renderSelectField, validateForm,
} from '../../core/input/CoreInputUtils';

const fieldsToValidate = ['certificateId', 'status'];
const InspectionFormRequest = forwardRef(({
  dispatchInspectionsPut,
  inspectionsPutIsLoading,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentInspection, setCurrentInspection] = useState(null);
  const [inputState, setInputState] = useState({});
  const [error, setError] = useState({});

  useImperativeHandle(ref, () => ({
    open: (inspection) => {
      setCurrentInspection(inspection);
      setInputState(get(inspection, 'request', {}));
      setIsOpen(true);
      setError({});
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    if (!inspectionsPutIsLoading) {
      closeModal();
    }
  }, [inspectionsPutIsLoading]);

  if (!isOpen) return null;

  const onInputChange = (event, path) => {
    const newState = { ...inputState };
    newState[path] = event.target.value;
    setInputState(newState);
    validateForm({
      state: newState,
      fieldsToValidate,
      setError,
    });
  };

  const handleSave = (inpsection, state) => {
    const validation = validateForm({
      state: inputState,
      fieldsToValidate,
      setError,
    });
    if (validation) {
      dispatchInspectionsPut(inpsection, { request: { ...state } });
    }
  };

  return (
    <CoreFormDualAction
      title="Request"
      primaryButtonCaption="Save"
      primaryButtonCallback={() => handleSave(currentInspection, inputState)}
      primaryButtonCallbackStatus={inspectionsPutIsLoading}
      primaryButtonDisabled={Object.keys(error).length > 0 || inspectionsPutIsLoading}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      {renderInputField({
        id: 'certificateId', inputState, onInputChange, error,
      })}
      {renderSelectField({
        id: 'status',
        inputState,
        onInputChange,
        options: Object.keys(INSPECTION_STATES).map(((element) => ({ label: _.startCase(element), value: element }))),
        error,
      })}
      {renderDateTimePicker({
        id: 'timestamp',
        label: 'Request Timestamp',
        inputState,
        onInputChange,
      })}
    </CoreFormDualAction>
  );
});

export default InspectionFormRequest;
