import React from 'react';
import { FaTrash } from 'react-icons/fa';
import BubbleCard from '../../BubbleCard';
import Button from '../../core/input/Button';
import { get, uuid } from '../../../utility/utility';
import InspectionInvoiceCard from '../cards/InspectionInvoiceCard';

function InspectionBubbleInvoices({
  handleButtonInspectionCreateInvoiceModalOpen,
  inspection,
  organization,
  dispatchInspectionsGetSingle,
  dispatchInspectionsDeleteInvoicePost,
  dispatchInspectionsFetchInvoiceGet,
}) {
  const renderSecondaryActions = (invoice) => {
    const secondaryActions = [];

    secondaryActions.push({
      _id: uuid(),
      text: 'Delete',
      icon: <FaTrash title="Delete" />,
      onClick: () => dispatchInspectionsDeleteInvoicePost(inspection, { inspectionId: inspection._id, invoiceId: invoice.invoiceId }),
    });

    return secondaryActions;
  };

  const renderGeneratedDocument = (invoice) => (
    <InspectionInvoiceCard
      inspectionId={get(inspection, '_id')}
      dispatchInspectionsGetSingle={dispatchInspectionsGetSingle}
      key={invoice._id}
      invoice={invoice}
      primaryAction={() => dispatchInspectionsFetchInvoiceGet(inspection, { invoiceId: get(invoice, 'invoiceId') })}
      secondaryActions={renderSecondaryActions(invoice)}
      isLoading={get(invoice, 'status', false) === 'pending'}
    />
  );

  return (
    <BubbleCard
      title="Invoices (Quickbooks)"
      style={{ marginBottom: '10px' }}
    >
      {get(inspection, 'report.invoices', []).map((invoice) => (
        renderGeneratedDocument(invoice)
      ))}
      <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '10px' }}>
        <Button
          style={{ width: 'auto', paddingLeft: '10px', paddingRight: '10px' }}
          onClick={() => handleButtonInspectionCreateInvoiceModalOpen(organization, inspection)}
          primary
          simple
        >
          Create invoice
        </Button>
      </div>
    </BubbleCard>
  );
}

export default InspectionBubbleInvoices;
