import {
  React, useEffect, useRef, useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import './BookingsShippedOnContainers.css';
import _ from 'lodash';
import {
  FaTrash,
  FaEdit,
  FaPaperclip,
  FaEnvelope,
  FaShare,
  FaArchive,
  FaSync,
  FaCopy,
  FaUser,
  FaEye,
  FaTruck,
} from 'react-icons/fa';
import Table from '../Table';
import TableHeader from '../TableHeader';
import TableBody from '../TableBody';
import {
  DATE_OPTIONS, getDateString, getLocationString, get, handleSort, permissionCheck,
} from '../../utility/utility';
import TableData from '../TableData';
import Spinner from '../Spinner';
import { SORT_OPTIONS, getStatusColor, sortKeyMap } from '../../utility/vesselParams';
import TableCollapsibleRow from '../TableCollapsibleRow';
import BookingSection from './sections/BookingSection';
import DocumentsSection from './sections/DocumentSection';
import DetailsSection from './sections/DetailsSection';
import BookingSharingSection from './sections/BookingSharingSection';
import ContainerTrackingSection from './sections/ContainerTrackingSection';
import { ChecklistSection, processChecklistAlarms } from './sections/ChecklistSection';
import ContainersCardList from '../cardLists/ContainersCardList';

import Map from '../Map';
import { getBookingsCount } from '../../utility/BookingsUtility';
import { MEDIA_MOBILE_MAX_WIDTH } from '../../utility/AppParameters';
import { getInspectionsStatusColor } from '../../utility/InspectionsUtils';
import InspectionsSection from './sections/InspectionSection';
import Checkbox from '../core/input/CoreInputCheckbox';
// import { getInspectionsStatusColor } from '../../utility/InspectionsUtils';

function BookingsShippedOnContainers({
  bookings,
  bookingsGetIsLoading,
  bookingsPostIsLoading,
  dispatchBookingRefreshPatch,
  dispatchBookingsGetDocument,
  dispatchInspectionsGetDocument,
  handleButtonBookingArchiveModalOpen,
  handleButtonBookingAttachDocumentModalOpen,
  handleButtonBookingCopyModalOpen,
  handleButtonBookingDeleteModalOpen,
  handleButtonBookingInspectionRequestModalOpen,
  handleButtonBookingSaveModalOpen,
  handleButtonBookingShareModalOpen,
  handleButtonBookingSingleEmailModalOpen,
  headerTitles = [],
  me,
  organization,
  presetChecklistFields = [],
  presetDetailsFields = [],
  rowSelectionIsEnabled,
  searchedBookings,
  searchInput,
  sharingSectionEnabled = false,
  statusLightsEnabled = false,
  title,
}) {
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: MEDIA_MOBILE_MAX_WIDTH });
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedBookings, setSelectedBookings] = useState([]);
  const [previousBookings, setPreviousBookings] = useState(searchedBookings);
  const [sortedBookingsState, setSortedBookingsState] = useState(previousBookings);
  const prevSearchedBookingsRef = useRef();

  useEffect(() => {
    prevSearchedBookingsRef.current = searchedBookings;
  }, [searchedBookings]);

  useEffect(() => {
    if (!_.isEqual(previousBookings, searchedBookings)) {
      setSelectedIds([]);
      setSelectedBookings([]);
      setPreviousBookings(searchedBookings);
    }
    handleSort({
      target: {
        value: SORT_OPTIONS.ETA,
      },
    }, searchedBookings, sortKeyMap, setSortedBookingsState);
  }, [searchedBookings, previousBookings]);

  const handleTableHeaderClick = (header) => {
    handleSort({
      target: {
        value: header.title,
      },
    }, searchedBookings, sortKeyMap, setSortedBookingsState);
  };

  const handleMasterCheckboxChange = (event) => {
    const { checked } = event.target;

    if (checked) {
      setSelectedIds(searchedBookings.map((booking) => booking._id));
      setSelectedBookings(searchedBookings);
    } else {
      setSelectedIds([]);
      setSelectedBookings([]);
    }
  };

  const displayBookings = (displayedBookings) => {
    const selectCheckboxHandler = (id) => {
      const newSelectedIds = [...selectedIds];
      const exists = newSelectedIds.includes(id);

      if (!exists) {
        newSelectedIds.push(id);
      } else {
        const index = newSelectedIds.indexOf(id);
        if (index > -1) {
          newSelectedIds.splice(index, 1);
        }
      }
      setSelectedIds(newSelectedIds);
      setSelectedBookings(_.filter(searchedBookings, (object) => _.includes(newSelectedIds, object._id)));
    };

    return (
      <>
        {displayedBookings.map((booking) => {
          const { associatedColor } = processChecklistAlarms(booking, organization?.bookingSettings?.checklist);
          const { color: statusColor, status: statusDescription } = getStatusColor(booking);

          return (
            <TableCollapsibleRow
              title={statusDescription}
              key={booking._id}
              id={booking._id}
              selectCheckboxIsEnabled={permissionCheck(me, ['permissions.bookings.edit']) && rowSelectionIsEnabled}
              selectCheckboxHandler={selectCheckboxHandler}
              checked={selectedIds.includes(booking._id)}
              alarmColor={associatedColor}
              expandedContent={(
                <div className="bookings-shipped-table-collaspsible-row-content">
                  <div className="bookings-shipped-map">
                    <Map flyZoom={5} milestones={booking?.tracking?.container?.status?.milestones} />
                  </div>

                  {permissionCheck(me, ['permissions.bookings.edit'])
                    ? (
                      <div className="table-collapsible-row-action-bar">
                        <FaEdit title="Edit" onClick={() => handleButtonBookingSaveModalOpen(booking)}>Edit</FaEdit>
                        <FaPaperclip title="Attach" onClick={() => handleButtonBookingAttachDocumentModalOpen(booking, organization.bookingSettings.documents)}>Attach</FaPaperclip>
                        <FaEnvelope title="Email" onClick={() => handleButtonBookingSingleEmailModalOpen(booking)}>Email</FaEnvelope>
                        {get(organization, 'features.inspectionRequests', false) && <FaEye title="Inspection" onClick={() => handleButtonBookingInspectionRequestModalOpen([booking])}>Inspection</FaEye>}
                        {get(organization, 'features.bookingSharing', false) && <FaShare title="Share" onClick={() => handleButtonBookingShareModalOpen([booking], organization.bookingSettings)}>Share</FaShare>}
                        <FaArchive title="Archive" onClick={() => handleButtonBookingArchiveModalOpen([booking])}>Archive</FaArchive>
                        <FaTrash title="Delete" onClick={() => handleButtonBookingDeleteModalOpen([booking])}>Delete</FaTrash>
                        <FaSync title="Refresh" onClick={() => dispatchBookingRefreshPatch(booking)}>Refresh</FaSync>
                      </div>
                    ) : null}
                  <BookingSection booking={booking.booking} />
                  <DetailsSection presetDetailsFields={presetDetailsFields} details={booking.details} />
                  <DocumentsSection booking={booking} dispatchBookingsGetDocument={dispatchBookingsGetDocument} />
                  <ChecklistSection presetChecklistFields={presetChecklistFields} booking={booking} />
                  {booking?.inspection && <InspectionsSection inspection={booking.inspection} dispatchInspectionsGetDocument={dispatchInspectionsGetDocument} />}
                  {sharingSectionEnabled ? <BookingSharingSection booking={booking} /> : null}
                  <ContainerTrackingSection container={booking.tracking.container} />
                </div>
              )}
            >
              {statusLightsEnabled ? <TableData color={statusColor} /> : <TableData />}
              <TableData>
                <div style={{ color: get(booking, 'tracking.container.status.etaTrustability') }}>
                  {getDateString(get(booking, 'tracking.container.status.eta'), DATE_OPTIONS.INCLUDE_WEEK)}
                </div>
              </TableData>
              <TableData>{get(booking, 'details.pickupDate')}</TableData>
              <TableData>{get(booking, 'tracking.container.carrierName')}</TableData>
              <TableData>{get(booking, 'tracking.container.status.vessel')}</TableData>
              <TableData>{get(booking, 'tracking.container.containerId')}</TableData>
              <TableData>{booking?.booking?.purchaseOrder}</TableData>
              <TableData>{booking?.booking?.shipper}</TableData>
              <TableData>{booking?.booking?.productDescription}</TableData>
              <TableData>
                {getLocationString(
                  get(booking, 'tracking.container.status.origin.country'),
                  get(booking, 'tracking.container.status.origin.city'),
                )}
              </TableData>
              <TableData>
                {getLocationString(
                  get(booking, 'tracking.container.status.destination.country'),
                  get(booking, 'tracking.container.status.destination.city'),
                )}
              </TableData>
              <TableData />
              <TableData>
                <div
                  title="Inspection requested"
                  className="bookings-shipped-inspection-requested-icon"
                >
                  {booking?.inspection?.id && (
                    <FaEye
                      title={getInspectionsStatusColor(booking?.inspection?.status).title}
                      style={{ color: getInspectionsStatusColor(booking?.inspection?.status).color }}
                      className="fa-inspection"
                    />
                  )}
                </div>
              </TableData>
              <TableData>
                <div
                  title={`Transport requested: ${get(booking, 'details.transport')}`}
                  className="bookings-shipped-inspection-requested-icon"
                >
                  {booking?.details?.transport && <FaTruck className="fa-inspection" />}
                </div>
              </TableData>
              <TableData>
                <div
                  className="bookings-shipped-shared-users"
                  title={booking.sharing.map((user) => user.email).join('\n')}
                >
                  <div className="bookings-shipped-shared-users-icon">
                    {booking.sharing.length > 0 && <FaUser className="fa-user" />}
                  </div>
                  <div className="bookings-shipped-shared-users-count">
                    {booking.sharing.length > 1 && <div className="shareCount">{booking.sharing.length}</div>}
                  </div>
                </div>
              </TableData>
            </TableCollapsibleRow>
          );
        })}
      </>
    );
  };

  const newElement = rowSelectionIsEnabled && <Checkbox checked={selectedIds.length === searchedBookings.length} onChange={handleMasterCheckboxChange} />;
  const updatedHeaderTitles = headerTitles.slice(0, -2)
    .concat(newElement)
    .concat(headerTitles.slice(-1));

  const containersCount = getBookingsCount(
    bookings.filter((item) => item.tracking.container !== null).length,
    searchedBookings.filter((item) => item.tracking.container !== null).length,
    searchInput,
  );

  function renderBookingsShippedTable() {
    if (containersCount.total === 0) return null;

    return (
      <div className="bookings-booked-table">
        <div className="bookings-shipped-title-block">
          <div className="bookings-shipped-title">
            {`${title} ${containersCount.text}`}
          </div>
          {
            selectedIds.length > 0 && (
              <div className="bookings-shipped-bulk-action-buttons">
                {get(organization, 'features.inspectionRequests', false) && <FaEye title="Inspection" className="action-icon" onClick={() => handleButtonBookingInspectionRequestModalOpen(selectedBookings)} />}
                <FaArchive title="Archive" className="action-icon" onClick={() => handleButtonBookingArchiveModalOpen(selectedBookings)} />
                {get(organization, 'features.bookingSharing', false) && <FaShare title="Share" className="action-icon" onClick={() => handleButtonBookingShareModalOpen(selectedBookings, organization.bookingSettings)} />}
                <FaTrash title="Delete" className="action-icon" onClick={() => handleButtonBookingDeleteModalOpen(selectedBookings)} />
                <FaCopy title="Copy" className="action-icon" onClick={() => handleButtonBookingCopyModalOpen(selectedBookings)} />
              </div>
            )
          }
        </div>
        {containersCount.found > 0
          && (
            <div>
              {bookingsGetIsLoading || bookingsPostIsLoading
                ? (
                  <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  >
                    <Spinner />
                  </div>
                )
                : (
                  <Table>
                    <TableHeader onClick={handleTableHeaderClick} titles={updatedHeaderTitles} />
                    <TableBody me={me}>
                      {displayBookings(sortedBookingsState)}
                    </TableBody>
                  </Table>
                )}
            </div>
          )}
        {' '}
      </div>
    );
  }

  function renderBookingsShippedCardList() {
    if (containersCount.found === 0 || containersCount.total === 0) return null;

    return (
      <ContainersCardList
        title={`${title} ${containersCount.text}`}
        sortOptions={headerTitles.filter((option) => typeof option === 'string' && option.length > 0)}
        handleTableHeaderClick={handleTableHeaderClick}
        organization={organization}
        bookings={sortedBookingsState}
        handleButtonBookingSaveModalOpen={handleButtonBookingSaveModalOpen}
        handleButtonBookingDeleteModalOpen={handleButtonBookingDeleteModalOpen}
        handleButtonBookingArchiveModalOpen={handleButtonBookingArchiveModalOpen}
        handleButtonBookingShareModalOpen={handleButtonBookingShareModalOpen}
        handleButtonBookingAttachDocumentModalOpen={handleButtonBookingAttachDocumentModalOpen}
        handleButtonBookingSingleEmailModalOpen={handleButtonBookingSingleEmailModalOpen}
        dispatchBookingsGetDocument={dispatchBookingsGetDocument}
      />
    );
  }

  return (
    <div>
      {isDesktopOrLaptop ? renderBookingsShippedTable() : renderBookingsShippedCardList()}
    </div>
  );
}

export default BookingsShippedOnContainers;
